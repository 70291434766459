import { Button } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/layout/FlexBox";
import { useEffect } from "react";
import SignatureCanvas from 'react-signature-canvas';


export default function SignatureBox({ width, height, inputId, dataURL, onChange, sx, ...props }) {
	if (inputId === undefined) inputId = "signature-box";

	if (width === undefined) width = 300;
	if (height === undefined) height = 150;

	let sigCanvas = null;

	useEffect(() => {
		if (sigCanvas) {
			let current = sigCanvas.toDataURL();
			if (current !== dataURL) {
				sigCanvas.fromDataURL(dataURL);
			}
		}
	}, [dataURL, sigCanvas])

	useEffect(() => {

		function handleResize() {
			// Prevent clearing on window resize
			if (sigCanvas && dataURL) sigCanvas.fromDataURL(dataURL);
		}
	
		window.addEventListener('resize', handleResize)
		return () => { window.removeEventListener('resize', handleResize) };
	});

	return <Box sx={{width: width + 'px', ...sx}} {...props}>
		<SignatureCanvas
			canvasProps={{style: {width: width, height: height, border: '1px solid #cccccc'}}}
			ref={(ref) => { sigCanvas = ref }}
			onEnd={() => {
				if (onChange) onChange(sigCanvas.toDataURL());
			}}
		/>
		<FlexBox>
			<Button variant="label" type="button" onClick={(event) => {
				sigCanvas.clear();
				if (onChange) onChange(null);
			}}>Clear</Button>

			<label htmlFor={inputId} style={{marginLeft: 'auto'}}>
				<input
					id={inputId}
					name={inputId}
					style={{ display: 'none' }}
					type="file"
					accept=".jpg, .png"
					onClick={(event) => {
						event.target.value = '';
					}}
					onChange={(event) => {
						if (event.target.files.length > 0) {
							let file = event.target.files[0];
							
							const fileReader = new FileReader();    
							fileReader.onloadend = (event) => {
								if (event.target.result && !event.target.error) {
									let dataURL = event.target.result;
									var image = new Image();
									image.onload = () => {

										let scale = Math.min(width / image.width, height / image.height);
										let scaledWidth = image.width * scale;
										let scaledHeight = image.height * scale;
										let xOffset = Math.floor((width - scaledWidth) / 2);
										let yOffset = Math.floor((height - scaledHeight) / 2);

										sigCanvas.fromDataURL(dataURL, { width: scaledWidth, height: scaledHeight, xOffset: xOffset, yOffset: yOffset });

										setTimeout(() => {
											if (onChange) onChange(sigCanvas.toDataURL());
										}, 1);
									}
									image.src = dataURL;
								}
							}
							fileReader.readAsDataURL(file);
						}
					}}
				/>
				<Button component="span" variant="label" type="button" >Select file</Button>
			</label>				

		</FlexBox>
	</Box>		

}