import { useEffect, useState } from "react";

export default function useDrag(ref, onChange) {

	const [dragState, setDragState] = useState({});
	
	useEffect(() => {
		let element = ref.current;
		if (!element) return;

		let originalClick = null;
		let centerOffset = null;

		function onMouseDown(event) {
			window.addEventListener("mousemove", onMouseMove);
			window.addEventListener("mouseup", onMouseUp);
			const bounds = element.getBoundingClientRect();
			let cx = event.clientX;
			let cy = event.clientY;
			originalClick = { x: cx, y: cy };
			centerOffset = { x: cx - (bounds.x + bounds.width / 2), y: cy - (bounds.y + bounds.height / 2) };
			setDragState({
				dragging: true,
				mouse: { x: cx, y: cy },
				dragged: { x: 0, y: 0 },
				center: { x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 },
			});
		}

		function onMouseMove(event) {
			let cx = event.clientX;
			let cy = event.clientY;
			setDragState({
				dragging: true,
				mouse: { x: cx, y: cy },
				dragged: { x: cx - originalClick.x, y: cy - originalClick.y },
				center: { x: cx - centerOffset.x, y: cy - centerOffset.y },
			})
		}

		function onMouseUp(event) {
			window.removeEventListener("mousemove", onMouseMove);
			window.removeEventListener("mouseup", onMouseUp);
			setDragState({
				dragging: false,
			})
		}


		function onTouchStart(event) {
			window.addEventListener("touchmove", onTouchMove);
			window.addEventListener("touchend", onTouchEnd);
			const bounds = element.getBoundingClientRect();
			let cx = event.touches[0].clientX;
			let cy = event.touches[0].clientY;
			originalClick = { x: cx, y: cy };
			centerOffset = { x: cx - (bounds.x + bounds.width / 2), y: cy - (bounds.y + bounds.height / 2) };
			setDragState({
				dragging: true,
				mouse: { x: cx, y: cy },
				dragged: { x: 0, y: 0 },
				center: { x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 },
			});
		}

		function onTouchMove(event) {
			let cx = event.touches[0].clientX;
			let cy = event.touches[0].clientY;
			setDragState({
				dragging: true,
				mouse: { x: cx, y: cy },
				dragged: { x: cx - originalClick.x, y: cy - originalClick.y },
				center: { x: cx - centerOffset.x, y: cy - centerOffset.y },
			})
		}

		function onTouchEnd(event) {
			window.removeEventListener("touchmove", onTouchMove);
			window.removeEventListener("touchend", onTouchEnd);
			setDragState({
				dragging: false,
			})
		}


		element.addEventListener("mousedown", onMouseDown);
		element.addEventListener("touchstart", onTouchStart);

		return () => {
			element.removeEventListener("mousedown", onMouseDown);
			window.removeEventListener("mousemove", onMouseMove);
			window.removeEventListener("mouseup", onMouseUp);
		}
	}, [ref]);

	return {
		dragging: dragState.dragging,
		mouse: dragState.dragging,
		dragged: dragState.dragged,
		center: dragState.center,
	};
};

