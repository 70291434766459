import React, { useContext, useRef, useState } from "react";
import UserContext from "contexts/UserContext";
import { AppBar, Toolbar, Typography, Menu, MenuItem, ListItem, ListItemText, ListItemIcon, Divider, IconButton, Button, Link as MUILink } from "@mui/material";
import { Logout, Menu as MenuIcon, AccountCircleOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ENV } from "Environment";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/inscap.svg';

export default function Header({ onHamburgerClick }) {

	const navigate = useNavigate();

	const { user, logout } = useContext(UserContext)

	const [menuAnchor, setMenuAnchor] = useState(null);

	const userIconRef = useRef(null);
	
	return (	
		<>
			<AppBar sx={{
				background: ENV.headerColor,
				boxShadow: 'none',
				borderBottom: '1px solid #D4D4D4',
				paddingTop: '5px',
			}}>
				<Toolbar>

					<IconButton aria-label="home" sx={{ color: "45556A", marginRight: '24px' }} onClick={() => {onHamburgerClick()}}>
						<MenuIcon />
					</IconButton>

					<Link to="/" style={{ lineHeight: 0, marginRight: 'auto' }}>
						<img src={logo} alt="inscap" />
					</Link>
					
					{ user && <MUILink component={Button} underline="none" onClick={() => setMenuAnchor(userIconRef.current)}>
						<Typography sx={{
							color: "#45556A",
							fontWeight: 400,
							fontSize: 13,
							letterSpacing: '0.5px',
							textTransform: 'none',
							marginRight: '-10px',
						}}>
							{
								(user.contact && user.contact.name) ||
								(user.inscap && user.inscap.email && user.inscap.email.address) 
							}
						</Typography>
					</MUILink> }
					<IconButton ref={userIconRef} sx={{ color: '#5F5F5F' }} onClick={() => setMenuAnchor(userIconRef.current)}>
						<AccountCircleOutlined />
					</IconButton>

					<Menu
						id="menu-appbar"
						keepMounted
						anchorEl={menuAnchor}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={Boolean(menuAnchor)}
						onClose={() => setMenuAnchor(null)}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							}
						}}
					>
						{ (user.contact && user.contact.name) ?
							<ListItem sx={{ paddingBottom: 0 }}>
								<ListItemText>{ user.contact.name }</ListItemText>
							</ListItem>
						: null }
						{/*
						<ListItem sx={{ paddingTop: 0 }}>
							<Typography variant="caption">{ user.cognito.username }</Typography>
						</ListItem>
						*/}
						{ user.client_name ? /* TODO: was this field changed? */
							<ListItem sx={{ paddingTop: 0 }}>
								<Typography variant="caption">{user.client_name}</Typography>
							</ListItem>
						: user.isAdmin ?
							<ListItem sx={{ paddingTop: 0 }}>
								<Typography variant="caption">Admin</Typography>
							</ListItem>
						: null }

						<Divider />

						<MenuItem onClick={() => {
							logout();
							navigate('/');
						}}>
							<ListItemIcon>
								<Logout fontSize="small" />
							</ListItemIcon>
							Logout
						</MenuItem>
					</Menu>

				</Toolbar>
			</AppBar>
			<Toolbar sx={{ marginBottom: 3 }}></Toolbar>
		</>
	);

}