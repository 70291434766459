import { Alert, Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { missingNewChargeText, missingHistoricChargeText, calculateBondOverrideRate, calculateBondOverrideFromRate } from "BondFunctions";
import { formatMoney, isBlank } from "Utils";
import { OverrideDollarField, OverrideDollarRateField } from "components/common/OverrideField";

export default function BondTotals({ creatingNew, bond, updateBond, facility, frontingBank, adminMode }) {

	const bondCharge = isBlank(bond.bond_fee_override) ? bond.calculated_bond_fee : bond.bond_fee_override;
	const minimumBondCharge = facility ? facility.minimum_bond_charge : 0;
	const showMinimumBondChargeWarning = !isBlank(bondCharge) && (bondCharge < minimumBondCharge);

	const frontingCharge = isBlank(bond.fronting_fee_override) ? bond.calculated_fronting_fee : bond.fronting_fee_override;
	const minimumFrontingCharge = frontingBank ? frontingBank.minimum_fronting_charge : 0;
	const showMinimumFrontingChargeWarning = !isBlank(frontingCharge) && (frontingCharge < minimumFrontingCharge);


	const nullMessage = creatingNew ? missingNewChargeText : missingHistoricChargeText;


	return <>
		<Stack spacing={2}>
			<OverrideDollarRateField
				adminMode={adminMode}
				calculatedValue={bond.calculated_bond_fee}
				overrideValue={bond.bond_fee_override}
				calculatePercentage={(overrideValue) => { return calculateBondOverrideRate(bond, overrideValue, 1); }}
				calculateRateFromPercentage={(percentage) => { return calculateBondOverrideFromRate(bond, percentage); }}
				onChange={(newOverrideValue) => { updateBond({ bond_fee_override: newOverrideValue }); }}
				label="Bond Fee"
				overrideLabel="Override Bond Fee"
				rateLabel="Rate"
				nullMessage={nullMessage}
			/>
			{ showMinimumBondChargeWarning && <Box>
				<Alert severity="info" sx={{marginTop: -1}}>This bond has a minimum Bond Fee of {formatMoney(minimumBondCharge)}</Alert>
			</Box> }

			{ bond.fronted && <>
				<OverrideDollarRateField
					adminMode={adminMode}
					calculatedValue={bond.calculated_fronting_fee}
					overrideValue={bond.fronting_fee_override}
					calculatePercentage={(overrideValue) => { return calculateBondOverrideRate(bond, overrideValue, 1); }}
					calculateRateFromPercentage={(percentage) => { return calculateBondOverrideFromRate(bond, percentage); }}
					onChange={(newOverrideValue) => { updateBond({ fronting_fee_override: newOverrideValue }); }}
					label="Fronting Fee"
					overrideLabel="Override Fronting Fee"
					rateLabel="Rate"
					nullMessage={nullMessage}
				/>			
				{ showMinimumFrontingChargeWarning && <Box>
					<Alert severity="info" sx={{marginTop: -1}}>This bond has a minimum Fronting Fee of {formatMoney(minimumFrontingCharge)}</Alert>
				</Box> }
			</> }

			<OverrideDollarField 
				adminMode={adminMode}
				calculatedValue={bond.calculated_issuance_fee}
				overrideValue={bond.issuance_fee_override}
				onChange={(newOverrideValue) => { updateBond({ issuance_fee_override: newOverrideValue }); }}
				label="Issuance Fee ex GST"
				overrideLabel="Override Issuance Fee"
				nullMessage={nullMessage}
			/>
			<Box>
				<Typography variant="label">Issuance Fee inc GST</Typography>							
				<Typography variant="total">
					{ !isBlank(bond.issuanceFeeIncGST) ?
						formatMoney(bond.issuanceFeeIncGST, 2)
					:
						nullMessage
					}
				</Typography>
			</Box>
		</Stack>

		<Divider sx={{my: 4}}/>

		<Box>
			<Typography variant="label">Total</Typography>
			<Typography variant="grandtotal">
				{ !isBlank(bond.total_charge) ?
					formatMoney(bond.total_charge, 2)
				:
					nullMessage
				}
			</Typography>
		</Box>
	</>

}