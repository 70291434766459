import React, {
	useState
} from "react";
import { Auth } from "aws-amplify";
import useInput from "hooks/useInput";
import FormError from 'components/common/FormError';
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";

export default function MfaForm({ cognitoUser, challenge, challengeParam, onMfaConfirmed, onMfaTimeout }) {

	// Challenge is kept as a separate parameter in case it differs from the cognitoUser.challengeName due to the workarounds for SELECT_MFA_TYPE 
	
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(null);

	const { value: code, bind: bindCode } = useInput("", true);

	const handleSubmit = async(event) => {
		event.preventDefault();

		setLoading(true);


		// You need to get the code from the UI inputs
		// and then trigger the following function with a button click
		//const code = getCodeFromUserInput();
		// If MFA is enabled, sign-in should be confirmed with the confirmation code
		//const loggedUser = await Auth.confirmSignIn(
		//    user,   // Return object from Auth.signIn()
		//    code,   // Confirmation code  
		//    mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
		//);

		try {
			const updatedUser = await Auth.confirmSignIn(cognitoUser, code, challenge);
			console.log("updatedUser", updatedUser)
			setLoading(false);
			onMfaConfirmed(updatedUser);			
		} catch(error) {
			console.log(error);
			setLoading(false);
			if (error && error) {
				// TODO: Leaving this screen for too long means every request ends in 400. Need to go back to start of login flow
				if (error.code === "NotAuthorizedException") onMfaTimeout("Session timed out, please try again.");
				else setFormError(error.message);
			} else {
				setFormError("Failed to submit Multifactor Authentication code");
			}
		}

	};		


	return (
		<form onSubmit={ handleSubmit }>
			<Stack spacing={2}>
				<Typography variant="h6">Multifactor authentication</Typography>
				
				{
					challenge === 'SMS_MFA' ?
						challengeParam && challengeParam.CODE_DELIVERY_DESTINATION ?
							<Typography variant="caption">Enter the code sent to {challengeParam.CODE_DELIVERY_DESTINATION}</Typography>
						:
							<Typography variant="caption">Enter the code sent to you via SMS</Typography>
					: challenge === 'SOFTWARE_TOKEN_MFA' ?
						<Typography variant="caption">Enter the code supplied by your authenticator App</Typography>
					: null
				}				

				<TextField label="Code" type="text" {...bindCode} />
				<Button variant="contained" size="large" type="submit" disabled={loading} >
					{loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
					Submit
				</Button>
				{ formError &&
					<FormError>{formError}</FormError>
				}
			</Stack>
		</form>
	);
};

