import { Container, Typography } from "@mui/material";
import { Link, Navigate, useRoutes } from "react-router-dom";
import ClientRoute from "components/routes/ClientRoute";
import AdminRoute from "components/routes/AdminRoute";
import { ClientDashboardPage, UndercoverDashboardPage } from "components/pages/ClientDashboardPage";
import EditClientPage from "components/pages/EditClientPage";
import AdminDashboardPage from 'components/pages/AdminDashboardPage';
import PageContainer from 'components/layout/PageContainer';
import { AdminBondCalculatorPage, ClientBondCalculatorPage, UndercoverBondCalculatorPage } from 'components/pages/BondCalculatorPage';
import EditContactPage from 'components/pages/EditContactPage';
import { AdminBondDetailPage, ClientBondDetailPage, UndercoverBondDetailPage } from 'components/pages/BondDetailPage';
import EditFacilityPage from 'components/pages/EditFacilityPage';
import UndercoverRoute from "components/routes/UndercoverRoute";
import AdminClientsPage from "components/pages/AdminClientsPage";
import { AdminClientFinancialPage, ClientClientFinancialPage } from "components/pages/ClientFinancialPage";
import AdminIssuersPage from "components/pages/AdminIssuersPage";
import EditIssuerPage from "components/pages/EditIssuerPage";
import CompanyProfilePage from "components/pages/CompanyProfilePage";
import CompanyFacilityPage from "components/pages/CompanyFacilityPage";
import AdminContactsPage from "components/pages/AdminContactsPage";
import { DataroomDashboardPage } from "components/pages/DataroomDashboardPage";
import DataroomClientPage from "components/pages/DataroomClientPage";
//import { ClientInvoicesPage, UndercoverInvoicesPage } from "components/pages/InvoicesPage";
//import { ClientInvoicePage, UndercoverInvoicePage } from "components/pages/InvoicePage";
import { AdminProjectsPage, ClientProjectsPage, DataroomProjectsPage, UndercoverProjectsPage } from "components/pages/ProjectsPage";
import { AdminProjectPage, ClientProjectPage } from "components/pages/ProjectPage";


export default function AppRoutes({ user }) {

	const mainRoutes = [

		{ path: '*', element:
			<Navigate to="/404" replace={true} />
		},

	];

	if (user.isAdmin) {

		mainRoutes.push(
			{ path: "/", element:
				<AdminRoute>
					<AdminDashboardPage />
				</AdminRoute>
			},
			{ path: "/clients", element:
				<AdminRoute>
					<AdminClientsPage />
				</AdminRoute>
			},
			{ path: "/client/:id/dashboard", element:
				<UndercoverRoute>
					<UndercoverDashboardPage />
				</UndercoverRoute>
			},

			/*
			{ path: "/client/:id/invoices", element:
				<UndercoverRoute>
					<UndercoverInvoicesPage />
				</UndercoverRoute>
			},
			{ path: "/invoice/:id", element:
				<UndercoverRoute>
					<UndercoverInvoicePage />
				</UndercoverRoute>
			},
			*/

			{ path: "/contacts", element:
				<AdminRoute>
					<AdminContactsPage />
				</AdminRoute>
			},

			{ path: "/bond", element:
				<AdminRoute>
					<AdminBondCalculatorPage />
				</AdminRoute>
			},
			{ path: "/client/bond", element:
				<UndercoverRoute>
					<UndercoverBondCalculatorPage />
				</UndercoverRoute>
			},
			
			{ path: "/bond/:id/detail/", element:
				<AdminRoute>
					<AdminBondDetailPage />
				</AdminRoute>
			},
			{ path: "/client/bond/:id/detail", element:				
				<UndercoverRoute>
					<UndercoverBondDetailPage />
				</UndercoverRoute>
			},

			{ path: "/bond/extend/:id", element:				
				<AdminRoute>
					<AdminBondDetailPage extensionMode={true} />
				</AdminRoute>
			},

			{ path: "/client/:id/financial", element:
				<AdminRoute>
					<AdminClientFinancialPage />
				</AdminRoute>
			},
			{ path: "/client/:id", element:
				<AdminRoute>
					<EditClientPage />
				</AdminRoute>
			},
			{ path: "/facility/:id", element:
				<AdminRoute>
					<EditFacilityPage />
				</AdminRoute>
			},
			{ path: "/contact/:id", element:
				<AdminRoute>
					<EditContactPage />
				</AdminRoute>
			},

			{ path: "/issuers", element:
				<AdminRoute>
					<AdminIssuersPage />
				</AdminRoute>
			},
			{ path: "/issuer/:id", element:
				<AdminRoute>
					<EditIssuerPage />
				</AdminRoute>
			},

			{ path: "/projects", element:
				<AdminRoute>
					<AdminProjectsPage />
				</AdminRoute>
			},
			{ path: "/client/:id/projects", element:
				<AdminRoute>
					<UndercoverProjectsPage />
				</AdminRoute>
			},
			{ path: "/project/:id", element:
				<AdminRoute>
					<AdminProjectPage />
				</AdminRoute>
			},
		);

	} else if (user.isDataroom) {

		mainRoutes.push(
			{ path: "/", element:
				<ClientRoute>
					<DataroomDashboardPage />
				</ClientRoute>
			},			
			{ path: "/client/:id", element:
				<ClientRoute>
					<DataroomClientPage />
				</ClientRoute>
			},
			{ path: "/client/:id/projects", element:
				<ClientRoute>
					<DataroomProjectsPage />
				</ClientRoute>
			},
			{ path: "/project/:id", element:
				<ClientRoute>
					<ClientProjectPage />
				</ClientRoute>
			},
			{ path: "/bond/:id/detail", element:
				<ClientRoute>
					<ClientBondDetailPage />
				</ClientRoute>
			},

		);

	} else if (user.isSubsidiary) {

		mainRoutes.push(
			{ path: "/", element:
				<ClientRoute>
					<ClientDashboardPage />
				</ClientRoute>
			},
			
			{ path: "/company", element:
				<ClientRoute>
					<CompanyProfilePage />
				</ClientRoute>
			},
			{ path: "/facility/:id", element:
				<ClientRoute>
					<CompanyFacilityPage />
				</ClientRoute>
			},

			{ path: "/bond", element:
				<ClientRoute>
					<ClientBondCalculatorPage />
				</ClientRoute>
			},
			
			{ path: "/bond/:id/detail", element:
				<ClientRoute>
					<ClientBondDetailPage />
				</ClientRoute>
			},
		);

	} else { // Primary

		mainRoutes.push(
			{ path: "/", element:
				<ClientRoute>
					<ClientDashboardPage />
				</ClientRoute>
			},
			
			{ path: "/company", element:
				<ClientRoute>
					<CompanyProfilePage />
				</ClientRoute>
			},
			{ path: "/facility/:id", element:
				<ClientRoute>
					<CompanyFacilityPage />
				</ClientRoute>
			},

			{ path: "/projects", element:
				<ClientRoute>
					<ClientProjectsPage />
				</ClientRoute>
			},

			{ path: "/financial", element:
				<ClientRoute>
					<ClientClientFinancialPage />
				</ClientRoute>
			},

			/*
			{ path: "/invoices", element:
				<ClientRoute>
					<ClientInvoicesPage />
				</ClientRoute>
			},
			{ path: "/invoice/:id", element:
				<ClientRoute>
					<ClientInvoicePage />
				</ClientRoute>
			},
			*/

			{ path: "/bond", element:
				<ClientRoute>
					<ClientBondCalculatorPage />
				</ClientRoute>
			},			
			{ path: "/bond/:id/detail", element:
				<ClientRoute>
					<ClientBondDetailPage />
				</ClientRoute>
			},
			{ path: "/project/:id", element:
				<ClientRoute>
					<ClientProjectPage />
				</ClientRoute>
			},
		);

	}
	

	const routes = [
		{ path: "/401", element:
			<Container pt={2} sx={{}}>
				<Typography variant="h6" mb={1}>401 - Not authorised</Typography>
				<Link to="/">Home</Link>
			</Container>
		},
		{ path: "/403", element:
			<Container>
				<Typography variant="h6" mb={1}>403 - Forbidden</Typography>
				<Link to="/">Home</Link>
			</Container>
		},
		{ path: "/404", element:
			<Container sx={{paddingTop: 2, textAlign: 'center'}}>
				<Typography variant="h6" mb={1}>404 - Page not found</Typography>
				<Link to="/">Home</Link>
			</Container>
		},
	
		{ path: "*", element:
			<PageContainer>
				{ useRoutes(mainRoutes) }
			</PageContainer>
		},


	];


	return useRoutes(routes);

}

