import { TextField, Stack, MenuItem, Divider, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { DollarTextField } from "components/common/FormattedTextField";
import CentreLoader from "components/common/CentreLoader";
import { formatNumber, formatPercentage, searchByField } from "Utils";
import DatePicker from "components/common/DatePicker";
import { Box } from "@mui/system";
import { clearBond, clientSelfIndemnifierId, doBondCalculations } from "BondFunctions";
import ExpandableCard from "components/layout/ExpandableCard";
import SpacedButton from "components/common/SpacedButton";
import FlexBox from "components/layout/FlexBox";
import BondTotals from "./BondTotals";
import LayoutContext from "contexts/LayoutContext";
import { useContext } from "react";
import NullableTextField from "components/common/NullableTextField";
import UserContext from "contexts/UserContext";

export default function BondEditor({
	staticData,
	clientId,
	client,
	facilitys,
	indemnifiers,
	label,
	bond,
	isOnlyBond,
	onRemove,
	onBondUpdated,
	onDuplicate,
	adminMode,
}) {

	const { wideLayout } = useContext(LayoutContext);

	const { user } = useContext(UserContext);

	function updateBond(updatedFields) {
		var updatedBond = { ...bond, ...updatedFields };
		doBondCalculations(updatedBond, searchByField(facilitys, 'id_facility', updatedBond.id_facility), staticData);
		onBondUpdated(updatedBond);
	}
	
	function clearEditor() {
		var cleared = { ...bond };
		clearBond(cleared);
		onBondUpdated(cleared);
	}

	let indemnifier = (client && bond.id_indemnifier && (bond.id_indemnifier !== clientSelfIndemnifierId)) ? searchByField(indemnifiers, 'id_indemnifier', bond.id_indemnifier) : null;
	let facility = client ? searchByField(facilitys, 'id_facility', bond.id_facility) : null;
	let frontingBank = facility ? searchByField(facility.facility_banks, 'id_bank', bond.id_bank) : null;
	let bondType = staticData ? searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type) : null;


	function selectIndemnifier(id_indemnifier) {
		let indemnifier = (client && id_indemnifier && (id_indemnifier !== clientSelfIndemnifierId)) ? searchByField(indemnifiers, 'id_indemnifier', id_indemnifier) : null;
		// Reset the facility if it is not applicable to this indemnifier
		var keepFacility = false;
		if (bond.id_facility) {
			var newFacilitys = indemnifier ? indemnifier.facility_indemnifiers : facilitys;
			for (var i = newFacilitys.length - 1; i >= 0; i--) {
				var f2 = newFacilitys[i];
				if (bond.id_facility === f2.id_facility) keepFacility = true;
			}
		}
		updateBond({ id_indemnifier: indemnifier ? indemnifier.id_indemnifier: null, facility: keepFacility ? bond.id_facility : null})
	}

	// Filter down to only active facilities applicable to the selected indemnifier
	var availableFacilitys = client ? facilitys.concat() : null;
	if (availableFacilitys && indemnifier) {
		for (var i = availableFacilitys.length - 1; i >= 0; i--) {
			var f1 = availableFacilitys[i];
			var found = false;
			for (var j = indemnifier.facility_indemnifiers.length - 1; j >= 0; j--) {
				var f2 = indemnifier.facility_indemnifiers[j];
				if (f1.id_facility === f2.id_facility) found = true;
			}
			if (!found) availableFacilitys.splice(i, 1);
		}
	} else if (user.isSubsidiary && !indemnifier) {
		availableFacilitys = [];
	}


	const daysString = isNaN(bond.days) ? "" : formatNumber(bond.days) + " " + (bond.days === 1 ? "day" : "days");

	return <ExpandableCard variant="hero" headerContent={
		<Typography variant="cardHeader">{label}</Typography>
	}>
		<Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
			<Grid item xs={12} md={12}>
				<NullableTextField label="Reference" type="text" fullWidth value={bond.reference} onChange={(value) => { updateBond({ reference: value }); }} />
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField label="Bond Type" fullWidth required value={(bondType ? bondType.id_bond_type : null) || ''} select onChange={(event) => { updateBond({ id_bond_type: event.target.value }); }}>
					{ staticData.bond_types.map((bondType) => {
						return <MenuItem key={bondType.id_bond_type} value={bondType.id_bond_type}>{bondType.name}</MenuItem>
					})}									
				</TextField>
			</Grid>
			<Grid item xs={12} md={4}>
				<FormControlLabel label="Expires" control={
					<Checkbox checked={bond.expires || false} onChange={(event) => {updateBond({ expires: event.target.checked })}} sx={{marginLeft: 1}} />
				} />
			</Grid>
		</Grid>

		{ clientId && bondType && 
			( client ? <>
				<Divider sx={{my: 4}}/>

				<Stack spacing={2}>
					<DollarTextField label="Bond Value"
						value={bond.face_value}
						onChange={(numericValue) => { updateBond({ face_value: numericValue }); }}
					/>
					<Stack direction={ wideLayout ? "row" : "column" } alignItems="center" spacing={2}>
						<DatePicker
							value={bond.date_start}
							label={wideLayout ? null : "Start date"}
							renderInput={(params) => <TextField {...params} fullWidth={!wideLayout} />}
							onChange={(value) => { updateBond({ date_start: value }); }}
						/>
						{ wideLayout && <Typography>to</Typography> }
						<DatePicker
							value={bond.date_finish}
							label={wideLayout ? null : "End date"}
							renderInput={(params) => <TextField {...params} fullWidth={!wideLayout} />}
							onChange={(value) => { updateBond({ date_finish: value }); }}
						/>
						<Typography sx={{whiteSpace:"nowrap", paddingRight: 2, color: (bond.days < 0 ? "red" : null)}}>{daysString}</Typography>
					</Stack>
					<TextField label="Indemnifier" required value={(indemnifier ? indemnifier.id_indemnifier : (user.isSubsidiary ? '' : clientSelfIndemnifierId)) || ''} select onChange={(event) => { selectIndemnifier(event.target.value); }}>
						{ !user.isSubsidiary && <MenuItem key={clientSelfIndemnifierId} value={clientSelfIndemnifierId}>{client.name}</MenuItem> }
						{ indemnifiers.map((indemnifier) => {
							if (!indemnifier.active) return null;
							return <MenuItem key={indemnifier.id_indemnifier} value={indemnifier.id_indemnifier}>{indemnifier.name}</MenuItem>
						})}									
					</TextField>
					<TextField label="Issuer" required value={searchByField(availableFacilitys, 'id_facility', bond.id_facility) ? bond.id_facility || '' : ''} select onChange={(event) => { updateBond({ id_facility: event.target.value }); }}>
						{ availableFacilitys.map((facility) => {
							if (!facility.active) return null;
							const bondTypeFacilityRate = bondType ? searchByField(facility.bond_type_facility_rates, 'id_bond_type', bondType.id_bond_type) : null;
							return <MenuItem key={facility.id_facility} value={facility.id_facility}>
								{facility.issuer_name + (bondTypeFacilityRate ? ' - ' + formatPercentage(bondTypeFacilityRate.rate) : '')}
							</MenuItem>
						})}									
					</TextField>
					{ bond.fronted && bond.id_facility && facility &&
						<TextField label="Fronting Bank" value={bond.id_bank || ''} select onChange={(event) => { updateBond({ id_bank: event.target.value }); }}>
							{ facility.facility_banks.map((facility_bank) => {
								if (!facility_bank.active) return null;
								return <MenuItem key={facility_bank.id_bank} value={facility_bank.id_bank}>{searchByField(staticData.banks, 'id_bank', facility_bank.id_bank).name + (bond.id_duration ? ' - ' + searchByField(facility_bank.facility_bank_duration_rates, 'id_duration', bond.id_duration).rate + '%' : '')}</MenuItem>
							})}		
						</TextField>
					}												
				</Stack>

				{ facility &&
					<>
						<Divider sx={{my: 4}}/>

						<BondTotals creatingNew={false} bond={bond} updateBond={updateBond} facility={facility} frontingBank={frontingBank} adminMode={adminMode} />
					</>
				}

			</> : <CentreLoader />)
		}


		<Box mt={5}>
			<Divider sx={{ position: 'absolute', left: 0, right: 0, opacity: 0.5 }}/>
			<FlexBox pt={2} mb={-2}>
				{ !isOnlyBond && <SpacedButton onClick={() => {onRemove(bond)}}>Remove from Quote</SpacedButton> }
				<SpacedButton onClick={() => {onDuplicate(bond)}} sx={{marginLeft: 'auto'}}>Duplicate</SpacedButton>
				<SpacedButton onClick={clearEditor} sx={{marginLeft: '1rem'}}>Clear</SpacedButton>
			</FlexBox>
		</Box>


	</ExpandableCard>

}