import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/layout/FlexBox";
import StaticDataContext from "contexts/StaticDataContext";
import { useContext } from "react";
import { calculateBondStatusUsage } from "BondFunctions";
import { formatMoney, searchByField } from "Utils";
import { red } from "@mui/material/colors";
import SectionedBar from "components/common/SectionedBar";


export default function FacilityCapacityBar({ facility, highestCapacity, facilityColor, additionalBonds, additionalBondColors, variant, ...props }) {

	const theme = useTheme();

	const staticData = useContext(StaticDataContext);

	if (!staticData || !facility) return null;

	const { pending, active } = calculateBondStatusUsage(facility.bond_status_totals, staticData.bond_statuses);



	var issuer = searchByField(staticData.issuers, 'id_issuer', facility.id_issuer);

	var sections = [];
	if (active > 0) sections.push({
		color: facilityColor || '#7B7F8D',
		progress: active / facility.limit,
	});
	if (pending > 0) sections.push({
		color: facilityColor || '#7B7F8D',
		opacity: 0.6,
		progress: pending / facility.limit,
	});
	var utilised = pending + active;
	if (additionalBonds) {
		for (var i = 0; i < additionalBonds.length; i++) {
			var bond = additionalBonds[i];
			utilised += bond.face_value;
			sections.push({
				color: additionalBondColors ? additionalBondColors[i] : theme.bondColors[i % theme.bondColors.length],
				progress: bond.face_value / facility.limit,
			});
		}
	}

	var utilisedStyle = { };
	if (variant === "wide") utilisedStyle.fontWeight = 500;
	if (utilised > facility.limit) utilisedStyle.color = red[700];
	var totalStyle = { };
	if (variant === "wide") totalStyle.fontWeight = 500;

	return <Box {...props}>
		{ (variant === "wide") ? <>
			<FlexBox center>
				<Typography variant="h6" mr={2}>{issuer.name}</Typography>
				<Typography variant="secondary"><span style={utilisedStyle}>{formatMoney(utilised, 2)}</span> of <span style={totalStyle}>{formatMoney(facility.limit, 2)}</span> total capacity</Typography>
			</FlexBox>
			<SectionedBar sections={sections} sx={{width: (highestCapacity ? (facility.limit / highestCapacity * 100) + "%" : null)}} />
		</> : <>
			<SectionedBar sections={sections} sx={{marginBottom: 1}}/>
			<FlexBox center>
				<Typography variant="tertiary"><span style={utilisedStyle}>{formatMoney(utilised, 2)}</span> out of <span style={totalStyle}>{formatMoney(facility.limit, 2)}</span></Typography>
			</FlexBox>

		</>}
	</Box>

}