import React from "react";
import { Checkbox } from "@mui/material";
import { searchByField } from "Utils";
import SectionTitle from "components/common/SectionTitle";
import PaginatedTable from "components/modules/tables/PaginatedTable";

export default function IndemnifiersTable({ facilityOrder, indemnifiers, updateIndemnifierField, setIndemnifierFacilityActive, actions }) {
	let columns = [
		{ key: 'indemnifier', cell: 'Indemnifier', minWidth: 200},
		{ key: 'abn', cell: 'ABN', minWidth: 140 },
	];
	facilityOrder.forEach(({ name, id_issuer }) => {
		columns.push({ key: id_issuer, width: 120, cell: name });
	});

	let rows = [];
	indemnifiers.forEach((indemnifier) => {
		if (indemnifier && indemnifier.active) {
			rows.push({ key: "bond_type_" + indemnifier.id_indemnifier, cells:
				[
					indemnifier.name,
					indemnifier.abn,
				].concat(facilityOrder.map(({ id_facility }) => {
					const active = searchByField(indemnifier.facility_indemnifiers, 'id_facility', id_facility) !== null;
					return <Checkbox disabled checked={active} />
				}))
			});
		}
	});	

	return <>
		<SectionTitle title="Indemnifiers" actions={actions} />
		<PaginatedTable columns={columns} rows={rows} sx={{mb: 4}} />
	</>
}




