import React, { useContext } from "react";
import StaticDataContext from "contexts/StaticDataContext";
import API from "API";
import { useGet } from "hooks/useAPI";
import AddButton from "components/common/AddButton";
import CentreLoader from "components/common/CentreLoader";
import UserContext from "contexts/UserContext";
import ClientsTable from "components/modules/tables/ClientsTable";

export default function AdminClientsPage() {

	const { user } = useContext(UserContext);

	const staticData = useContext(StaticDataContext);
	const [clientsData] = useGet(API.getClientsTotals(user));
	const { clients } = clientsData || {};

	if (!staticData) return <CentreLoader />;

	return <>
		{ clients ? <>
			<ClientsTable title="Clients" clients={clients} actions={
				<AddButton to="/client/add">Add client</AddButton>
			} />
		</> : <CentreLoader /> }	
	</>

};

