import { CancelOutlined, LockOpenOutlined } from "@mui/icons-material";
import { IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DatePicker from "components/common/DatePicker";
import { DollarTextField, PercentageTextField } from "components/common/FormattedTextField";
import StruckOut from "components/common/Struckout";
import FlexBox from "components/layout/FlexBox";
import moment from "moment/moment";
import { useMemo } from "react";
import { useState } from "react";
import { formatMoney, isBlank } from "Utils";

export function OverrideDollarField({ adminMode, calculatedValue, overrideValue, onChange, label, overrideLabel, nullMessage }) {

	if (nullMessage === undefined) nullMessage = "-";
	if (onChange === undefined) onChange = () => {};

	const [unlocked, setUnlocked] = useState(overrideValue !== null);

	return <FlexBox align="end">
		<Box>
			<Typography variant="label">{label}</Typography>
			<Typography variant="total">
				{ !isBlank(overrideValue) ?
					adminMode ? <StruckOut>{!isBlank(calculatedValue) ? formatMoney(calculatedValue, 2) : nullMessage}</StruckOut> 
					: formatMoney(overrideValue, 2)
				:
					!isBlank(calculatedValue) ? formatMoney(calculatedValue, 2) : nullMessage
				}
			</Typography>
		</Box>
		{ adminMode && <FlexBox ml="auto" mb="-7px">
			{ unlocked ? <>
				<IconButton sx={{margin: 'auto', marginRight: 1}} onClick={() => {
					setUnlocked(false);
					onChange(null);
				}}><CancelOutlined /></IconButton>
				<DollarTextField label={overrideLabel}
					value={overrideValue}
					onChange={(numericValue) => { onChange(numericValue); }}
				/>
			</> : <>
				<IconButton sx={{marginBottom: '7px'}} onClick={() => {
					setUnlocked(true);
					onChange(0);
				}}><LockOpenOutlined /></IconButton>
			</> }
		</FlexBox> }
	</FlexBox>

}

export function OverrideDollarRateField({ adminMode, calculatedValue, overrideValue, calculatePercentage, calculateRateFromPercentage, onChange, label, overrideLabel, rateLabel, nullMessage }) {

	if (nullMessage === undefined) nullMessage = "-";
	if (onChange === undefined) onChange = () => {};

	const [unlocked, setUnlocked] = useState(overrideValue !== null);

	return <FlexBox align="end">
		<Box>
			<Typography variant="label">{label}</Typography>
			<Typography variant="total">
				{ !isBlank(overrideValue) ?
					adminMode ? <StruckOut>{!isBlank(calculatedValue) ? formatMoney(calculatedValue, 2) : nullMessage}</StruckOut> 
					: formatMoney(overrideValue, 2)
				:
					!isBlank(calculatedValue) ? formatMoney(calculatedValue, 2) : nullMessage
				}
			</Typography>
		</Box>
		{ adminMode && <FlexBox ml="auto" mb="-7px">
			{ unlocked ? <>
				<IconButton sx={{margin: 'auto', marginRight: 1}} onClick={() => {
					setUnlocked(false);
					onChange(null);
				}}><CancelOutlined /></IconButton>
				<DollarTextField label={overrideLabel}
					sx={{width: 150}}
					value={overrideValue}
					onChange={(numericValue) => { onChange(numericValue); }}
				/>
				<PercentageTextField label={rateLabel}
					sx={{width: 100, ml: 1}}
					value={ calculatePercentage(overrideValue) }
					onChange={(numericValue) => { onChange(calculateRateFromPercentage(numericValue)) }}
				/>
			</> : <>
				<IconButton sx={{marginBottom: '7px'}} onClick={() => {
					setUnlocked(true);
					onChange(0);
				}}><LockOpenOutlined /></IconButton>
			</> }
		</FlexBox> }
	</FlexBox>

}

export function OverrideDateField({ adminMode, calculatedValue, overrideValue, onChange, label, overrideLabel, nullMessage }) {

	if (nullMessage === undefined) nullMessage = "-";
	if (onChange === undefined) onChange = () => {};

	const [unlocked, setUnlocked] = useState(overrideValue !== null);

	const formattedValue = useMemo(() => {
		if (!calculatedValue) return null;
		return moment(calculatedValue).format("DD/MM/YYYY");
	}, [calculatedValue]);

	return <FlexBox align="end">
		<Box>
			<Typography variant="label">{label}</Typography>
			<Typography variant="total">
				{ !isBlank(overrideValue) ?
					adminMode ? <StruckOut>{!isBlank(calculatedValue) ? formattedValue : nullMessage}</StruckOut> 
					: formatMoney(overrideValue, 2)
				:
					!isBlank(calculatedValue) ? formattedValue : nullMessage
				}
			</Typography>
		</Box>
		{ adminMode && <FlexBox ml="auto" mb="-7px">
			{ unlocked ? <>
				<IconButton sx={{margin: 'auto', marginRight: 1}} onClick={() => {
					setUnlocked(false);
					onChange(null);
				}}><CancelOutlined /></IconButton>
				<DatePicker
					value={overrideValue}
					label={overrideLabel}
					renderInput={(params) => <TextField {...params} fullWidth={true} />}
					onChange={(value) => { onChange(value); }}
				/>

			</> : <>
				<IconButton sx={{marginBottom: '7px'}} onClick={() => {
					setUnlocked(true);
					onChange(new Date(calculatedValue));
				}}><LockOpenOutlined /></IconButton>
			</> }
		</FlexBox> }
	</FlexBox>

}
