import React, { useContext } from "react";
import { Checkbox } from "@mui/material";
import { formatMoney, searchByField } from "Utils";
import SectionTitle from "components/common/SectionTitle";
import PaginatedTable from "components/modules/tables/PaginatedTable";
import moment from "moment";
import UserContext from "contexts/UserContext";
import { useNavigate } from "react-router-dom";
import StaticDataContext from "contexts/StaticDataContext";

export default function FacilitysTable({ facilityStates, facilitys, actions }) {

	const { user } = useContext(UserContext);

	const navigate = useNavigate();

	const staticData = useContext(StaticDataContext);

	const canSeeClientTotals = user && (!user.isSubsidiary);


	let columns = [
		{ key: 'issuer', cell: 'Issuer', width: 130 },
		{ key: 'prime_rate', cell: 'Prime rate', width: 110 },
	].concat(canSeeClientTotals ? [
		{ key: 'capacity', cell: 'Capacity', width: 120 },
	] : []).concat([
		{ key: 'renewal_date', cell: "Renewal date", width: 130 },
	]);
	staticData.banks.forEach((bank) => {
		columns.push({ key: bank.id_bank + '_active', cell: bank.name + " fronted", width: 130 });
	})

	let rows = [];
	facilityStates.forEach(({ name, facilityIndex, active, id_issuer, id_facility }) => {
		const facility = facilitys[facilityIndex];
		if (facility && facility.active) {
			let cells = [
				name,
				active ? facility.prime_rate + "%" : "",
			].concat(canSeeClientTotals ? [
				active ? formatMoney(facility.limit) : "",				
			] : []).concat([
				active ? moment(facility.renewal_date).format('DD/MM/YYYY') : "",
			]);
			staticData.banks.forEach((bank) => {
				const facility_bank = searchByField(facility.facility_banks, 'id_bank', bank.id_bank);
				cells.push(active && <Checkbox disabled checked={facility_bank && facility_bank.active} />);
			});

			rows.push({
				key: id_issuer,
				cells: cells,
				onClick: canSeeClientTotals ? () => { navigate("/facility/" + id_facility) } : null
			});
		}
	});


	return <>
		<SectionTitle title="Facilities" actions={actions} />
		<PaginatedTable columns={columns} rows={rows} sx={{mb: 4}} />
	</>

}

