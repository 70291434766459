import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, IconButton, TextField, Typography } from "@mui/material";
import { anyBlank, calculateFacilityTotals, checkFormValidity, formatMoney, formatNumber, formatPercentage, isBlank, searchByField, stripNonNumeric } from "Utils";
import StaticDataContext from "contexts/StaticDataContext";
import API from "API";
import { useGet, usePost, useUpload } from "hooks/useAPI";
import CentreLoader from "components/common/CentreLoader";
import cloneDeep from 'lodash/cloneDeep';
import SaveDrawer from "components/modules/SaveDrawer";
import StandardTable from "components/modules/tables/StandardTable";
import { DollarTextField } from "components/common/FormattedTextField";
import NullableTextField from "components/common/NullableTextField";
import FlexBox from "components/layout/FlexBox";
import { ArrowLeft, ArrowRight, HighlightOffOutlined } from "@mui/icons-material";
import FinancialFiles from "components/modules/FinancialFiles";
import RatioGraph from "components/common/RatioGraph";
import UserContext from "contexts/UserContext";


export default function Financials({ adminMode, client, facilitys }) {

	const staticData = useContext(StaticDataContext);

	const { user } = useContext(UserContext);

	const [retrievedYears, setRetrievedYears] = useState([]);
	const [yearsData, setYearsData] = useState(null);
	const [yearsFiles, setYearsFiles] = useState(null);
	const [comment, setComment] = useState(null);
	const [financialConstants, setFinancialConstants] = useState(null);
	const [customNameMapping, setCustomNameMapping] = useState(null);
	const [editingCustomFinancial, setEditingCustomFinancial] = useState(null);

	const [postFileUpload] = useUpload(API.uploadFinancialFile(user));

	const [revertTarget, setRevertTarget] = useState(null);

	const [changed, setChanged] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [postFinancials] = usePost(API.postFinancials(user));

	const thisYear = (new Date()).getFullYear();
	const [firstYear, setFirstYear] = useState(thisYear - 2);
	const [lastYear, setLastYear] = useState(thisYear + 1);

	let years = [];
	let yearsToRetrieve = [];
	for (let year = firstYear; year <= lastYear; year++) {
		years.push(year);
		if (yearsToRetrieve.indexOf(year) < 0) yearsToRetrieve.push(year);
	}
	
	const [originalFinancialData] = useGet((client && client.id_client && yearsToRetrieve.length > 0) ? API.getFinancials(user, yearsToRetrieve) : null);

	const formRef = useRef();	



	function shiftYear(offset) {
		setFirstYear(firstYear + offset);
		setLastYear(lastYear + offset);
	}


	useEffect(() => {
		processIncomingFinancialData(originalFinancialData, yearsData, firstYear, lastYear, retrievedYears, customNameMapping, comment, setYearsFiles, setFinancialConstants, setYearsData, setCustomNameMapping, setRetrievedYears, setComment, setRevertTarget);
	}, [originalFinancialData, yearsData, firstYear, lastYear,customNameMapping, retrievedYears, comment, setYearsData, setCustomNameMapping, setRetrievedYears, setFinancialConstants, setComment]);
		


	function updateFinancial(year, sheetName, typeName, financialName, value) {
		let newYearsData = cloneDeep(yearsData);
		setFinancial(newYearsData, year, sheetName, typeName, financialName, value);
		setYearsData(newYearsData);
		setChanged(true);
	}

	function addCustomFinancial(sheetName, typeName) {
		let newMapping = cloneDeep(customNameMapping);
		let newYearsData = cloneDeep(yearsData);

		let sheetMapping = newMapping[sheetName];
		if (!sheetMapping) sheetMapping = newMapping[sheetName] = { };
		let typeMapping = sheetMapping[typeName];
		if (!typeMapping) typeMapping = sheetMapping[typeName] = { };

		let key = null;
		for (let i = 1;; i++) {
			let tryKey = typeName + " " + i;
			if (!typeMapping[tryKey]) {
				key = tryKey;
				break;
			}
		}

		typeMapping[key] = key;

		let year = thisYear;
		let typeData = getOrCreateTypeData(newYearsData, year, sheetName, typeName)
		let name = null;
		typeData.financials[key] = {
			name: name,
			type: typeName,
			amount: 0,
		}

		setCustomNameMapping(newMapping);
		setYearsData(newYearsData);
		setChanged(true);
		setEditingCustomFinancial({ key: key, name: name })
	}

	function remapCustomFinancial(sheetName, typeName, key, value) {
		let newMapping = cloneDeep(customNameMapping);

		let sheetMapping = newMapping[sheetName];
		if (!sheetMapping) sheetMapping = newMapping[sheetName] = { };
		let typeMapping = sheetMapping[typeName];
		if (!typeMapping) typeMapping = sheetMapping[typeName] = { };

		typeMapping[key] = value;

		setCustomNameMapping(newMapping);
		setChanged(true);
	}

	function removeCustomFinancial(sheetName, typeName, key) {
		let newMapping = cloneDeep(customNameMapping);
		let newYearsData = cloneDeep(yearsData);

		let sheetMapping = newMapping[sheetName];
		if (!sheetMapping) sheetMapping = newMapping[sheetName] = { };
		let typeMapping = sheetMapping[typeName];
		if (!typeMapping) typeMapping = sheetMapping[typeName] = { };

		delete typeMapping[key];

		for (let year = firstYear; year <= lastYear; year++) {
			let typeData = getTypeData(newYearsData, year, sheetName, typeName)
			if (typeData) delete typeData.financials[key];
		}

		setCustomNameMapping(newMapping);
		setYearsData(newYearsData);
		setChanged(true);
	}


	function revert() {
		setYearsData(cloneDeep(revertTarget.yearsData));
		setCustomNameMapping(cloneDeep(revertTarget.mapping));
		setFinancialConstants(cloneDeep(revertTarget.constants));
		setComment(revertTarget.comment);
		setChanged(false);
	}


	function save(event) {
		if (checkFormValidity(formRef.current)) {
			setSubmitting(true);

			let r = {
				yearsData: cloneDeep(yearsData),
				mapping: cloneDeep(customNameMapping),
				constants: cloneDeep(financialConstants),
				comment: comment,
			}
			
			let dataToSubmit = {
				client: {
					id_client: client.id_client,
				},
				financial: {
					constants: [
						/*
						{
							key: "Surety",
							value: financialConstants.surety,
						}
						*/
					],
					years: { },
					comment: comment,
				}
			}
			
			for (let year in yearsData) {
				let yearData = yearsData[year];
				let financialYear = dataToSubmit.financial.years[year] = {
					year: year,
					graphs: [ ],
					sheets: [ ],
				}

				for (let sheetName in yearData.sheets) {
					let sheetData = yearData.sheets[sheetName];

					let sheet = {
						name: sheetName,
						financials: [ ],
					};
					financialYear.sheets.push(sheet);

					for (let typeName in sheetData.types) {
						let typeData = sheetData.types[typeName];

						for (let financialName in typeData.financials) {
							let financialData = typeData.financials[financialName];

							let mappedName = financialName;
							let sheetMapping = customNameMapping[sheetName];
							if (sheetMapping) {
								let typeMapping = sheetMapping[typeName];
								if (typeMapping) {
									mappedName = typeMapping[financialName];
								}
							}

							sheet.financials.push({
								type: typeName,
								name: mappedName,
								amount: financialData.amount,
							});

						}
					}
				}

				// Process removals
				let rYear = revertTarget.yearsData[year];
				if (rYear) {
					for (let sheetName in rYear.sheets) {
						let rSheet = rYear.sheets[sheetName];
						let sheet = searchByField(financialYear.sheets, "name", sheetName);
						if (sheet) {
							for (let rTypeName in rSheet.types) {
								let rType = rSheet.types[rTypeName];
								for (let rFinancialName in rType.financials) {
									let rFinancial = rType.financials[rFinancialName];

									let found = false;
									sheet.financials.forEach((financial) => {
										if (financial.type === rTypeName && financial.name === rFinancialName) {
											found = true;
											// Remove blank values
											if (financial.amount === null) financial.remove = true;
										}
									})

									if (!found) {
										sheet.financials.push({
											type: rTypeName,
											name: rFinancialName,
											amount: rFinancial.amount,
											remove: true,
										});
									}
		
								}
							}
						}
					}
				}
					
			}

			postFinancials(dataToSubmit).then((responseData) => {
				setSubmitting(false);
				setRevertTarget(r);
				setChanged(false);
			}).catch((error) => {console.log("Post financials error", error)})
		}
	}


	const { utilised: surety } = (facilitys && staticData) ? calculateFacilityTotals(facilitys, staticData) : { };

	const tableSpace = 20;

	const typeTotals = { };

	const currentAssets = new Array(4).fill(undefined);
	const totalAssets = new Array(4).fill(undefined);
	const currentLiabilities = new Array(4).fill(undefined);
	const totalLiabilities = new Array(4).fill(undefined);
	const capital = new Array(4).fill(undefined);
	const retained = new Array(4).fill(undefined);
	const totalEquity = new Array(4).fill(undefined);
	const ebitda = new Array(4).fill(undefined);
	const hirePurchase = new Array(4).fill(undefined);
	const ebit = new Array(4).fill(undefined);
	const profit = new Array(4).fill(undefined);
	const ratio1 = new Array(4).fill(undefined);
	const ratio2 = new Array(4).fill(undefined);
	const ratio3 = new Array(4).fill(undefined);
	const ratio4 = new Array(4).fill(undefined);

	
	function getYearColumns(overrides) {
		const yearColumns = [];
		years.forEach((year) => {
			yearColumns.push({
				key: year,
				width: 180,
				alignHeader: 'center',
				align: 'right',
				thin: true,
				cell: <FlexBox center justify="center">
					{ (retrievedYears.indexOf(year) >= 0) ? <span>{"FY" + ("" + year).substring(2)}</span> : <CircularProgress size={24} sx={{marginY: -1}} /> }
					{ (year === firstYear) &&
						<IconButton
							sx={{position: 'absolute', left: '5px', marginY: -1}}
							onClick={() => {
								shiftYear(-1);
							}}
						>
							<ArrowLeft />
						</IconButton>
					}
					{ (year === lastYear) &&
						<IconButton
							sx={{position: 'absolute', right: '5px', marginY: -1}}
							onClick={() => {
								shiftYear(1);
							}}
						>
							<ArrowRight />
						</IconButton>
					}
				</FlexBox>,
				...overrides
			});
		});
		return yearColumns;
	}
	
	function renderConstants() {
		return <Card sx={{marginBottom: 2}}>
			<CardContent>
				<Grid container columnSpacing={8} rowSpacing={2}>
					<Grid item xs={12} md={7}>
						<FlexBox center>
							<Typography variant="cell"sx={{marginRight: 'auto'}}>Surety</Typography>
							{/* adminMode ? 
								<DollarTextField compact clearable value={financialConstants.surety} align="right" sx={{maxWidth: '50%'}} onChange={(value) => {
									updateConstants({ surety: value });
								}}/>					
							:
								<Typography>{formatMoney(financialConstants.surety)}</Typography>
							*/}
							<Typography>{formatMoney(surety)}</Typography>
						</FlexBox>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	}

	function renderSummarisedBalanceSheet() {
		let rows = [];
		let columns = [
			{ key: 'label', cell: 'Summarised Balance Sheet', minWidth: 200 },
		].concat(getYearColumns());

		rows.push(createInputRow("Summarised Balance Sheet", "Current Assets", "Cash"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Assets", "Receivables"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Assets", "Contract Assets"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Assets", "WIP (Work in Progress)"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Assets", "Tax Assets"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Assets", "Other"));
		rows.push(createTypeTotalsRow("Current Assets", "Total Current Assets"));
		rows.push(createInputRow("Summarised Balance Sheet", "Non-Current Assets", "PPE"));
		rows.push(createInputRow("Summarised Balance Sheet", "Non-Current Assets", "Goodwill and Intangibles"));
		rows.push(createInputRow("Summarised Balance Sheet", "Non-Current Assets", "Other"));
		rows.push(createTypeTotalsRow("Non-Current Assets", "Total Non-Current Assets"));
		rows.push(createSectionTotalsRow("Total Assets", (year, index) => {
			currentAssets[index] = typeTotals["Current Assets"][index];
			return formatMoney(totalAssets[index] = currentAssets[index] + typeTotals["Non-Current Assets"][index]);
		}));
		rows.push({ space: tableSpace });

		rows.push(createInputRow("Summarised Balance Sheet", "Current Liabilities", "Payables"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Liabilities", "Financial Liabilities"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Liabilities", "Contract Liabilities"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Liabilities", "Current Tax Liabilities"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Liabilities", "Provisions"));
		rows.push(createInputRow("Summarised Balance Sheet", "Current Liabilities", "Other"));
		rows.push(createTypeTotalsRow("Current Liabilities", "Total Current Liabilities"));
		rows.push(createInputRow("Summarised Balance Sheet", "Non-Current Liabilities", "Financial Liabilities"));
		rows.push(createInputRow("Summarised Balance Sheet", "Non-Current Liabilities", "Provisions"));
		rows.push(createTypeTotalsRow("Non-Current Liabilities", "Total Non-Current Liabilities"));
		rows.push(createSectionTotalsRow("Total Liabilities", (year, index) => {
			currentLiabilities[index] = typeTotals["Current Liabilities"][index];
			return formatMoney(totalLiabilities[index] = currentLiabilities[index] + typeTotals["Non-Current Liabilities"][index]);
		}));
		rows.push({ space: tableSpace });

		rows.push(createInputRow("Summarised Balance Sheet", "Equity", "Issued & Paid Up Capital"));
		rows.push(createRow("Retained Profits / Losses", (year, index) => {
			capital[index] = getFinancial(yearsData, year, "Summarised Balance Sheet", "Equity", "Issued & Paid Up Capital");
			retained[index] = totalAssets[index] - totalLiabilities[index] - capital[index];
			return formatMoney(retained[index]);
		}));
		rows.push(createSectionTotalsRow("Total Equity", (year, index) => {
			totalEquity[index] = totalAssets[index] - totalLiabilities[index];
			return formatMoney(totalEquity[index]);
		}));
	
		return <StandardTable columns={columns} rows={rows} />
	}

	function renderSummarisedProfitAndLossStatement() {
		let rows = [];
		let columns = [
			{ key: 'label', cell: 'Summarised Profit and Loss Statement', minWidth: 200 },
		].concat(getYearColumns());

		// Dynamic revenue		
		registerType("Revenue");
		rows = rows.concat(renderDynamicType("Summarised Profit and Loss Statement", "Revenue"));
		if (adminMode) rows.push({ addButton: true, onAdd: () => { addCustomFinancial("Summarised Profit and Loss Statement", "Revenue") } })
		rows.push(createTypeTotalsRow("Revenue", "Total Revenue"));		

		rows.push({ space: tableSpace });

		// Dynamic cost of goods sold
		registerType("Cost of Goods Sold");
		rows = rows.concat(renderDynamicType("Summarised Profit and Loss Statement", "Cost of Goods Sold"));
		if (adminMode) rows.push({ addButton: true, onAdd: () => { addCustomFinancial("Summarised Profit and Loss Statement", "Cost of Goods Sold") } })
		rows.push(createTypeTotalsRow("Cost of Goods Sold", "Total Cost of Goods Sold"));

		rows.push(createRow("Gross Margin", (year, index) => {
			let revenue = typeTotals["Revenue"][index];
			let costs = typeTotals["Cost of Goods Sold"][index];
			return revenue ? formatPercentage((1 - (costs / revenue)) * 100, 2) : "-";
		}));
		rows.push(createInputRow("Summarised Profit and Loss Statement", "Overhead", "Overhead"));
		rows.push({ space: tableSpace });

		rows.push(createSectionTotalsRow("EBITDA", (year, index) => {
			ebitda[index] = typeTotals["Revenue"][index] - typeTotals["Cost of Goods Sold"][index] - getFinancial(yearsData, year, "Summarised Profit and Loss Statement", "Overhead", "Overhead");
			return formatMoney(ebitda[index]);
		}));
		rows.push({ space: tableSpace });

		// Dynamic depriciation
		registerType("Depreciation");
		rows = rows.concat(renderDynamicType("Summarised Profit and Loss Statement", "Depreciation"));
		if (adminMode) rows.push({ addButton: true, onAdd: () => { addCustomFinancial("Summarised Profit and Loss Statement", "Depreciation") } })
		rows.push(createTypeTotalsRow("Depreciation", "Total Depreciation"));

		rows.push(createInputRow("Summarised Profit and Loss Statement", "Hire Purchase", "Hire Purchase Charges"));
		rows.push(createSectionTotalsRow("EBIT", (year, index) => {
			hirePurchase[index] =  typeTotals["Hire Purchase"][index];
			ebit[index] = ebitda[index] - (typeTotals["Depreciation"][index] + hirePurchase[index]);
			return formatMoney(ebit[index]);
		}));
		rows.push({ space: tableSpace });

		rows.push(createInputRow("Summarised Profit and Loss Statement", "Income Tax", "Income Tax"));
		rows.push(createSectionTotalsRow("Profit / Loss", (year, index) => {
			profit[index] = ebit[index] - typeTotals["Income Tax"][index];
			return formatMoney(profit[index]);
		}));

	
		return <StandardTable columns={columns} rows={rows} />
	}
	
	function renderDynamicType(sheetName, typeName) {
		let rows = [];
		let sheetMapping = customNameMapping[sheetName];
		if (sheetMapping) {
			let typeMapping = sheetMapping[typeName];
			if (typeMapping) for (let key in typeMapping) {
				let name = typeMapping[key];
				rows.push(createInputRow(sheetName, typeName, key,
					(editingCustomFinancial && (editingCustomFinancial.key === key)) ?
						<FlexBox>
							<NullableTextField compact autoFocus
								placeholder={editingCustomFinancial.key}
								value={editingCustomFinancial.name}
								onChange={(value) => {
									remapCustomFinancial(sheetName, typeName, editingCustomFinancial.key, value);
									setEditingCustomFinancial({ key: editingCustomFinancial.key, name: value, original: editingCustomFinancial.original });
								}}
								onBlur={() => {
									let matches = 0;
									for (let checkKey in typeMapping) {
										if (typeMapping[checkKey] === editingCustomFinancial.name) matches++;
									}
									if (matches > 1) {
										// Prevent duplicate name/keys
										remapCustomFinancial(sheetName, typeName, editingCustomFinancial.key, editingCustomFinancial.original);
									}
									setEditingCustomFinancial(null);
								}}
							/>
							<IconButton onMouseDown={() => {
								removeCustomFinancial(sheetName, typeName, editingCustomFinancial.key);
							}}>
								<HighlightOffOutlined />
							</IconButton>
						</FlexBox>
					:
						<FlexBox onClick={() => {
							if (adminMode) setEditingCustomFinancial({ key: key, name: name, original: name });
						}}>
							<span>{(name && name.length > 0) ? name : key}</span>
						</FlexBox>
				));
			}
		}
		return rows;
	}

	function renderFiles() {
		let rows = [];
		let columns = [
			{ key: 'label', cell: 'Files', minWidth: 200 },
		].concat(getYearColumns({ align: 'left' }));

		rows.push({
			key: "files",
			cells: [""].concat(years.map((year, index) => {
				return <>
					<FinancialFiles adminMode={adminMode} year={year} id_client={client.id_client} files={yearsFiles[year]} postFileUpload={postFileUpload} />
				</>;
			}))
		});

		
		return <StandardTable columns={columns} rows={rows} verticalAlign="top"/>
	}

	function renderComment() {
		return adminMode ?
			<Card sx={{marginBottom: 4}}>
				<CardContent>
					<TextField label="Comments" multiline rows={5} fullWidth value={comment || ""} onChange={(event) => {
						setComment(event.target.value);
						setChanged(true);
					}} />
				</CardContent>
			</Card>
		: comment ?
			<Card sx={{marginBottom: 4}}>
				<CardContent>
					<Typography>{comment}</Typography>
				</CardContent>
			</Card>
		: null;
	}

	function renderRatios() {
		let rows = [];
		let columns = [
			{ key: 'label', cell: 'Ratios', minWidth: 200 },
		].concat(getYearColumns());
	

		rows.push(createRow("(Current Assets − Current Liabilities) / Total Assets", (year, index) => {			
			if (currentLiabilities[index] === 0 || totalAssets[index] === 0) return "-";
			let ratio = ratio1[index] = (currentAssets[index] - currentLiabilities[index]) / totalAssets[index];
			return formatNumber(ratio, 3);
		}));

		rows.push(createRow("Retained Earnings / Total Assets", (year, index) => {			
			if (totalAssets[index] === 0) return "-";
			let ratio = ratio2[index] = retained[index] / totalAssets[index];
			return formatNumber(ratio, 3);
		}));

		rows.push(createRow("Earnings Before Interest and Taxes / Total Assets", (year, index) => {			
			if (totalAssets[index] === 0) return "-";
			let ratio = ratio3[index] = (hirePurchase[index] + ebit[index]) / totalAssets[index];
			return formatNumber(ratio, 3);
		}));

		rows.push(createRow("Book Value of Equity / Total Liabilities", (year, index) => {			
			if (totalLiabilities[index] === 0) return "-";
			let ratio = ratio4[index] = totalEquity[index] / totalLiabilities[index];
			return formatNumber(ratio, 3);
		}));

		
		return <StandardTable columns={columns} rows={rows}/>
	}

	function renderGraphs() {

		let RED = '#F90707';
		let YELLOW = '#EDC72D';
		let GREEN = '#89AC4A';

		const graphsData = [
			{
				title: "Altman Z-Score",
				calc: (year, index) => {
					if (anyBlank(ratio1[index], ratio2[index], ratio3[index], ratio4[index])) return undefined;
					return (6.56 * ratio1[index]) + (3.26 * ratio2[index]) + (6.72 + ratio3[index]) + (1.05 * ratio4[index]);	
				},
				min: 0,
				max: 5,
				step: 0.5,
				colors: [
					{ value: 0, color: RED },
					{ value: 2, color: YELLOW },
					{ value: 3, color: GREEN },
				],
			},
			{
				title: "Debt / Equity",
				calc: (year, index) => {
					if (anyBlank(totalLiabilities[index], totalEquity[index])) return undefined;
					if (totalEquity[index] === 0) return undefined;
					return (totalLiabilities[index] / totalEquity[index]);
				},
				min: 0,
				max: 5,
				step: 0.5,
				colors: [
					{ value: 0, color: GREEN },
					{ value: 1.5, color: YELLOW },
					{ value: 2.5, color: RED },
				],
			},
			{	
				title: "Current Ratio",
				calc: (year, index) => {
					if (anyBlank(currentAssets[index], currentLiabilities[index])) return undefined;
					if (currentLiabilities[index] === 0) return undefined;
					return (currentAssets[index] / currentLiabilities[index]);
				},
				min: 0,
				max: 4,
				step: 0.5,
				colors: [
					{ value: 0, color: RED },
					{ value: 1, color: YELLOW },
					{ value: 2, color: GREEN },
				],
			},
			{
				title: "Surety / NTA",
				calc: (year, index) => {
					if (anyBlank(surety, totalEquity[index])) return undefined;
					if (totalEquity[index] === 0) return undefined;
					return (surety / totalEquity[index]);
				},
				min: 0,
				max: 1,
				step: 0.1,
				colors: [
					{ value: 0, color: GREEN },
					{ value: 0.9, color: YELLOW },
				],
			},
			{
				title: "NTA / Book value of Assets",
				calc: (year, index) => {
					let ppe = getFinancial(yearsData, year, "Summarised Balance Sheet", "Non-Current Assets", "PPE");
					if (anyBlank(totalEquity[index], ppe)) return undefined;
					if (ppe === 0) return undefined;
					return (totalEquity[index] / ppe);
				},
				min: 0,
				max: 1,
				step: 0.1,
				colors: [
					{ value: 0, color: GREEN },
					{ value: 0.5, color: YELLOW },
					{ value: 0.7, color: RED },
				],
			},
			{
				title: "(NTA + Surety) / Book value of Assets",
				calc: (year, index) => {
					let ppe = getFinancial(yearsData, year, "Summarised Balance Sheet", "Non-Current Assets", "PPE");
					if (anyBlank(totalEquity[index], surety, ppe)) return undefined;
					if (ppe === 0) return undefined;
					return ((totalEquity[index] + surety) / ppe);
				},
				min: 0,
				max: 1,
				step: 0.1,
				colors: [
					{ value: 0, color: GREEN },
					{ value: 0.4, color: YELLOW },
					{ value: 0.6, color: RED },
				],
			},
			{
				title: "Net Debt / Equity",
				calc: (year, index) => {
					let cash = getFinancial(yearsData, year, "Summarised Balance Sheet", "Current Assets", "Cash");
					if (anyBlank(totalLiabilities[index], cash, totalEquity[index])) return undefined;
					if (totalEquity[index] === 0) return undefined;
					return ((totalLiabilities[index] - cash) / totalEquity[index]);
				},
				min: 0,
				max: 5,
				step: 0.5,
				colors: [
					{ value: 0, color: GREEN },
					{ value: 1.5, color: YELLOW },
					{ value: 2.5, color: RED },
				],
			},
			{	
				title: "Debt net of Working Capital / Equity",
				calc: (year, index) => {
					let cash = getFinancial(yearsData, year, "Summarised Balance Sheet", "Current Assets", "Cash");
					let receivables = getFinancial(yearsData, year, "Summarised Balance Sheet", "Current Assets", "Receivables");
					if (anyBlank(totalLiabilities[index], cash, receivables, totalEquity[index])) return undefined;
					if (totalEquity[index] === 0) return undefined;
					return ((totalLiabilities[index] - cash - receivables) / totalEquity[index]);
				},
				min: 0,
				max: 3,
				step: 0.5,
				colors: [
					{ value: 0, color: GREEN },
					{ value: 1.5, color: YELLOW },
					{ value: 2.5, color: RED },
				],
			},
			{
				title: "Net Debt to Operating EBITDA (x)",
				calc: (year, index) => {
					let cash = getFinancial(yearsData, year, "Summarised Balance Sheet", "Current Assets", "Cash");
					if (anyBlank(totalLiabilities[index], cash, ebitda[index])) return undefined;
					if (ebitda[index] === 0) return undefined;
					return ((totalLiabilities[index] - cash) / ebitda[index]);
				},
				min: 0,
				max: 5,
				step: 0.5,
				colors: [
					{ value: 0, color: GREEN },
					{ value: 3, color: YELLOW },
					{ value: 4, color: RED },
				],
			},
		];

		graphsData.forEach((graphData) => {
			graphData.values = { };
			years.forEach((year, index) => {			
				let key = "FY" + ("" + year).substring(2);
				graphData.values[key] = graphData.calc(year, index);
			});
		});

		return <Card sx={{marginBottom: 2}}>
			<CardContent>
				<Grid container columnSpacing={2} rowSpacing={2}>
					{ graphsData.map((graphData) => {
						return <Grid item xs={12} md={6} key={graphData.title}>
							<RatioGraph title={graphData.title} values={graphData.values} colors={graphData.colors} min={graphData.min} max={graphData.max} step={graphData.step} gradient={graphData.gradient}/>
						</Grid>
					})}
				</Grid>
			</CardContent>
		</Card>
	}


	function registerType(typeName) {
		let totals = typeTotals[typeName];
		if (!totals) totals = typeTotals[typeName] = new Array(lastYear - firstYear + 1).fill(0);
		return totals;
	}

	function createRow(label, yearCallback) {
		return {
			key: label,
			cells: [label].concat(years.map((year, index) => {
				return yearCallback(year, index);
			}))
		};
	}

	function createInputRow(sheetName, typeName, financialName, cell) {
		if (!cell) cell = financialName;
		return {
			key: sheetName + "." + typeName + "." + financialName,
			short: true,
			cells: [cell].concat(years.map((year, index) => {
				let value = getFinancial(yearsData, year, sheetName, typeName, financialName);

				let totals = registerType(typeName);
				totals[index] += (value || 0);

				return adminMode ?
					<DollarTextField compact clearable value={value} align="right" onChange={(value) => {
						updateFinancial(year, sheetName, typeName, financialName, value);
					}}/>
				: <Typography>{isBlank(value) ? "-" : formatMoney(value)}</Typography>;
			}))
		};
	}

	function createTypeTotalsRow(typeName, cell) {
		return {
			key: typeName,
			cells: [cell].concat(years.map((year, index) => {
				return formatMoney(typeTotals[typeName][index]);
			})),
			sx: { background: 'rgba(0, 0, 0, 0.03)' }
		};
	}

	function createSectionTotalsRow(label, yearCallback) {
		return {
			key: label,
			cells: [label].concat(years.map((year, index) => {
				return yearCallback(year, index);
			})),
			sx: { background: 'rgba(0, 0, 0, 0.08)' }
		};
	}


	return <>
		{ (staticData && client && yearsData) ? <>

			<form ref={formRef}>

				{ renderConstants() }
				{ renderSummarisedBalanceSheet() }
				{ renderSummarisedProfitAndLossStatement() }
				{ renderRatios() }
				{ renderGraphs() }
				{ renderFiles() }
				{ renderComment() }
					
			</form>

			<SaveDrawer open={changed} actions={<>
				<Button variant="contained" disabled={submitting} onClick={save}>Save</Button>
				<Button variant="outlined" disabled={submitting} onClick={revert}>Cancel</Button>
			</>}/>

		</> : <CentreLoader /> }
	</>
};

function getFinancial(yearsData, year, sheetName, typeName, financialName) {
	let yearData = yearsData[year];
	if (yearData) {
		let sheetData = yearData.sheets[sheetName];
		if (sheetData) {
			let typeData = sheetData.types[typeName];
			if (typeData) {
				let financialData = typeData.financials[financialName];
				if (financialData) return financialData.amount;
			}
		}
	}
	return null;
}

function setFinancial(yearsData, year, sheetName, typeName, financialName, amount) {
	let financialData = getOrCreateFinancialData(yearsData, year, sheetName, typeName, financialName);
	financialData.amount = amount;
}

function getTypeData(yearsData, year, sheetName, typeName) {
	let yearData = yearsData[year];
	if (!yearData) return null;
	let sheetData = yearData.sheets[sheetName];
	if (!sheetData) return null;
	let typeData = sheetData.types[typeName];
	if (!typeData) return null;
	return typeData;
}

function getOrCreateTypeData(yearsData, year, sheetName, typeName) {
	let yearData = yearsData[year];
	if (!yearData) yearData = yearsData[year] = {
		year: year,
		sheets: { },
		graphs: { },
	};

	let sheetData = yearData.sheets[sheetName];
	if (!sheetData) sheetData = yearData.sheets[sheetName] = {
		name: sheetName,
		types: { },
	}

	let typeData = sheetData.types[typeName];
	if (!typeData) typeData = sheetData.types[typeName] = {
		type: typeName,
		financials: { },
	}

	return typeData;
}

function getOrCreateFinancialData(yearsData, year, sheetName, typeName, financialName, amount) {
	let typeData = getOrCreateTypeData(yearsData, year, sheetName, typeName);

	let financialData = typeData.financials[financialName];
	if (!financialData) financialData = typeData.financials[financialName] = {
		name: financialName,
		type: typeName,
		amount: amount,
	}

	return financialData;
}


function processIncomingFinancialData(originalFinancialData, yearsData, firstYear, lastYear, retrievedYears, customNameMapping, comment, setYearsFiles, setFinancialConstants, setYearsData, setCustomNameMapping, setRetrievedYears, setComment, setRevertTarget) {
	
	if (originalFinancialData && originalFinancialData.financial) {

		// Always update files
		let newYearsFiles = { };			
		for (let year in originalFinancialData.financial.years) {
			if (originalFinancialData.financial.years[year]) {
				let id_financial_year = originalFinancialData.financial.years[year].id_financial_year;

				year = parseInt(year);
				let yearFiles = newYearsFiles[year] = [];

				originalFinancialData.financial.files.forEach((file) => {
					if (file.id_financial_year === id_financial_year) {
						yearFiles.push(file);
					}
				})
			}
		}
		setYearsFiles(newYearsFiles);


		// Only update other data if necessary
		let yearsToProcess = [];			
		for (let year in originalFinancialData.financial.years) {
			let financialYear = originalFinancialData.financial.years[year];
			if (!financialYear || financialYear.sheets) {
				if (!yearsData || !yearsData[year]) {
					// Only create the fresh years this call (including null returns)
					yearsToProcess.push(parseInt(year));
				}
			}
		}

		if (yearsToProcess.length === 0) return;

		let newYearsData = { ...yearsData };
		for (let year in originalFinancialData.financial.years) {
			year = parseInt(year);

			if (yearsToProcess.indexOf(year) < 0) continue;

			let financialYear = originalFinancialData.financial.years[year];
			if (financialYear && financialYear.sheets) {					
				let yearData = newYearsData[year] = {
					year: year,
					sheets: { },
					graphs: { },
				};
				financialYear.sheets.forEach((sheet) => {
					let sheetData = yearData.sheets[sheet.name] = {
						name: sheet.name,
						types: { },
					}
					sheet.financials.forEach((financial) => {
						let type = sheetData.types[financial.type];
						if (!type) type = sheetData.types[financial.type] = {
							type: financial.type,
							financials: { },
						}
						type.financials[financial.name] = {
							name: financial.name,
							type: financial.type,
							amount: stripNonNumeric(financial.amount),
						}
					});
				});
			}
		}

		// Dynamic categories - mine those which have previously been submitted for any retrieved year
		let newMapping = customNameMapping ? cloneDeep(customNameMapping) : {
			"Summarised Profit and Loss Statement": {
				"Revenue": { },
				"Cost of Goods Sold": { },
				"Depreciation": { },
			},
		}
		for (let sheetName in newMapping) {
			let sheetMapping = newMapping[sheetName];
			for (let typeName in sheetMapping) {
				let typeMapping = sheetMapping[typeName];

				for (let year = firstYear; year <= lastYear; year++) {
					let typeData = getOrCreateTypeData(newYearsData, year, sheetName, typeName);
					if (typeData) for (let name in typeData.financials) {
						if (typeMapping[name] === undefined) typeMapping[name] = name;
					}
				}
			}
		}

		let constants = { };
		for (let key in originalFinancialData.financial.constants) {
			let constant = originalFinancialData.financial.constants[key];
			switch(constant.key) {
				//case "Surety": constants.surety = stripNonNumeric(constant.value); break;
				default: break;
			}
		}
		//if (constants.surety === undefined) constants.surety = 4000000;
		
		const newComment = comment || originalFinancialData.financial.comment;

		setFinancialConstants(constants);
		setYearsData(newYearsData);
		setCustomNameMapping(newMapping);
		setRetrievedYears(retrievedYears.concat(yearsToProcess));
		setComment(newComment);

		if (setRevertTarget) setRevertTarget({
			yearsData: cloneDeep(newYearsData),
			mapping: cloneDeep(newMapping),
			constants: constants,
			comment: newComment,
		})
	}
}


	