import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

export default function ExpandableCard({ variant, headerContent, startOpen, children, sx, ...props }) {

	const [expanded, setExpanded] = useState((startOpen !== undefined) ? startOpen : true);

	return <Box {...props} sx={{ marginBottom: 2, ...sx }}>
		<Accordion expanded={expanded} onChange={(event, isExpanded) => {setExpanded(isExpanded)}}>
			<AccordionSummary variant={variant} expandIcon={<ExpandMore />}>
				{headerContent}
			</AccordionSummary>
			<Divider />
			<AccordionDetails variant={variant}>
				{children}
			</AccordionDetails>
		</Accordion>
	</Box>

}