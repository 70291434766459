import { Button, CircularProgress } from "@mui/material";
import FlexBox from "components/layout/FlexBox";
import { useState } from "react";

export default function FileUpload({ inputId, data, postFileUpload, ...props }) {

	const [submittingFile, setSubmittingFile] = useState(false);


	function uploadFile(file) {
		setSubmittingFile(true);

		let formData = new FormData();
		formData.append('file', file);
		for (let key in data) formData.append(key, data[key]);

		postFileUpload(formData).then((responseData) => {
			setSubmittingFile(false);
		}).catch((error) => {console.log("Post file error", error, data)})
	}

	inputId = inputId || "file-upload";

	return <>
		<FlexBox center {...props}>
			<label htmlFor={inputId}>
				<input
					id={inputId}
					name={inputId}
					style={{ display: 'none' }}
					type="file"
					accept=".pdf"
					onClick={(event) => {
						event.target.value = '';
					}}
					onChange={(event) => {
						if (event.target.files.length > 0) {
							let file = event.target.files[0];
							uploadFile(file);
						}
					}}
				/>
				<Button component="span" variant="outlined" disabled={submittingFile}>Upload file</Button>
			</label>

			{ submittingFile && <CircularProgress size={24} sx={{marginY: -1, marginLeft: 2}} /> }
		</FlexBox>

	</>

}