import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getBondStatus, missingHistoricChargeText } from "BondFunctions";
import BondStatus from "components/common/BondStatus";
import CardTitle from "components/common/CardTitle";
import FlexBox from "components/layout/FlexBox";
import { Link } from "react-router-dom";
import { formatMoney, isBlank, searchByField } from "Utils";


export default function RelatedBonds({ undercoverMode, staticData, bond_related }) {

	if (bond_related.length <= 0) return null;

	return <Card>		
		<CardContent>
			
			<CardTitle title="Related bonds"/>
		
			<Stack direction="column">
				{ bond_related && bond_related.map((bond, index) => {
					var bondType = staticData && searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type);
					var bondInfo = "";
					if (bondType) {
						let bondTypeLabel = bondType.name.toLowerCase().replace("bond", "").trim();
						if (bondType.fronted) bondTypeLabel = bondTypeLabel.replace("fronted", "").trim() + " fronted"
						if (bondInfo.length > 0) {
							bondInfo = bondInfo + ", " + bondTypeLabel;
						} else {
							bondInfo = bondTypeLabel.charAt(0).toUpperCase() + bondTypeLabel.substr(1);
						}
					}
					return <Box key={index} mb={1}>
						<FlexBox center>
							<Typography variant="h6" >{bondInfo || ''}</Typography>
							<Typography variant="secondary" ml="auto">{!isBlank(bond.face_value) ? formatMoney(bond.face_value, 2) : missingHistoricChargeText}</Typography>
						</FlexBox>
						<FlexBox center mt={0.5}>
							<BondStatus sx={{marginRight: 0.8}} bondStatus={getBondStatus(bond.id_bond_status, staticData)} /> 
							<Typography sx={{display: 'inline'}}><Link to={'/bond/' + bond.id_bond + '/detail'}>view bond</Link></Typography>
						</FlexBox>
					</Box>
				})}
				
			</Stack>

		</CardContent>
	</Card>

}
