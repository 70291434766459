import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, TextField, Divider, MenuItem, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import { checkFormValidity, formatMoney, formatPercentage, searchByField, } from "Utils";
import StaticDataContext from "contexts/StaticDataContext";
import API from "API";
import { useGet, usePost, usePut } from "hooks/useAPI";
import SectionTitle from "components/common/SectionTitle";
import SectionCard from "components/common/SectionCard";
import PaginatedTable from "components/modules/tables/PaginatedTable";
import moment from "moment";
import CentreLoader from "components/common/CentreLoader";
import AddButton from "components/common/AddButton";
import { useQuery } from "hooks/useQuery";
import cloneDeep from 'lodash/cloneDeep';
import SaveDrawer from "components/modules/SaveDrawer";
import NullableTextField from "components/common/NullableTextField";
import UserContext from "contexts/UserContext";
import { useDetectClient } from "hooks/useDetectClient";
import { FilterableBondsTable } from "components/modules/tables/BondsTable";
import DatePicker from "components/common/DatePicker";
import { DollarTextField, PercentageTextField } from "components/common/FormattedTextField";
import { EXTENSION_TYPE_MAINTENANCE, EXTENSION_TYPE_PERFORMANCE, prepareProject } from "ProjectFunctions";
import { HighlightOffOutlined } from "@mui/icons-material";
import DisplayField from "components/common/DisplayField";
import CenteredModal from "components/layout/CentredModal";


export function AdminProjectPage() {

	return <ProjectPage adminMode={true} />
}

export function ClientProjectPage() {

	return <ProjectPage adminMode={false} />
}

function ProjectPage({ adminMode }) {	

	const navigate = useNavigate();

	const query = useQuery();
	const id_project = (query.id === "add") ? null : parseInt(query.id);
	const creatingNew = (query.id === "add");
	const wrapBondId = query.b;

	const { user } = useContext(UserContext);

	const staticData = useContext(StaticDataContext);
	
	const [originalProjectData] = useGet(id_project ? API.getProject(user, id_project) : null);
	const { project_bonds: originalBonds, project_bond_historys: originalBondHistorys, project: originalProject, project_extensions: originalExtensions, project_margins: originalMargins } = originalProjectData || {};

	const [bondData] = useGet(wrapBondId ? API.getBond(user, wrapBondId) : null);
	const { bond: wrappedBond, bond_related } = (bondData || {});

	

	const isBonded = (originalBonds && originalBonds.length > 0) || (originalBondHistorys && originalBondHistorys.length > 0) || !!wrappedBond;

	const [clientsData] = useGet(API.getClients(user));
	const clients = clientsData && clientsData.clients;

	const [project, setProject] = useState(originalProject);
	const [extensions, setExtensions] = useState(originalExtensions);
	const [margins, setMargins] = useState(originalMargins);
	const [bonds, setBonds] = useState(originalBonds);

	const [revertTarget, setRevertTarget] = useState(originalProjectData);

	const [changed, setChanged] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [postProject] = usePost(API.postProject(user));
	const [putProject] = usePut(id_project ? API.putProject(user, id_project) : null);


	const formRef = useRef();

	const [showAddBondModal, setShowAddBondModal] = useState(false);


	useDetectClient(project && project.id_client);


	const { revisedMargins, typedExtensions, currentPerformanceDate, currentMaintenanceDate, originalPerformanceDate, originalMaintenanceDate, originalProjectValue, originalProjectMargin, originalProjectMarginClientInitiated } = useMemo(() => {
		return prepareProject(project, extensions, margins, bonds, staticData) || { };
	}, [project, extensions, margins, bonds, staticData]);
	
	function createDefaultProject() {
		return {
			name: null,
			date_start: new Date(),
			date_maintenance: new Date(),
			date_performance: new Date(),
			comment: null,
		}		
	}
	
	useEffect(() => {
		if (creatingNew && staticData) {
			let bonds = (wrappedBond && bond_related) ? [].concat(wrappedBond, bond_related) : [];
			let margins = [];
			let d = createDefaultProject();

			if (wrappedBond) {
				d.name = wrappedBond.project;
				d.principal = wrappedBond.principal;
				d.id_client = wrappedBond.id_client;
				let issuer = searchByField(staticData.issuers, 'name', wrappedBond.issuer_name);
				if (issuer) d.id_issuer = issuer.id_issuer;

				let value = 0;
				bonds.forEach((bond) => {
					value += bond.face_value;
				});
				margins.push({ 
					project_value: value,
					project_margin: null,
					date: wrappedBond.date_start,
					revised: false,
					client_initiated: false,
				})
			}

			setProject(d);
			setBonds(bonds);
			setExtensions([]);
			setMargins(margins);
			setRevertTarget({
				project: d,
				bonds: bonds,
				extensions: [],
				margins: margins,
			});
		}
	}, [creatingNew, setProject, wrappedBond, bond_related, staticData, setBonds])

	useEffect(() => {
		if (!creatingNew) {
			let orderedExtensions = originalExtensions ? [...originalExtensions] : [];
			orderedExtensions.sort((a, b) => a.date - b.date);

			let ensuredMargins = originalMargins ? [...originalMargins] : [];
			if (ensuredMargins.length === 0) ensuredMargins.push({
				date: new Date(),
				project_value: 0,
				project_margin: 0,
				revised: false,
				client_initiated: false,
			});

			setProject(originalProject);
			setBonds(originalBonds);
			setExtensions(orderedExtensions);
			setMargins(ensuredMargins);
			setRevertTarget(cloneDeep({
				project: originalProject,
				bonds: originalBonds,
				extensions: orderedExtensions,
				margins: ensuredMargins,
			}));
		}
	}, [creatingNew, originalProject, originalBonds, originalExtensions, originalMargins, setProject, setBonds, setExtensions, setMargins])
	

	function revert() {
		let clone = cloneDeep(revertTarget);
		setProject(clone.project);
		setBonds(clone.bonds);
		setExtensions(clone.extensions);
		setMargins(clone.margins);
		setChanged(false);
	}

	const updateProject = useCallback((newValues) => {
		setProject({
			...project,
			...newValues,
		});
		setChanged(true);
	}, [project]);

	const updateMargin = useCallback((index, newValues) => {
		let list = [...margins] || [];
		let prev = list[index] || { revised: index !== 0, project_value: 0, project_margin: null, date: new Date(), client_initiated: false };
		list[index] = { ...prev, ...newValues };
		setMargins(list);
		setChanged(true);
	}, [margins]);
	const deleteMargin = useCallback((index) => {
		let list = [...margins];
		list.splice(index, 1);
		setMargins(list);
		setChanged(true);
	}, [margins]);

	const updateExtension = useCallback((index, newValues) => {
		let list = [...extensions] || [];
		let prev = list[index] || { date_performance: null, date_maintenance: null };
		list[index] = { ...prev, ...newValues };
		setExtensions(list);
		setChanged(true);
	}, [extensions]);
	const deleteExtension = useCallback((index) => {
		let list = [...extensions];
		list.splice(index, 1);
		setExtensions(list);
		setChanged(true);
	}, [extensions]);


	function save(event) {
		if (checkFormValidity(formRef.current)) {
			setSubmitting(true);
			let r = cloneDeep({
				project: project,
				project_bonds: bonds,
				project_extensions: extensions,
				project_margins: margins,
			});

			// Process removals
			let dataToSubmit = cloneDeep(r);
			if (!creatingNew) dataToSubmit.project.active = true;


			if (creatingNew) {
				postProject(dataToSubmit).then((responseData) => {
					setSubmitting(false);
					setRevertTarget(r);
					setChanged(false);
					navigate("/project/" + responseData.project.id_project);
				}).catch((error) => {console.log("Post project error", error)})
			} else {
				putProject(dataToSubmit).then((responseData) => {
					setSubmitting(false);
					setRevertTarget(r);
					setChanged(false);
				}).catch((error) => {console.log("Put project error", error)})
			}			
		}
	}

	return <>
		{ (staticData && project && bonds && (!adminMode || clients)) ? <>
			<form ref={formRef}>

				<SectionTitle title="Project details" />
				<SectionCard>
					<Grid container columnSpacing={2} rowSpacing={2}  alignItems="center">
						<Grid item xs={12} md={12}>
							{ adminMode ? <NullableTextField label="Name" fullWidth type="text" required value={project.name} onChange={(value) => { updateProject({ name: value } )}}/>
							: <DisplayField label="Name" fullWidth value={project.name} />}
						</Grid>
						<Grid item xs={12} md={12}>
							{ adminMode ? <NullableTextField label="Principal" type="text" fullWidth required value={project.principal} onChange={(value) => { updateProject({ principal: value }); }} />
							: <DisplayField label="Principal" fullWidth value={project.principal} />}
						</Grid>
						<Grid item xs={12} md={6}>
							{ adminMode ? 
								<TextField label="Project Type" required fullWidth value={(project.id_project_type) || ''} select onChange={(event) => { updateProject({ id_project_type: event.target.value }); }}>
									{ staticData.project_types.map((projectType) => {
										return <MenuItem key={projectType.id_project_type} value={projectType.id_project_type}>{projectType.name}</MenuItem>
									})}				
								</TextField>						
							: <DisplayField label="Project Type" fullWidth value={(searchByField(staticData.project_types, 'id_project_type', project.id_project_type) || { }).name || ""} />}
						</Grid>
						<Grid item xs={12} md={6}>
							{ adminMode ? 
								<TextField label="Principal Type" required fullWidth value={(project.id_principal_type) || ''} select onChange={(event) => { updateProject({ id_principal_type: event.target.value }); }}>
									{ staticData.principal_types.map((principalType) => {
										return <MenuItem key={principalType.id_principal_type} value={principalType.id_principal_type}>{principalType.name}</MenuItem>
									})}				
								</TextField>						
							: <DisplayField label="Principal Type" fullWidth value={(searchByField(staticData.principal_types, 'id_principal_type', project.id_principal_type) || { }).name || ""} />}
						</Grid>
						{ adminMode ?
							<Grid item xs={12} md={6}>
								<TextField label="Client" fullWidth required value={project.id_client || ''} select onChange={(event) => { updateProject({ id_client: event.target.value }); }}>
									{ clients.map((client) => {
										return <MenuItem key={client.id_client} value={client.id_client}>{client.name}</MenuItem>
									})}									
								</TextField>
							</Grid>
						: null }
						<Grid item xs={12} md={6}>
							{ adminMode ? 
								<TextField label="Issuer" required fullWidth value={(project.id_issuer) || ''} select onChange={(event) => { updateProject({ id_issuer: event.target.value }); }}>
									{ staticData.issuers.map((issuer) => {
										return <MenuItem key={issuer.id_issuer} value={issuer.id_issuer}>{issuer.name}</MenuItem>
									})}				
								</TextField>						
							: <DisplayField label="Issuer" fullWidth value={(searchByField(staticData.issuers, 'id_issuer', project.id_issuer) || { }).name || ""} />}
						</Grid>

						<Grid item xs={12} md={12}>
							<Divider />
						</Grid>

						<Grid item xs={12} md={6}>
							{ adminMode ?
								<DatePicker
									value={project.date_start}
									label={"Start Date"}
									renderInput={(params) => <TextField {...params} required fullWidth={true} />}
									onChange={(value) => { updateProject({ date_start: value }); }}
								/>
							:
								<DatePicker
									value={project.date_start}
									label={"Start Date"}
									renderInput={(params) => <DisplayField {...params} fullWidth={true} />}
									onChange={() => {}}
									disabled
								/>
							}
						</Grid>									
						<Grid item xs={12} md={6}>
						</Grid>
						<Grid item xs={12} md={6}>
							{ adminMode ?
								<DatePicker
									value={originalPerformanceDate}
									label={"Original PC Date"}
									renderInput={(params) => <TextField {...params} required={!isBonded} fullWidth={true} />}
									disabled={isBonded}
									onChange={isBonded ? null : (value) => { updateProject({ date_performance: value }); }}
								/>
							:
								<DatePicker
									value={originalPerformanceDate}
									label={"Original PC Date"}
									renderInput={(params) => <DisplayField {...params} fullWidth={true} />}
									onChange={() => {}}
									disabled
								/>
							}
						</Grid>									
						<Grid item xs={12} md={6}>
							{ adminMode ?
								<DatePicker
									value={originalMaintenanceDate}
									label={"Original Maintenance Date"}
									renderInput={(params) => <TextField {...params} required={!isBonded} fullWidth={true} />}
									disabled={isBonded}
									onChange={isBonded ? null : (value) => { updateProject({ date_maintenance: value }); }}
								/>
							:
								<DatePicker
									value={originalMaintenanceDate}
									label={"Original Maintenance Date"}
									renderInput={(params) => <DisplayField {...params} fullWidth={true} />}
									onChange={() => {}}
									disabled
								/>
							}
						</Grid>									
						<Grid item xs={12} md={6}>
							{ adminMode ?
								<DatePicker
									value={currentPerformanceDate}
									label={"Current PC Date"}
									renderInput={(params) => <TextField {...params} fullWidth={true} />}
									disabled={true}
								/>
							:
								<DatePicker
									value={currentPerformanceDate}
									label={"Current PC Date"}
									renderInput={(params) => <DisplayField {...params} fullWidth={true} />}
									onChange={() => {}}
									disabled
								/>
							}
						</Grid>									
						<Grid item xs={12} md={6}>
							{ adminMode ?
								<DatePicker
									value={currentMaintenanceDate}
									label={"Current Maintenance Date"}
									renderInput={(params) => <TextField {...params} fullWidth={true} />}
									disabled={true}
								/>
							:
								<DatePicker
									value={currentMaintenanceDate}
									label={"Current Maintenance Date"}
									renderInput={(params) => <DisplayField {...params} fullWidth={true} />}
									onChange={() => {}}
									disabled
								/>
							}
						</Grid>									


						<Grid item xs={12} md={12}>
							<Divider />
						</Grid>

						<Grid item xs={12} md={6}>							
							{ adminMode ? <DollarTextField label="Original Project Value" fullWidth required value={ originalProjectValue } onChange={(numericValue) => { updateMargin(0, { project_value: numericValue }); }} />
							: <DisplayField label="Original Project Value" fullWidth value={formatMoney(originalProjectValue)} />}
						</Grid>						
						<Grid item xs={12} md={adminMode ? 3 : 6}>
							{ adminMode ? <PercentageTextField label="Project Margin" fullWidth value={ originalProjectMargin } onChange={(numericValue) => { updateMargin(0, { project_margin: numericValue }); }} />
							: <DisplayField label="Project Margin" fullWidth value={formatPercentage(originalProjectMargin)} />}
						</Grid>
						{ adminMode ?
							<Grid item xs={12} md={3}>
								<FormControlLabel label="Client Initiated" control={
									<Checkbox checked={originalProjectMarginClientInitiated} onChange={(event) => { updateMargin(0, { client_initiated: event.target.checked }) }} />
								} />
							</Grid>
						: null}

						<Grid item xs={12} md={6}>
							{ adminMode ? 
								<TextField label="Price Structure" required fullWidth value={(project.id_price_structure) || ''} select onChange={(event) => { updateProject({ id_price_structure: event.target.value }); }}>
									{ staticData.price_structures.map((priceStructure) => {
										return <MenuItem key={priceStructure.id_price_structure} value={priceStructure.id_price_structure}>{priceStructure.name}</MenuItem>
									})}				
								</TextField>						
							: <DisplayField label="Price Structure" fullWidth value={(searchByField(staticData.price_structures, 'id_price_structure', project.id_price_structure) || { }).name || ""} />}
						</Grid>


					</Grid>
				</SectionCard>
							
		
				{ !creatingNew && <>
		
					<SectionTitle title="Revised Project Margins" actions={
						adminMode ? 
							<AddButton onClick={() => {
								updateMargin(margins.length, { });
							}}>Add revised margin</AddButton>
						: null
					}/>
					<PaginatedTable
						columns={[
							{ key: 'variation', cell: "Variation Project Value" },
							{ key: 'revised_margin', cell: "Revised Project Margin" },
							{ key: 'date', cell: "Date"/*, align: 'right'*/, width: 250 },
						].concat(adminMode ? [
							{ key: 'client_initiated', cell: "Client Initiated", align: 'center', width: 40 },
							{ key: 'remove', cell: "", width: 40 },
						] : [])}
						rows={revisedMargins.map((margin) => {
							return {
								key: margin.id_project_margin + "-" + margin.index,
								cells: [
									adminMode ? <DollarTextField fullWidth value={parseFloat(margin.project_value)} onChange={(value) => {updateMargin(margin.index, { project_value: value })}} />
									: <DisplayField fullWidth value={formatMoney(margin.project_value)} />,

									adminMode ? <PercentageTextField fullWidth value={parseFloat(margin.project_margin)} onChange={(value) => {updateMargin(margin.index, { project_margin: value })}}/>
									: <DisplayField fullWidth value={formatPercentage(margin.project_margin)} />,

									adminMode ? <DatePicker
										value={margin.date}
										renderInput={(params) => <TextField {...params} required fullWidth={true} />}
										onChange={(value) => {updateMargin(margin.index, { date: value })}}
									/>
									: <DatePicker
										value={margin.date}
										renderInput={(params) => <DisplayField {...params} fullWidth={true} />}
										onChange={() => {}}
										disabled
									/>
	
								].concat(adminMode ? [
									<FormControlLabel label="" control={
										<Checkbox checked={!!margin.client_initiated} onChange={(event) => { updateMargin(margin.index, { client_initiated: event.target.checked }) }} />
									} />,
	
									<IconButton sx={{my: -2}} onClick={() => {
										deleteMargin(margin.index);
									}}>
										<HighlightOffOutlined />
									</IconButton>
								] : [])					
							}
						})}
					/>

					<SectionTitle title="Extensions" actions={isBonded ? null : 
						<AddButton onClick={() => {
							updateExtension(extensions.length, { date_performance: new Date(currentPerformanceDate), date_maintenance: null });
						}}>Add extension</AddButton>
					}/>
					{ (isBonded || !adminMode) ? 
						<PaginatedTable
							columns={[
								{ key: 'type', cell: "Type" },
								{ key: 'date', cell: "Date", align: 'right', width: 250 },
							]}
							rows={typedExtensions.map((extension, index) => {
								return {
									key: extension.type + (new Date(extension.date)).getTime() + index,
									cells: [
										extension.type,
										moment(extension.date).format('DD/MM/YYYY'),
									],
								}
							})}
						/>
					:
						<PaginatedTable
							columns={[
								{ key: 'type', cell: "Type" },
								{ key: 'date', cell: "Date", align: 'right', width: 250 },
								{ key: 'remove', cell: "", width: 40 },
							]}
							rows={typedExtensions.map((extension, index) => {
								return {
									key: extension.type + (new Date(extension.date)).getTime() + index,
									cells: [
										<TextField required fullWidth value={(extension.type) || ''} select onChange={(event) => {
											updateExtension(extension.index, {
												date_performance: event.target.value === EXTENSION_TYPE_PERFORMANCE ? extension.date : null,
												date_maintenance: event.target.value === EXTENSION_TYPE_MAINTENANCE ? extension.date : null,
											})
										}}>
											{ [EXTENSION_TYPE_PERFORMANCE, EXTENSION_TYPE_MAINTENANCE].map((extensionType) => {
												return <MenuItem key={extensionType} value={extensionType}>{extensionType}</MenuItem>
											})}				
										</TextField>,
										<DatePicker
											value={extension.date}
											renderInput={(params) => <TextField {...params} required fullWidth={true} />}
											onChange={(value) => {
												updateExtension(extension.index, {
													date_performance: extension.type === EXTENSION_TYPE_PERFORMANCE ? value : null,
													date_maintenance: extension.type === EXTENSION_TYPE_MAINTENANCE ? value : null,
												})
											}}
										/>,	
										<IconButton sx={{my: -2}} onClick={() => {
											deleteExtension(extension.index);
										}}>
											<HighlightOffOutlined />
										</IconButton>										
									],
								}
							})}
						/>
					}

				</>}

				{ (bonds && bonds.length > 0) && 
					<FilterableBondsTable title="Bonds" bonds={bonds} actions={
						adminMode &&
							<AddButton onClick={() => {
								setShowAddBondModal(true);
							}}>Add Bonds</AddButton>
					} onRemove={(bond) => {
						let updatedBonds = [...bonds];
						let index = updatedBonds.indexOf(bond);
						if (index >= 0) {
							updatedBonds.splice(index, 1);
							setBonds(updatedBonds);
							setChanged(true);
						}
					}} />
				}

				<SectionCard>
					<Grid container columnSpacing={2} rowSpacing={2}>
						<Grid item xs={12} md={12}>
							{ adminMode ? <NullableTextField label="Comments" fullWidth type="text" multiline rows={5} value={project.comment || ""} onChange={(value) => { updateProject({ comment: value }) }} />
							: <DisplayField label="Comments" fullWidth multiline rows={5} value={project.comment || ""} /> }
						</Grid>
					</Grid>
				</SectionCard>
					

			</form>


			{ adminMode ? 
				<SaveDrawer open={changed} actions={<>
					<Button variant="contained" disabled={submitting} onClick={save}>Save</Button>
					<Button variant="outlined" disabled={submitting} onClick={revert}>Cancel</Button>
				</>}/>
			: null }

			{ showAddBondModal && <AddBondModal user={user}
				onAdded={(bondsToAdd) => {
					setBonds(bonds.concat(bondsToAdd));
					setChanged(true);
				}}
				onClose={() => setShowAddBondModal(false)}
			/> }

		</> : <CentreLoader /> }
	</>

};



function AddBondModal({ user, onAdded, onClose }) {

	const [orphansData] = useGet(API.getOrphanBonds(user));
	const orphans = orphansData && orphansData.bonds;

	const [bondsToAdd, setBondsToAdd] = useState([]);
	function updateBondToggle(bond, toggled) {
		let updatedBondsToAdd = [...bondsToAdd];
		let index = updatedBondsToAdd.indexOf(bond);
		if (toggled) {
			if (index === -1) updatedBondsToAdd.push(bond);
		} else {
			if (index >= 0) updatedBondsToAdd.splice(index, 1);
		}
		setBondsToAdd(updatedBondsToAdd);
	}

	const toggledBonds = useMemo(() => {
		let toggledBonds = { };
		bondsToAdd.forEach((bond) => {
			toggledBonds[bond.id_bond] = true;
		});
		return toggledBonds;
	}, [bondsToAdd]);


	return <CenteredModal width={724} headerContent={
		<SectionTitle title="Add Bonds" sx={{marginBottom: 0}} actions={<>
			<Button variant="contained" disabled={bondsToAdd.length === 0} onClick={() => {
				if (onAdded) onAdded(bondsToAdd);
				onClose();
			}}>Add</Button>
			<Button variant="outlined" onClick={onClose}>Cancel</Button>
		</>} />
	}>
		
		{ orphans ? <FilterableBondsTable
			title="Bonds Without Projects"
			sx={{marginBottom: 0}}
			bonds={orphans}
			toggledBonds={toggledBonds}
			onToggleBond={({ bond, toggled }) => {
				updateBondToggle(bond, toggled)
			}}
		/> : null }
		
	</CenteredModal>
}

