import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import StaticDataContext from "contexts/StaticDataContext";
import PageTitle from "components/common/PageTitle";
import CentreLoader from "components/common/CentreLoader";
import SortableTable from "components/modules/tables/SortableTable";

export default function AdminIssuersPage() {

	const navigate = useNavigate();

	const staticData = useContext(StaticDataContext);
	const issuers = staticData && staticData.issuers;

	if (!staticData) return <CentreLoader />;

 	return <>

		<PageTitle title="Issuers"/>

		<SortableTable
			columns={[
				{ key: 'name', cell: "Name" }, 
			]}
			rows={ issuers && issuers.map((issuer) => {
				return {
					key: issuer.id_issuer,
					cells: [
						issuer.name || "",
					],
					onClick: () => { navigate("/issuer/" + issuer.id_issuer) }
				}
			})}

			sx={{marginBottom: 4}}
		/>

	</>

};

