import './assets/scss/style.scss';
import React, {
	useContext, useEffect,
} from "react";
import Amplify from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import UserContext from "contexts/UserContext";
import { AWS } from "Environment";
import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import inscapTheme from 'Theme';
import { StaticDataProvider } from 'contexts/StaticDataContext';
import AppRoutes from 'AppRoutes';
import UserDataLoader from 'components/modules/UserDataLoader';
import LoginPage from 'components/pages/LoginPage';
import { LayoutProvider } from 'contexts/LayoutContext';
import { CacheIterationProvider } from 'contexts/CacheIterationContext';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


Amplify.configure({
	aws_cognito_region: AWS.REGION,
	aws_user_pools_id: AWS.USER_POOL_ID,
	aws_user_pools_web_client_id: AWS.APP_CLIENT_ID,
});



function App() {

	const { user } = useContext(UserContext);

	useEffect(() => {
		document.title = "InsCap";
	}, []);

	return (
		<CacheIterationProvider>
		<ThemeProvider theme={inscapTheme}>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
		<StaticDataProvider>
		<LayoutProvider>
			<Router>
				{ (user && user.inscap && user.cognito) ?
					/*(user.isDataroom && !user.nonreliance) ?
						<DataroomNonreliancePage />
					:*/
						<AppRoutes user={user} />				
				: (user && user.cognito) ?
					<UserDataLoader />
				: (user) && 
					<LoginPage />
				}
			</Router>
		</LayoutProvider>
		</StaticDataProvider>
		</LocalizationProvider>
		</ThemeProvider>
		</CacheIterationProvider>
	);
};

export default App;
