import { HighlightOffOutlined } from "@mui/icons-material";
import { Typography, Link, IconButton } from "@mui/material";
import API from "API";
import FlexBox from "components/layout/FlexBox";
import UserContext from "contexts/UserContext";
import { useDelete, useFileGet } from "hooks/useAPI";
import useHover from "hooks/useHover";
import moment from "moment";
import { useContext, useState } from "react";
import { downloadPdfFromArrayBuffer } from "Utils";

export default function FinancialFileLink({ id_client, file, adminMode }) {

	const { user } = useContext(UserContext);

	const [deleting, setDeleting] = useState(false);
	const [hovering, bindHover] = useHover();

	const [downloadFinancialFile] = useFileGet(
			file ? API.downloadFinancialFile(user, id_client, file.id_file) : null
		);
	const [deleteFinancialFile] = useDelete(
		adminMode ?
			(file ? API.deleteFinancialFile(user) : null)
		:
			null
	);

	function downloadFinancial() {
		downloadFinancialFile().then((responseData) => {
			let arrayBuffer = responseData.data;
			downloadPdfFromArrayBuffer(arrayBuffer, file.filename);
		}).catch((error) => {console.log("Download financial file error", error)})
	}

	function deleteFile() {
		setDeleting(true);
		deleteFinancialFile({
			client: {
				id_client: file.id_client,
			},
			file: {
				id_file: file.id_file,
			}
		}).then((responseData) => {
			//console.log("Deleted");
		}).catch((error) => {console.log("Delete financial file error", error)})
	}


	return <FlexBox center {...bindHover} sx={deleting ? {opacity: 0.5, pointerEvents: 'none'} : null}>
		<FlexBox align="end" sx={{flex: '1 1 auto', width: 0}}>
			<Typography variant="caption" mr={1}>
				{ moment(file.timestamp_created).format("DD/MM/YY") }
			</Typography>			
			<Typography sx={{flex: '1 1 auto', width: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
				<Link sx={{cursor: 'pointer'}} title={file.filename} onClick={downloadFinancial}>
					{ file.filename }
				</Link>
			</Typography>
		</FlexBox>
		{ adminMode && hovering && !deleting &&
			<IconButton sx={{my: -2}} onClick={deleteFile}>
				<HighlightOffOutlined />
			</IconButton>
		}
	</FlexBox>


}