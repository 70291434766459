import React, {
	useEffect,
	useState,
} from "react";
import { Auth } from "aws-amplify";
import QRCode from 'qrcode.react';
import useInput from "hooks/useInput";
import FormError from 'components/common/FormError';
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/layout/FlexBox";

export default function MfaSetupForm({ cognitoUser, onMfaSetup }) {
	
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [setupCode, setSetupCode] = useState(null);

	const { value: authenticatorCode, bind: bindAuthenticatorCode } = useInput("", true);

	const issuer = "";

	useEffect(() => {
		let isSubscribed = true;

		try {
			Auth.setupTOTP(cognitoUser).then((code) => {
				if (isSubscribed) {

					setLoading(false);
					setSetupCode(code);

					// You can directly display the `code` to the user or convert it to a QR code to be scanned.
					// E.g., use following code sample to render a QR code with `qrcode.react` component:  
					//      import QRCode from 'qrcode.react';
					//      const str = "otpauth://totp/AWSCognito:"+ username + "?secret=" + code + "&issuer=" + issuer;
					//      <QRCode value={str}/>				
				}
				
			});
		} catch(error) {
			if (isSubscribed) {
				setLoading(false);
				if (error && error.message) {
					setFormError(error.message);
				} else {
					setFormError("Failed to setup MFA");
				}
			}
		}

		return () => { isSubscribed = false; }
	}, [cognitoUser]);
	
	const handleSubmitAuthenticatorCode = async(event) => {
		event.preventDefault();

		setLoading(true);

		try {
			Auth.verifyTotpToken(cognitoUser, authenticatorCode).then(() => {
				setLoading(false);

				Auth.setPreferredMFA(cognitoUser, 'TOTP');

				// TODO: This is not an updated user, challenge will still be in there. Login again here?
				onMfaSetup(cognitoUser);

			}).catch(error => {
				setLoading(false);
				setFormError("Invalid code");
			});
		} catch(error) {
			setLoading(false);
			if (error && error.message) {
				setFormError(error.message);
			} else {
				setFormError("Failed to submit code");
			}
		}

	};		
	
	return (
		<>
			<form onSubmit={ handleSubmitAuthenticatorCode }>
				<Stack spacing={2}>
					<Typography variant="h6">Set up multifactor authentication</Typography>
					<Box className="test" sx={{ display: 'flex', justifyContent: 'center' }} paddingTop={1}>
						{ setupCode ? <>
							<QRCode value={"otpauth://totp/AWSCognito:"+ cognitoUser.username + "?secret=" + setupCode + "&issuer=" + issuer}/>
						</> :
							<FlexBox center sx={{height: '128px'}}>
								<CircularProgress />
							</FlexBox>
						}
					</Box>
					<Typography variant="caption" align="center">Scan the QR code to set up MFA with Google Authenticator, then enter the code below</Typography>
					<TextField label="Code" {...bindAuthenticatorCode} type="text" />
					<Button
						variant="contained"
						color="primary"
						size="large"
						type="submit"
						disabled={loading}
					>
						{loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
						Submit
					</Button>
					{ formError &&
						<FormError>{formError}</FormError>
					}
				</Stack>
			</form>
		</>
	);
};

