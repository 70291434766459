import { useMemo } from "react";
import {
	useParams,
	useLocation,
} from "react-router-dom";
import queryString from "query-string";

export function useQuery() {
	const params = useParams();
	const location = useLocation();

	return useMemo(() => {
		return {
			...queryString.parse(location.search), // Convert string to object
			...params,
		};
	}, [params, location]);
}