import { calculateBondStatusUsage } from "BondFunctions";

export function formatNumber(value, dp, pad) {
	if (pad === undefined) pad = true;

	var numericValue = stripNonNumeric(value);

	if (dp > 0) {
		var rounder = Math.pow(10, dp);
		numericValue = Math.round(numericValue * rounder) / rounder;
	}

	value = '' + numericValue;
	var dot = value.indexOf(".");
	var decimal = (dot >= 0 ? value.substr(dot + 1) : '');
	if (pad && (dp !== undefined) && !isNaN(dp)) {
		while(decimal.length < dp) {
			decimal = decimal + "0";
		}
		decimal = decimal.substr(0, dp);
	}
	var whole = (dot >= 0 ? value.substr(0, dot) : value);

	var negative = whole.charAt(0) === "-";
	if (negative) whole = whole.substr(1);
	
	var formattedWhole = "";
	for (var i = whole.length - 3; i > 0; i -= 3) {
		formattedWhole = whole.substr(i, 3) + (formattedWhole.length > 0 ? "," : "") + formattedWhole;
	}
	formattedWhole = whole.substr(0, i + 3) + (formattedWhole.length > 0 ? "," : "") + formattedWhole;

	return (negative ? "-" : '') + formattedWhole + (decimal ? '.' + decimal : '');
}
export function formatMoney(value, dp, includeDollarSign) {
	let formatted = formatNumber(value, dp);
	let dot = formatted.indexOf(".");
	if (dot >= 0 && dot !== formatted.length - 3) formatted = formatNumber(value, 2); // If an unusual number of dp for a dollar value, go again with 2 specified
	if (includeDollarSign !== false) {
		if (formatted.charAt(0) === "-") return "-$" + formatted.substring(1);
		else return '$' + formatted;
	} else {
		return formatted;
	}
}
export function formatPercentage(value, dp) {
	return formatNumber(value, dp) + '%';
}
export function stripNonNumeric(value) {
	return parseFloat(('' + value).replace(/[^\d.-]/g, ''));
}
export function sanitizeNumeric(value) {
	var money = ('' + value).replace(/[^\d.$,-]/g, '');
	var dot = money.indexOf('.');
	if (dot >= 0) {
		while(true) {
			var nextDot = money.indexOf('.', dot + 1);
			if (nextDot >= 0) money = money.substr(0, nextDot) + money.substr(nextDot + 1);
			else break;
		}
	}
	return money;
}

export function isBlank(n) {
	return (n === null || n === undefined);
}

export function anyBlank(...args) {
	for (let i = 0; i < args.length; i++) {
		let n = args[i];
		if (n === null || n === undefined) return true;
	}
	return false;	
}

export function searchByField(list, field, value) {
	for (var s in list) {
		var o = list[s];
		if (o[field] === value) return o;
	}
	return null;
}

export function indexByField(list, field, value) {
	for (var i = 0; i < list.length; i++) {
		var o = list[i];
		if (o[field] === value) return i;
	}
	return -1;
}



export function compareMoneyStrings(a, b) {
	let aFloat = parseFloat(stripNonNumeric(a));
	let bFloat = parseFloat(stripNonNumeric(b));
	return bFloat - aFloat;
}

export function compareDateStrings(a, b) {
	if (!a && !b) return 0;
	if (!a) return -1;
	if (!b) return 1;
	var aParts = a.split('/');
	var bParts = b.split('/');
	if (aParts.length !== 3 || bParts.length !== 3) return a.localeCompare(b);
	var comp2 = aParts[2].localeCompare(bParts[2]);
	if (comp2 !== 0) return comp2;
	var comp1 = aParts[1].localeCompare(bParts[1]);
	if (comp1 !== 0) return comp1;
	return aParts[0].localeCompare(bParts[0]);
}

export function compareAny(a, b) {
	if (!a && !b) return 0;
	if (!a) return -1;
	if (!b) return 1;
	if (a instanceof Object) {
		if (a.props && b.props) {
			// Various jsx types
			if ((a.props.value !== undefined) && (b.props.value !== undefined)) {
				if ((typeof a.props.value === "string") && (typeof b.props.value === "string")) return b.props.value.localeCompare(a.props.value)
				else return b.props.value - a.props.value;
			} else if ((a.props.children !== undefined) && (b.props.children !== undefined)) {
				return compareAny(a.props.children, b.props.children);
			} else if ((a.props.checked !== undefined) && (b.props.checked !== undefined)) {
				return (b.props.checked ? 1 : 0) - (a.props.checked ? 1 : 0);
			} else if ((a.props.bondStatus !== undefined) && (b.props.bondStatus !== undefined)) {
				return b.props.bondStatus.name.localeCompare(a.props.bondStatus.name);
			} else {
				// Unhandled object
				return undefined;
			}
		} else {
			// Unhandled object
			return undefined;
		}
	} else if ((typeof a === "string") && (typeof b === "string")) {
		if (a.charAt(0) === "$") {
			return stripNonNumeric(b) - stripNonNumeric(a);
		} else {
			return b.localeCompare(a);
		}
	} else {
		return b - a;
	}
}

export function filterAny(value, filter) {
	if (!filter) return true;
	filter = filter.toLowerCase();

	let string = null;
	let checkValue = value;

	for (var attempts = 0; attempts < 10; attempts++) {
		if (checkValue instanceof Object) {
			if (checkValue.props) {
				// Various jsx types
				if (checkValue.props.value !== undefined) {
					if (typeof checkValue.props.value === "string") string = checkValue.props.value;
				} else if (checkValue.props.to !== undefined) {
					// Link. Drill down further
					checkValue = checkValue.props.children;
				} else if (checkValue.props.children !== undefined) {
					string = checkValue.props.children;
				} else if (checkValue.props.checked !== undefined) {
					return false;
				} else if (checkValue.props.bondStatus !== undefined) {
					string = checkValue.props.bondStatus.name;
				} else {
					// Unhandled object
					return false;
				}
			} else {
				// Unhandled object
				return false;
			}
		} else if (typeof checkValue === "string") {		
			string = checkValue;
		} else {
			return false;
		}
		if (string !== null) break;
	}

	if (string === null) return false;
		
	return string.toLowerCase().indexOf(filter) >= 0;

}



// Function to download data to a file
export function downloadFile(data, filename, type) {
    var file = new Blob([data], {type: type});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
		var url = URL.createObjectURL(file);

		autoDownloadUrl(url, filename);
    }
}

// Function to download a PDF file retrieved as an ArrayBuffer
export function downloadPdfFromArrayBuffer(arrayBuffer, filename) {

	var blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    var url = URL.createObjectURL(blob);

	autoDownloadUrl(url, filename);
}

function autoDownloadUrl(url, filename) {
	var a = document.createElement("a");
	document.body.appendChild(a);
	a.href = url;
	a.download = filename;
	a.click();
	setTimeout(function() {
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);  
	}, 0); 
}


export function tagForRemoval(target, source, field) {
	if (!target || !source) return;

	source.forEach((sourceElement) => {
		const targetElement = searchByField(target, field, sourceElement[field]);
		if (!targetElement) target.push({
			...sourceElement,
			remove: true,
		})
	});

}




export function checkFormValidity(form) {
	return form.reportValidity();
}


export function generateUniqueNumberedName(list, field, name, maxIterations) {
	if (maxIterations === undefined) maxIterations = 1000;
	let n = 1;
	for (let i = 0; i < maxIterations; i++) {
		let found = false;
		for (let key in list) {
			let o = list[key];
			if (name + n === o[field]) {
				found = true;
				break;
			}
		}
		if (!found) {
			return name + n;
		}
	}
}


export function getIssuerHeaderLines(issuer) {
	var lines = [];
	if (issuer) {
		let nameLine = issuer.legal_name || issuer.name;
		if (issuer.abn) nameLine += "  ABN " + issuer.abn;
		lines.push(nameLine);

		let addressLine = "";
		if (issuer.business_address_street) addressLine += issuer.business_address_street;
		if (issuer.business_address_suburb) {
			if (addressLine.length > 0) addressLine += ", ";
			addressLine += issuer.business_address_suburb;
		}
		if (issuer.business_address_state) {
			if (addressLine.length > 0) addressLine += ", ";
			addressLine += issuer.business_address_state;
		}
		if (issuer.business_address_postcode) {
			if (addressLine.length > 0) addressLine += " ";
			addressLine += issuer.business_address_postcode;
		}
		if (addressLine.length > 0) lines.push(addressLine);

		if (issuer.business_phone) lines.push("T " + issuer.business_phone);
		
		if (issuer.business_website) lines.push("" + issuer.business_website);
	}
	return lines;
}


export function calculateFacilityTotals(facilitys, staticData) {
	let highestCapacity = 0;
	let utilised = 0;
	let quoted = 0;
	let limit = 0;
	if (staticData && facilitys) {
		facilitys.forEach((facility) => {
			if (facility.active) {
				const { pending, active } = calculateBondStatusUsage(facility.bond_status_totals, staticData.bond_statuses);
				utilised += (active || 0);
				quoted += (pending || 0);
				limit += facility.limit;
				highestCapacity = Math.max(highestCapacity, facility.limit);
			}
		});
	}
	utilised = Math.round(utilised * 100) / 100;
	quoted = Math.round(quoted * 100) / 100;
	limit = Math.round(limit * 100) / 100;

	return { highestCapacity, utilised, quoted, limit };
}

export async function base64ToBytes(base64) {
	//return (await fetch("data:application/octet;base64," + base64)).arrayBuffer();
	return (await fetch(base64)).arrayBuffer();
}

export function orderFacilitys(facilitys) {
	let orderedFacilitys = facilitys.filter(facility => facility.active);
	orderedFacilitys.sort((a, b) => { return b.limit - a.limit });
	return orderedFacilitys;
}