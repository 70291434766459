import React, { useContext } from "react";
import API from "API";
import { useGet } from "hooks/useAPI";
import { useQuery } from "hooks/useQuery";
import UserContext from "contexts/UserContext";
import Financials from "components/modules/Financials";
import CentreLoader from "components/common/CentreLoader";
import PageTitle from "components/common/PageTitle";
import { useDetectClient } from "hooks/useDetectClient";

export function AdminClientFinancialPage() {
	
	const query = useQuery();
	const id_client = (query.id === "add") ? null : parseInt(query.id);

	useDetectClient(id_client);

	const { user } = useContext(UserContext);

	const [clientData] = useGet(id_client ? API.getClient(user, id_client) : null);
	const client = (clientData || {}).client;
	const facilitys = (clientData || {}).facilitys;

	return <>
		{ (client && facilitys) ? <>
			<PageTitle title={client.name} />
			<Financials adminMode={true} client={client} facilitys={facilitys} />
		</> : <CentreLoader /> }
	</>
}

export function ClientClientFinancialPage() {

	const { user } = useContext(UserContext);

	const [clientData] = useGet(API.getClient(user));
	const client = (clientData || {}).client;
	const facilitys = (clientData || {}).facilitys;
	
	return <>
		{ (client && facilitys) ? <>
			<PageTitle title={client.name} />
			<Financials adminMode={false} client={client} facilitys={facilitys} />
		</> : <CentreLoader /> }
	</>
}

