import React, { useContext } from "react";
import UserContext from "contexts/UserContext";
import { Navigate } from "react-router";

export default function ClientRoute({ children, ...attributes }) {

	const { user } = useContext(UserContext)

	return (<>
		{ (user && user.cognito && user.inscap) &&
			(user && !user.isAdmin) ?
				children
			:
				<Navigate to="/403" />
		}
	</>);


};
