import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useState } from "react";
import { compareAny } from "Utils";

export default function SortableTableBase({ columns, rows, firstRow, lastRow, stickyHeader, ...props }) {

	const [order, setOrder] = useState(null);
	const [orderBy, setOrderBy] = useState(null);

	var sortedRows = rows;

	function sortRows() {
		if ((orderBy !== undefined) && columns && (rows.length > 0)) {
			var column = columns[orderBy];
			if (column) {
				var compareFunc = column.compareFunc || compareAny;
				sortedRows.sort((a, b) => {
					return (order === 'asc') ? compareFunc(a.cells[orderBy], b.cells[orderBy]) : compareFunc(b.cells[orderBy], a.cells[orderBy]);
				});
			}
		}
	}
	sortRows();

	if (firstRow !== undefined && lastRow !== undefined) sortedRows = sortedRows.slice(firstRow, lastRow);


	function changeOrder(columnIndex) {
		if (orderBy === columnIndex) {
			setOrder((order === 'desc') ? 'asc' : 'desc');
		} else {
			setOrderBy(columnIndex);
			setOrder('desc');
		}
	}

	return <Table stickyHeader {...props}>
		{ columns &&
			<TableHead>
				<TableRow>
					{columns.map((column, index) => {
						return <TableCell key={column.key} align={column.align} sx={{minWidth: column.minWidth, width: column.width, textAlign: column.alignHeader, whiteSpace: "nowrap"}}>
							<TableSortLabel
								active={orderBy === index}
								direction={orderBy === index ? order : 'asc'}
								onClick={() => {changeOrder(index)}}
							>
								{column.cell}
							</TableSortLabel>									
						</TableCell>
					})}
				</TableRow>
			</TableHead>
		}

		<TableBody>
			{ (sortedRows && sortedRows.length > 0) ?
				sortedRows.map((row, index) => {
					return <TableRow key={row ? row.key : "_" + index } className={row.onClick ? "clickable" : ''} sx={row.sx}
						onClick={(event) => {
							if (row.onClick) {
								var isRowClick = true;
								for (var node = event.target; !node || node.tagName !== "TD"; node = node.parentNode) {
									if (node.tagName === "A") isRowClick = false;
								}
								if (isRowClick) {
									row.onClick(event)}
								}
							}
						}
					>
						{ row && row.cells && row.cells.map((content, index) => {
							return <TableCell key={index} align={columns[index].align}>{content}</TableCell>
						})}
					</TableRow>
				})
			:
				<TableRow>
					<TableCell colSpan={columns.length} sx={{textAlign: 'center'}}>No results found</TableCell>
				</TableRow>
			}
		</TableBody>

	</Table>
}