import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/layout/FlexBox";
import { Link } from "react-router-dom";

export default function DownloadButton({ children, to, ...props }) {
	function renderContent() {
		return <FlexBox center>
			<Download sx={{marginLeft: '-5px', marginRight: 1}}/>
			<Box sx={{position: 'relative', top: '1px'}}>{children}</Box>
		</FlexBox>
	}

	return to ? 
		<Button component={Link} to={to} variant="outlined" {...props}>
			{ renderContent() }
		</Button>
	:
		<Button variant="outlined" {...props}>
			{ renderContent() }
		</Button>

}