import { FilterList } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import NullableTextField from "components/common/NullableTextField";
import SectionTitle from "components/common/SectionTitle";
import PaginatedTable from "components/modules/tables/PaginatedTable";
import { useState } from "react";
import { filterAny } from "Utils";

export default function FilterableTable({ title, actions, rows, ...props }) {

	const [filtering, setFiltering] = useState(false);
	const [filter, setFilter] = useState(null);


	const filterRows = (rows) => {
		if (!filter) return rows;

		let filteredRows = [];
		rows.forEach((row) => {
			let allow = false;
			row.cells.forEach((cell) => {
				if (filterAny(cell, filter)) allow = true;
			});
			if (allow) filteredRows.push(row);
		});
		return filteredRows;
	}
	const filteredRows = filter ? filterRows(rows) : rows;
	
	
	return <>
		<SectionTitle title={title} actions={<>
			{ filtering ?
				<NullableTextField
					label="Filter"
					//placeholder="Filter"
					inputRef={input => input && input.focus()}
					sx={{
						background: '#ffffff',
						input: {
							paddingTop: 1,
							paddingBottom: 1
						},
						"label[data-shrink=false]": {
							transform: 'translate(14px, 8px) scale(1)',
						},
					}}
					value={filter}
					onChange={(value) => setFilter(value)}
				/>
			:
				<IconButton onClick={() => {setFiltering(true)}} >
					<FilterList />
				</IconButton>
			}
			{ actions }
		</>} />

		<PaginatedTable rows={filteredRows} {...props} />

	</>

}