import { Typography } from "@mui/material"
import FlexBox from "components/layout/FlexBox"
import MobilePadding from "components/layout/MobilePadding"
import Actions from "components/modules/Actions"

export default function SectionTitle({ title, actions, sx, ...props }) {
	return <MobilePadding {...props}>
		<FlexBox center sx={{minHeight: '36px', marginBottom: 1, ...sx}}>
			<Typography variant="cardHeader" mr="auto">{title}</Typography>
			{ actions ? <Actions>{actions}</Actions> : null }
		</FlexBox>
	</MobilePadding>
}