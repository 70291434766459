import React, { useContext } from "react";
import { useGet } from "hooks/useAPI";
import API from "API";
import CentreLoader from "components/common/CentreLoader";
import UserContext from "contexts/UserContext";
import { Navigate } from "react-router-dom";

export function DataroomDashboardPage() {	

	const { user } = useContext(UserContext);

	const [clientsData] = useGet(API.getClients(user));
	const { clients } = clientsData || {};


	return <>
		{ clients ? <>
			{/*<ClientsTable title="Clients" clients={clients} showTotals={false} />*/}
			{ clients.length > 0 ?
				<Navigate to={'/client/' + clients[0].id_client} />
				: null
			}
		</> : <CentreLoader /> }	
	</>

};

