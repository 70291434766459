import React, { useContext, useEffect, useState } from "react";
import { indexByField, searchByField } from "Utils";
import StaticDataContext from "contexts/StaticDataContext";
import API from "API";
import { useGet } from "hooks/useAPI";
import PageTitle from "components/common/PageTitle";
import CentreLoader from "components/common/CentreLoader";
import UserContext from "contexts/UserContext";
import IndemnifiersTable from "components/modules/tables/IndemnifiersTable";
import FacilitysTable from "components/modules/tables/FacilitysTable";


export default function CompanyProfilePage() {

	const { user } = useContext(UserContext);

	const staticData = useContext(StaticDataContext);
	
	const [originalClientData] = useGet(API.getClient(user));
	const { client, facilitys, indemnifiers } = originalClientData || {};

	const [facilityStates, setFacilityStates] = useState([]);
	const [facilityOrder, setFacilityOrder] = useState([]);


	// Construct facilities state
	useEffect(() => {
		if (!facilitys || !staticData) return;

		let updatedFacilityStates = [];
		let updatedFacilityOrder = [];

		staticData.issuers.forEach((issuer, index) => {
			const facility = searchByField(facilitys, 'id_issuer', issuer.id_issuer);
			
			const facilityState = {
				id_facility: facility && facility.id_facility,
				id_issuer: issuer.id_issuer,
				facilityIndex: facility && indexByField(facilitys, 'id_facility', facility.id_facility),
				issuerIndex: index,
				name: issuer.name,
				active: (facility !== null),
			};

			updatedFacilityStates.push(facilityState);
			if (facilityState.active) updatedFacilityOrder.push(facilityState);
		});

		setFacilityStates(updatedFacilityStates);
		setFacilityOrder(updatedFacilityOrder);
	}, [facilitys, staticData, setFacilityStates, setFacilityOrder]);
	
	return <>
		{ (staticData && client && facilitys && facilityStates && facilityOrder) ? <>
			<PageTitle title={client.name} />

			<FacilitysTable facilityStates={facilityStates} facilitys={facilitys} />

			<IndemnifiersTable facilityOrder={facilityOrder} indemnifiers={indemnifiers} />

		</> : <CentreLoader /> }
	</>
};


