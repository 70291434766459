import { Stack, Typography } from "@mui/material"
import FinancialFileLink from "components/common/FinancialFileLink"
import FileUpload from "components/modules/FileUpload"

export default function FinancialFiles({ adminMode, year, id_client, files, postFileUpload }) {

	return <>

		{ files && 
			(files.length > 0) ?
				<Stack spacing={1}>
					{ files.map((file) => {
						return <FinancialFileLink key={file.id_file} id_client={id_client} file={file} adminMode={adminMode} />
					}) }
				</Stack>
			:
				<Typography variant="secondary">No file has been uploaded</Typography>
		}

		{ adminMode && 
			<FileUpload sx={{mt: 2, minWidth: 165}} inputId={"financial-file-upload-" + year} postFileUpload={postFileUpload} data={{ year: year, id_client: id_client }}/>
		}

	</>

}
