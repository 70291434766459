import { useState } from "react";

export default function useHover() {
	const [isHovering, setIsHovering] = useState(false);
	return [
		isHovering,
		{
			onMouseEnter: () => {
				setIsHovering(true);
			},
			onMouseLeave: () => {
				setIsHovering(false);
			}
		}
	];
}