import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";

const LayoutContext = React.createContext({ });

export const LayoutProvider = ({ children }) => {	
	const [drawerOpen, setDrawerOpen] = useState(false);	
	const [navCollapsed, setNavCollapsed] = useState(false);	

	const theme = useTheme();
	const wideLayout = useMediaQuery(theme.breakpoints.up('md'));

	const navStyle = {
		width: navCollapsed ? theme.sideNav.collapsedWidth : theme.sideNav.expandedWidth,
		transition: 'width ' + theme.sideNav.transitionDuration,
		overflow: 'hidden',
	}

	const contentStyle = {
		flex: '1 1 0',
		width: 0,
		maxWidth: wideLayout ? (navCollapsed ? 'calc(100% - ' + theme.sideNav.collapsedWidth + ')' : 'calc(100% - ' + theme.sideNav.expandedWidth + ')') : '100%', transition: 'max-width ' + theme.sideNav.transitionDuration
	}

	const containerStyle = wideLayout ? {} : {
		paddingLeft: 0,
		paddingRight: 0
	};


	return <LayoutContext.Provider value={{ wideLayout, drawerOpen, setDrawerOpen, navCollapsed, setNavCollapsed, navStyle, contentStyle, containerStyle }}>
		{ children }
	</LayoutContext.Provider>;
}

export default LayoutContext;