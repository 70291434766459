import { ENV } from "Environment";
import mockedData from "MockedStaticData";

export function mockAPICalls(axios) {

	const partial = (ENV.mock === 'partial');
	const MockAdapter = require("axios-mock-adapter");
	const mock = new MockAdapter(axios, {
		delayResponse: 1000,
		onNoMatch: (partial ? "passthrough" : null),
	});


	const localClientId = 1;

	// getAdminStaticDataUrl
	if (!partial) mock.onGet('/admin/static').reply((config) => {
		return [200, {
			data: {
				issuers: mockedData.issuers,
				banks: mockedData.banks,
				bond_types: mockedData.bond_types,
				facility_fee_types: mockedData.facility_fee_types,
				durations: mockedData.durations,
				bond_statuses: mockedData.bond_statuses,
			}
		}];
	});

	// getClientStaticDataUrl
	if (!partial) mock.onGet('/client/static').reply((config) => {
		return [200, {
			data: {
				issuers: mockedData.issuers,
				banks: mockedData.banks,
				bond_types: mockedData.bond_types,
				facility_fee_types: mockedData.facility_fee_types,
				durations: mockedData.durations,
				bond_statuses: mockedData.bond_statuses,
			}
		}];
	});

	// getUserDataUrl
	if (!partial) mock.onGet('/user').reply((config) => {
		return [200, {
			data: {
				user: {		
					name: "Some User",
					groups: ['admin'], //'client'
					id_client: 1,
				}
			}
		}];
	});

	// getAdminClientsUrl
	if (!partial) mock.onGet('/admin/clients').reply((config) => {
		return [200, {
			data: {
				clients: mockedData.clients,
			}
		}];
	});

	// getAdminClientUrl(id_client)
	if (!partial) mock.onGet(/admin\/client?.*/).reply((config) => {
		let id_client = parseInt(config.url.substr(config.url.lastIndexOf('=') + 1));
		return [200, {
			data: {
				client: mockedData.clients.filter(client => client.id_client === id_client)[0],
				facilitys: mockedData.facilitys.filter(facility => facility.id_client === id_client),
				indemnifiers: mockedData.indemnifiers.filter(indemnifier => indemnifier.id_client === id_client),
				contacts: mockedData.contacts.filter(contact => contact.id_client === id_client),
			}
		}];
	});

	// getClientClientUrl
	if (!partial) mock.onGet('/client/client').reply((config) => {
		let id_client = localClientId;
		return [200, {
			data: {
				client: mockedData.clients.filter(client => client.id_client === id_client)[0],
				facilitys: mockedData.facilitys.filter(facility => facility.id_client === id_client),
				indemnifiers: mockedData.indemnifiers.filter(indemnifier => indemnifier.id_client === id_client),
			}
		}];
	});

	// getAdminBondsAllUrl
	// getAdminBondsUrl(id_client)
	if (!partial) mock.onGet(/admin\/bonds?.*/).reply((config) => {
		let id_client = parseInt(config.url.substr(config.url.lastIndexOf('=') + 1));
		return [200, {
			data: {
				bonds: id_client ? mockedData.bonds.filter(bond => bond.id_client === id_client) : mockedData.bonds,
			}
		}];
	});

	// getClientBondsUrl(id_client)
	if (!partial) mock.onGet('/client/bonds').reply((config) => {
		let id_client = localClientId;
		return [200, {
			data: {
				bonds: mockedData.bonds.filter(bond => bond.id_client === id_client),
			}
		}];
	});

	// getAdminContactUrl(id_contact)
	if (!partial) mock.onGet(/admin\/contact\/?.*/).reply((config) => {
		let id_contact = parseInt(config.url.substr(config.url.lastIndexOf('=') + 1));
		return [200, {
			data: {
				contact: mockedData.contacts.filter(contact => contact.id_contact === id_contact)[0],
			}
		}];
	});

	// getAdminBondDetailUrl(id_bond)
	if (!partial) mock.onGet(/admin\/bond\/detail\/?.*/).reply((config) => {
		let id_bond = parseInt(config.url.substr(config.url.lastIndexOf('=') + 1));
		return [200, {
			data: {
				bond: mockedData.bonds.filter(bond => bond.id_bond === id_bond)[0],
				// Missing bond_history, extensions, relatedBonds
			}
		}];
	});

	// getClientBondDetailUrl(id_bond)
	if (!partial) mock.onGet(/client\/bond\/detail\/?.*/).reply((config) => {
		let id_bond = parseInt(config.url.substr(config.url.lastIndexOf('=') + 1));
		return [200, {
			data: {
				bond: mockedData.bonds.filter(bond => bond.id_bond === id_bond)[0],
				// Missing bond_history, extensions, relatedBonds
			}
		}];
	});

	// getAdminFacilityUrl(id_facility)
	if (!partial) mock.onGet(/admin\/facility\/?.*/).reply((config) => {
		let id_facility = parseInt(config.url.substr(config.url.lastIndexOf('=') + 1));
		return [200, {
			data: {
				facility: mockedData.facilitys.filter(facility => facility.id_facility === id_facility)[0],
			}
		}];
	});




	// getAdminClientFinancialsUrl(id_client, financial_years)
	mock.onGet(/admin\/financials\/?.*/).reply((config) => {
		return [200, {
			data: {
				"client" : {
					"id_client" : 1
				},
				"financial" : {
					"constants" : [
						{
							"key"   : "Tax Rate",
							"value" : "27.50%"
						},
						{
							"key"   : "Surety",
							"value" : "$4,000,000"
						}
					],
					"years" : {
						2022: {
							"graphs" : [
								{
									"name"  : "Altman Z-Score",
									"value" : "2.5"
								},
								{
									"name"  : "Debt Equity",
									"value" : "2.5"
								}
							],
							"sheets" : [
								{
									"name" : "Summarised Balance Sheet",
									"financials" : [
										{
											"amount" : "$1,001.01",
											"name"   : "Cash",
											"type"   : "Current Assets"
										},
										{
											"amount" : "$1,001.02",
											"name"   : "Other",
											"type"   : "Current Assets"
										},
										{
											"amount" : "$500.04",
											"name"   : "Other",
											"type"   : "Non-Current Assets"
										},
										{
											"amount" : "$200.07",
											"name"   : "Other",
											"type"   : "Current Liabilities"
										}
									]
								},
								{
									"name" : "Summarised Profit and Loss Statement",
									"financials" : [
										{
											"amount" : "$2,002.01",
											"name"   : "Revenue",
											"type"   : "Revenue"
										},
										{
											"amount" : "$2,002.02",
											"name"   : "Cash-ola",
											"type"   : "Assets"
										},
										{
											"amount" : "$100.23",
											"name"   : "Test1",
											"type"   : "Cost of Goods Sold"
										},
										{
											"amount" : "$200.34",
											"name"   : "Test2",
											"type"   : "Cost of Goods Sold"
										},
										{
											"amount" : "$300.45",
											"name"   : "Test3",
											"type"   : "Depreciation"
										},
									]
								}
							]
						}
			
					}
				}
			}
		}];
	});






}