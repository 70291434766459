import React, {
	useState,
} from "react";
import { Auth } from "aws-amplify";
import useInput from "hooks/useInput";
import FormError from "components/common/FormError";
import { Button, TextField, Stack, Typography, CircularProgress } from "@mui/material";

export default function LoginForm({ onLoggedIn, restartMessage }) {
	
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(restartMessage);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [forgotPasswordSubmitted, setForgotPasswordSubmitted] = useState(false);
	const [forgotPasswordSucceeded, setForgotPasswordSucceeded] = useState(false);

	const { value: email, bind: bindEmail } = useInput("", true);
	const { value: password, bind: bindPassword } = useInput("", true);
	const { value: code, bind: bindCode } = useInput("", true);
	const { value: newPassword, bind: bindNewPassword } = useInput("", true);

	const handleSubmitLogin = async(event) => {
		event.preventDefault();

		setLoading(true);
		setFormError(null);
		setForgotPasswordSucceeded(false);

		try {
			const cognitoUser = await Auth.signIn(email, (password || "").trim());
			setLoading(false);
			onLoggedIn(cognitoUser);
		} catch(error) {
			setLoading(false);
			if (error && error.message) {
				setFormError(error.message);
			} else {
				setFormError("Failed to log in");
			}
		}

	};		

	const handleSubmitForgotPassword = async(event) => {
		event.preventDefault();

		setLoading(true);
		setFormError(null);

		try {
			Auth.forgotPassword(email).then((data) => {
				console.log("Forgot password submitted", data);
				setForgotPasswordSubmitted(true);
				setLoading(false);
			}).catch((error) => {
				console.log("Forgot password error", error)
				setFormError(error.message);
			});
		} catch(error) {
			if (error && error.message) {
				setFormError(error.message);
			} else {
				setFormError("Failed to send forgot password email");
			}
			setLoading(false);
		}

	};		

	const handleSubmitForgotPasswordCode = async(event) => {
		event.preventDefault();

		setLoading(true);
		setFormError(null);

		try {
			Auth.forgotPasswordSubmit(email, code, newPassword).then((data) => {
				if (data === "SUCCESS") {
					setLoading(false);
					setForgotPassword(false);
					setForgotPasswordSubmitted(false);
					setForgotPasswordSucceeded(true);
				}
			});
		} catch(error) {
			if (error && error.message) {
				setFormError(error.message);
			} else {
				setFormError("Failed to send forgot password email");
			}
			setLoading(false);
		}

	};		

	return ( forgotPasswordSubmitted ?
		<form onSubmit={ handleSubmitForgotPasswordCode }>
			<Stack spacing={2}>
				<Typography variant="h6">Forgot password</Typography>
				<TextField disabled label="Email" {...bindEmail} type="email" />
				<Typography variant="caption">Enter the verification code which has been sent to the email above</Typography>
				<TextField label="Verification code" {...bindCode} type="text" />
				<TextField label="New password" type="password" {...bindNewPassword} />
				<Button key="submit forgot" variant="contained" size="large" type="submit" disabled={loading} >
					{loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
					Submit
				</Button>
				<Button key="back" variant="text" size="small" disabled={loading} onClick={() => {setForgotPassword(false); setForgotPasswordSubmitted(false)}}>
					Back
				</Button>
				{ formError &&
					<FormError>{formError}</FormError>
				}
			</Stack>
		</form>		
	: forgotPassword ?
		<form onSubmit={ handleSubmitForgotPassword }>
			<Stack spacing={2}>
				<Typography variant="h6">Forgot password</Typography>
				<TextField label="Email" {...bindEmail} type="email" />
				<Button key="submit forgot" variant="contained" size="large" type="submit" disabled={loading} >
					{loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
					Send email
				</Button>
				<Button key="back" variant="text" size="small" disabled={loading} onClick={() => {setForgotPassword(false)}}>
					Back
				</Button>
				{ formError &&
					<FormError>{formError}</FormError>
				}
			</Stack>
		</form>
	:
		<form onSubmit={ handleSubmitLogin }>
			<Stack spacing={2}>
				<Typography variant="h6">Log in to your account</Typography>
				{ forgotPasswordSucceeded &&
					<Typography variant="caption">Your password has been reset</Typography>
				}
				<TextField label="Email" {...bindEmail} type="email" />
				<TextField label="Password" type="password" {...bindPassword} />
				<Button key="submit login" variant="contained" size="large" type="submit" disabled={loading} /*sx={{background: ENV.headerColor, ':hover': { background: ENV.headerColor }}}*/ >
					{loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
					Log in
				</Button>
				<Button key="forgot" variant="text" size="small" disabled={loading} onClick={() => {setForgotPassword(true)}}>
					Forgot password
				</Button>
				{ formError &&
					<FormError>{formError}</FormError>
				}
			</Stack>
		</form>
	);
};

