import { Box } from "@mui/system";
import useDrag from "hooks/useDrag";
import { forwardRef, useEffect, useRef } from "react";

const ssbHeight = 34;
const ssbHandleHeight = ssbHeight - 2;
const ssbInteriorHeight = ssbHandleHeight - 2;

const ssbHandleSideWidth = 50;

export default function ScaleScrollBar({ lowValue, highValue, minRange, maxRange, onChange }) {

	if (minRange === undefined) minRange = 0.1;
	if (maxRange === undefined) maxRange = 1;

	const barRef = useRef(null);
	const handleRef = useRef(null);
	const scrollRef = useRef(null);
	const leftRef = useRef(null);
	const rightRef = useRef(null);

	const { dragging: draggingHandle, center: handleCenter } = useDrag(scrollRef);
	const { dragging: draggingLeft, center: leftCenter } = useDrag(leftRef);
	const { dragging: draggingRight, center: rightCenter } = useDrag(rightRef);

	useEffect(() => {
		if (!draggingLeft) return;
		if (!barRef || !barRef.current || !leftCenter) return;
		const barBounds = barRef.current.getBoundingClientRect();

		let f = (leftCenter.x - ssbHandleSideWidth * 0.5 - barBounds.x) / (barBounds.width - ssbHandleSideWidth * 2);
		f = Math.max(0, highValue - maxRange, Math.min(1, highValue - minRange, f));

		onChange({ lowValue: f, highValue });

	}, [draggingLeft, leftCenter, highValue, minRange, maxRange, onChange]);

	useEffect(() => {
		if (!draggingRight) return;
		if (!barRef || !barRef.current || !rightCenter) return;
		const barBounds = barRef.current.getBoundingClientRect();

		let f = (rightCenter.x - ssbHandleSideWidth * 1.5 - barBounds.x) / (barBounds.width - ssbHandleSideWidth * 2);
		f = Math.min(1, lowValue + maxRange, Math.max(0, lowValue + minRange, f));

		onChange({ highValue: f, lowValue });

	}, [draggingRight, rightCenter, lowValue, minRange, maxRange, onChange]);

	useEffect(() => {
		if (!draggingHandle) return;
		if (!barRef || !barRef.current || !handleRef || !handleRef.current || !handleCenter) return;
		const barBounds = barRef.current.getBoundingClientRect();
		const handleBounds = handleRef.current.getBoundingClientRect();

		let range = highValue - lowValue
		let f = (range >= 1) ? 0.5 : (handleCenter.x - handleBounds.width * 0.5 - barBounds.x) / (barBounds.width - handleBounds.width);
		f = Math.max(0, Math.min(1, f));

		onChange({
			lowValue: range * 0 + f * (1 - range),
			highValue: range * 1 + f * (1 - range),
		});

	}, [draggingHandle, handleCenter, lowValue, highValue, onChange]);


	
	return <Bar ref={barRef}>

		<Handle
			ref={handleRef}
			//onMouseDown={onMouseDown}
			lowValue={lowValue}
			highValue={highValue}
		>
			<DragHandle ref={scrollRef} />
			<ScaleHandle ref={leftRef} sx={{left: 0}} />
			<ScaleHandle ref={rightRef} sx={{right: 0}} />
		</Handle>

	</Bar>

}

const Bar = forwardRef(function({ children }, ref) {
	return <Box 
		ref={ref}
		sx={{
			position: 'relative',
			background: 'white',
			height: ssbHeight,
			boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.06)',
			userSelect: 'none',
		}}
	>
		{children}
	</Box>
})

const Handle = forwardRef(function({ onMouseDown, lowValue, highValue, children }, ref) {
	let handleWidthCalc = '' + ssbHandleSideWidth * 2 + 'px + (100% - ' + ssbHandleSideWidth * 2 + 'px) * ' + (highValue - lowValue);
	let handleXCalc = (lowValue === 0) ? '0' : '(100% - (' + handleWidthCalc + ')) * ' + (lowValue / (1 - (highValue - lowValue)));

	return <Box // Handle
		ref={ref}
		sx={{ 
			position: 'absolute',
			height: ssbHandleHeight,
			width: 'calc(' + handleWidthCalc + ')',
			left: 'calc(' + handleXCalc + ')',
			top: (ssbHeight - ssbHandleHeight) / 2,

			background: '#A4A3A3',
			border: '1px solid #F2F2F2',
			borderRadius: '20px',
		}}
		onMouseDown={onMouseDown}
	>			
		{children}
	</Box>
}) 

const DragHandle = forwardRef(function ({ sx, ...rest }, ref) {
	return <Box ref={ref} sx={{
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: ssbHandleSideWidth,
		right: ssbHandleSideWidth,
		...sx, 
	}} {...rest} >
		<Box sx={{
			position: 'absolute',
			width: 15,
			height: 15,
			top: (ssbInteriorHeight - 15) / 2,
			left: 'calc(50% - 15px / 2)' ,
			borderRadius: '50%',
			border: '1px solid white',
			filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))',
			pointerEvents: 'none',
		}} />
	</Box>
});

const ScaleHandle = forwardRef(function ({ sx, ...rest }, ref) {
	return <Box ref={ref} sx={{
		position: 'absolute',
		top: 0,
		bottom: 0,
		width: ssbHandleSideWidth,
		...sx, 
	}} {...rest} >
		<VerticalLine sx={{left: 17}} />
		<VerticalLine sx={{left: 25}} />
		<VerticalLine sx={{left: 33}} />
	</Box>
})

function VerticalLine({ sx }) {
	return <Box sx={{
		position: 'absolute',
		top: 6,
		bottom: 6,
		borderLeft: '1px solid white',
		boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
		pointerEvents: 'none',
		...sx, 
	}} />

}