// When a section needs padding on mobile but not desktop

import { Box } from "@mui/system";
import LayoutContext from "contexts/LayoutContext";
import { useContext } from "react";

export default function MobilePadding({ children, sx, ...props }) {

	const { wideLayout } = useContext(LayoutContext);

	return <Box {...props} sx={{...sx}} px={wideLayout ? 0 : 2}>
		{children}
	</Box>
}