import { Paper, TableContainer, TablePagination } from "@mui/material";
import { useState } from "react";
import SortableTableBase from "./SortableTableBase";

export default function PaginatedTable({ columns, rows, stickyHeader, rowsPerPageOptions, sx, ...props }) {

	if (!rowsPerPageOptions) rowsPerPageOptions = [5, 20, 100];
	
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

	// Pass in firstRow and lastRow so slicing can happen after sorting
	//let slicedRows = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

	return <Paper sx={{ width: '100%', marginBottom: 2, ...sx }} {...props}>
		<TableContainer>
			<SortableTableBase
				stickyHeader={stickyHeader}
				columns={columns}
				rows={rows}
				firstRow={page * rowsPerPage}
				lastRow={(page + 1) * rowsPerPage} 
			/>
		</TableContainer>
		<TablePagination
			rowsPerPageOptions={rowsPerPageOptions}
			component="div"
			count={rows.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={(event, newPage) => {
				setPage(newPage);
			}}
			onRowsPerPageChange={(event) => {
				setRowsPerPage(+event.target.value);
				setPage(0);
			}}
		/>
	</Paper>

}