import { PeopleAltOutlined } from "@mui/icons-material";
import { Autocomplete, CircularProgress, IconButton, MenuItem, Select, TextField } from "@mui/material";
import API from "API";
import FlexBox from "components/layout/FlexBox";
import UserContext from "contexts/UserContext";
import { useGet } from "hooks/useAPI";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";


export default function ClientSelect({ collapsed, sx, ...props }) {

	const navigate = useNavigate();

	const { user, selectClient } = useContext(UserContext);

	const [clientsData] = useGet(API.getClients(user));
	const { clients } = clientsData || {};

	const [selectOpen, setSelectOpen] = useState(false);
	const [autocompleteFocus, setAutocompleteFocus] = useState(false);


	var clientOptions = [];
	var selectedClientOption = null;
	if (clients) {
		clients.forEach((client) => {
			let option = { label: client.name, id_client: client.id_client };
			if (user.id_client === client.id_client) selectedClientOption = option;
			clientOptions.push(option);
		})
	}

	return <FlexBox justify="center" sx={{ ...sx }} {...props} >
	
		{ clients ? <>
		
			{ collapsed ? <>

				<FlexBox center sx={{height: '56px'}}>
					<IconButton
						sx={{width: '34px', height: '34px'}}
						onClick={() => setSelectOpen(!selectOpen)}
					>
						<PeopleAltOutlined />
					</IconButton>
					<Select
						sx={{width: 0, visibility: 'hidden'}}
						value={user.id_client || ""}
						open={selectOpen}
						onOpen={() => setSelectOpen(true)}
						onClose={() => setSelectOpen(false)}
						onChange={(event) => { selectClient(event.target.value); }}
					>
						{ clientOptions.map((option) => {
							return <MenuItem key={option.id_client} value={option.id_client}>{option.label}</MenuItem>
						})}
					</Select>
				</FlexBox>

			</> : <>

				<Autocomplete
					fullWidth
					options={clientOptions}
					value={selectedClientOption}
					onFocus={() => setAutocompleteFocus(true)}
					onBlur={() => setAutocompleteFocus(false)}
					renderInput={(params) => {
						return <TextField 
							{...params}
							label={ (selectedClientOption || autocompleteFocus) ? null : "Client" }
						/>
					}}
					onChange={(event, newValue) => {
						selectClient(newValue ? newValue.id_client : null);
						if (newValue) navigate("/client/" + newValue.id_client);					
					}}
				/>

			</>}

		</> : <>

			<CircularProgress color="inherit" sx={{padding: '8px'}}/>

		</>}
		
	</FlexBox>


}