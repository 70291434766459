import { useTheme } from "@emotion/react"
import { Grid, Paper, Stack, Typography } from "@mui/material"
import FlexBox from "components/layout/FlexBox"
import MobilePadding from "components/layout/MobilePadding"
import FacilityCapacityBar from "components/modules/FacilityCapacityBar"
import LayoutContext from "contexts/LayoutContext"
import StaticDataContext from "contexts/StaticDataContext"
import { useContext } from "react"
import { calculateFacilityTotals, formatMoney, orderFacilitys } from "Utils"

export default function ClientTotals({ facilitys }) {

	const staticData = useContext(StaticDataContext);

	const theme = useTheme();
	const { wideLayout } = useContext(LayoutContext);

	const { highestCapacity, utilised, quoted, limit } = calculateFacilityTotals(facilitys, staticData);

	let orderedFacilitys = orderFacilitys(facilitys);

	return <MobilePadding mb={4}>
		<Grid container columnSpacing={2} rowSpacing={1} justifyContent="center" mt={3} mb={4}>
			<Grid item xs={12} md={4}>
				<Paper>
					<FlexBox column center>
						<Typography variant="grandtotal" mt={2}>{formatMoney(utilised, 2)}</Typography>
						<Typography variant="h5" mb={2}>Utilised</Typography>
					</FlexBox>
				</Paper>
			</Grid>
			<Grid item xs={12} md={4}>
				<Paper>
					<FlexBox column center>
						<Typography variant="grandtotal" mt={2}>{formatMoney(quoted, 2)}</Typography>
						<Typography variant="h5" mb={2}>Quotes and applications</Typography>
					</FlexBox>
				</Paper>
			</Grid>
			<Grid item xs={12} md={4}>
				<Paper>
					<FlexBox column center>
						<Typography variant="grandtotal" mt={2}>{formatMoney(limit, 2)}</Typography>
						<Typography variant="h5" mb={2}>Total capacity</Typography>
					</FlexBox>
				</Paper>
			</Grid>
		</Grid>
		<Stack sx={{width: '100%'}} spacing={2}>
			{ orderedFacilitys.map((facility, index) => {
				return <FacilityCapacityBar key={facility.id_facility} variant={wideLayout ? "wide" : null} facility={facility} highestCapacity={highestCapacity} facilityColor={theme.bondColors[index % theme.bondColors.length]}/>
			})}
		</Stack>
	</MobilePadding>
}