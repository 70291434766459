import React, {
	useState,
	useContext,
	useEffect,
	useCallback,
} from "react";
import LoginForm from 'components/modules/login/LoginForm';
import NewPasswordForm from 'components/modules/login/NewPasswordForm';
import MfaSetupForm from 'components/modules/login/MfaSetupForm';
import MfaForm from 'components/modules/login/MfaForm';
import FormError from 'components/common/FormError';
import Auth from "@aws-amplify/auth";
import UserContext from "contexts/UserContext";
import { Card, CardContent } from "@mui/material";
import CentreWrapper from "components/layout/CentreWrapper";
import CentreLoader from "components/common/CentreLoader";


export default function LoginPage({ alert: initialAlert }) {

	const { onLoggedIn } = useContext(UserContext);

	const [loading, setLoading] = useState(true);
	const [cognitoUser, setCognitoUser] = useState(null);
	const [challenge, setChallenge] = useState(null);
	const [challengeParam, setChallengeParam] = useState(null);
	const [restartMessage, setRestartMessage] = useState(null);

	const handleCognitoUserUpdate = useCallback((updatedUser) => {
		//console.log("Update user", updatedUser, "Challenge", updatedUser.challenge);
		setCognitoUser(updatedUser);
		setRestartMessage(null);
		setChallenge(updatedUser.challengeName);
		setChallengeParam(updatedUser.challengeParam);
		setLoading(false);
		if (updatedUser.signInUserSession) {
			//console.log("Login loop complete!");
			onLoggedIn(updatedUser);
		}
	}, [onLoggedIn]);

	function handleRestart(message) {
		setRestartMessage(message);
	}

	useEffect(() => {
		let isSubscribed = true;

		async function fetchCognitoUser() {
			if (isSubscribed) {
				try {
					const cognitoUser = await Auth.currentUserPoolUser();
					//console.log("Stored user", cognitoUser);
					handleCognitoUserUpdate(cognitoUser);	
				} catch(error) {
					setLoading(false);
					console.log("Not currently logged in", error);
					// Not currently logged in
				}
			}
		}
		fetchCognitoUser();
		// TODO: Does this need a spinner?

		return () => { isSubscribed = false; }
	}, [handleCognitoUserUpdate]);

	useEffect(() => {
		if (challenge === 'SELECT_MFA_TYPE') {
			cognitoUser.sendMFASelectionAnswer('SMS_MFA', {
				mfaRequired: (challengeName, challengeParameters) => {
					setChallenge('SMS_MFA');
					setChallengeParam(null);
				},
				totpRequired: (challengeName, challengeParameters) => { console.log("> totpRequired", challengeName, challengeParameters) },
			});
		}
	}, [challenge, cognitoUser])

	if (loading) {
		return <CentreLoader />;
	}

	return <CentreWrapper>
		<Card sx={{ width: '300px', maxWidth: '100%' }}>
			<CardContent sx={{width: '100%'}}>
				{ getFormContent() }
			</CardContent>
		</Card>
	</CentreWrapper>		

	function getFormContent() {
		if (restartMessage) {
			return <LoginForm onLoggedIn={handleCognitoUserUpdate} restartMessage={restartMessage} />
		} else if (cognitoUser) {
			if (challenge === 'SMS_MFA' || challenge === 'SOFTWARE_TOKEN_MFA') {
				//console.log("MFA_AUTHENTICATION_CODE_REQUIRED")
				return <MfaForm cognitoUser={cognitoUser} challenge={challenge} challengeParam={challengeParam} onMfaConfirmed={handleCognitoUserUpdate} onMfaTimeout={handleRestart} />
			} else if (challenge === 'MFA_SETUP') {
				//console.log("MFA_SETUP_REQUIRED")
				return <MfaSetupForm cognitoUser={cognitoUser} onMfaSetup={handleCognitoUserUpdate} />
			} else if (challenge === 'NEW_PASSWORD_REQUIRED') {
				//console.log("NEW_PASSWORD_REQUIRED")
				return <NewPasswordForm cognitoUser={cognitoUser} onPasswordSet={handleCognitoUserUpdate} />
			} else if (challenge === 'SELECT_MFA_TYPE') {
				// Handled in useEffect
			} else {
				//console.log("Fetch user data now?", cognitoUser);
				return <FormError>Something went wrong signing in</FormError>
			}
		} else {
			return <LoginForm onLoggedIn={handleCognitoUserUpdate} />
		}
	}

};
