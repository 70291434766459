import React, {
	useState
} from "react";
import { Auth } from "aws-amplify";
import useInput from "hooks/useInput";
import FormError from 'components/common/FormError';
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";

export default function NewPasswordForm({ cognitoUser, onPasswordSet }) {
	
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(null);

	const { value: password, bind: bindPassword } = useInput("", true);

	const handleSubmit = async(event) => {
		event.preventDefault();

		setLoading(true);

		//const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
		// You need to get the new password and required attributes from the UI inputs
		// and then trigger the following function with a button click
		// For example, the email and phone_number are required attributes
		//const {username, email, phone_number} = getInfoFromUserInput();
		//const loggedUser = await Auth.completeNewPassword(
		//    user,              // the Cognito User Object
		//    newPassword,       // the new password
		//    // OPTIONAL, the required attributes
		//    {
		//        email,
		//        phone_number,
		//    }
		//);
		try {
			const updatedUser = await Auth.completeNewPassword(cognitoUser, (password || "").trim());
			setLoading(false);
			onPasswordSet(updatedUser);
		} catch(error) {
			setLoading(false);
			if (error && error.message) {
				setFormError(error.message);
			} else {
				setFormError("Failed to submit password");
			}
		}

	};		

	return (
		<form onSubmit={ handleSubmit }>
			<Stack spacing={2}>
				<Typography variant="h6">Set password</Typography>
				<TextField label="Password" type="password" {...bindPassword} />
				<Button variant="contained" size="large" type="submit" disabled={loading} >
					{loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
					Set Password
				</Button>
				{ formError &&
					<FormError>{formError}</FormError>
				}
			</Stack>
		</form>
	);
};

