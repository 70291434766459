import { ENV } from "Environment";
import UserContext from "contexts/UserContext";
import { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import CacheIterationContext from "contexts/CacheIterationContext";


export function useFileGet(route) {
	const { data, loading, error, execute } = useAPI(route, "GET", true, true);
	return [execute, data, loading, error];
}
export function useGet(route, manual) {
	const { cacheIteration } = useContext(CacheIterationContext);
	if (route) {
		if (route.indexOf("?") >= 0) route += "&t=" + cacheIteration;
		else route += "?t=" + cacheIteration;
	}

	const { data, loading, error, execute } = useAPI(route, "GET", manual);
	return [data, loading, error, execute];
}
export function usePost(route, shouldIncrementCacheIteration) {
	if (shouldIncrementCacheIteration === undefined) shouldIncrementCacheIteration = true;
	const { data, loading, error, execute } = useAPI(route, "POST", true);
	const { incrementCacheIteration } = useContext(CacheIterationContext);
	const wrappedExecute = (data) => {
		return execute({ data: data }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response.data.data);
				if (shouldIncrementCacheIteration) incrementCacheIteration();
            });
		});
	}
	return [wrappedExecute, loading, error, data];
}
export function usePut(route) {
	const { data, loading, error, execute } = useAPI(route, "PUT", true);
	const { incrementCacheIteration } = useContext(CacheIterationContext);
	const wrappedExecute = (data) => {
		return execute({ data: data }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response.data.data);
				incrementCacheIteration();
            });
		});
	}
	return [wrappedExecute, loading, error, data];
}
export function useDelete(route) {
	const { data, loading, error, execute } = useAPI(route, "DELETE", true);
	const { incrementCacheIteration } = useContext(CacheIterationContext);
	const wrappedExecute = (data) => {
		return execute({ data: data }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response.data.data);
				incrementCacheIteration();
            });
		});
	}
	return [wrappedExecute, loading, error, data];
}
export function useUpload(route) {
	const { data, loading, error, execute } = useAPI(route, "POST", true);
	const { incrementCacheIteration } = useContext(CacheIterationContext);
	const wrappedExecute = (formData) => {
		return execute({ data: formData }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response.data.data);
				incrementCacheIteration();
            });
		});
	}
	return [wrappedExecute, loading, error, data];
}

export function useAPI(route, method, manual, isFile) {

	const { user } = useContext(UserContext);
	const [headers, setHeaders] = useState(null);

	useEffect(() => {
		if (user && user.getToken && !headers) {
			user.getToken().then((token) => {
				setHeaders({
					'Authorization': 'Bearer ' + token,
					//'Access-Control-Allow-Origin': '*',
				});	
			}).catch((error) => {
				console.log("Get token error", error);
			});
		}
	}, [user, headers, setHeaders]);

	useEffect(() => {
		// May be some time between route changes, refresh token when it does
		setHeaders(null);
	}, [route])

	const [{ data, loading, error}, execute] = useAxios(headers ? {
		url: ENV.apiBase + route,
		method: method,
		headers: headers,
		responseType: isFile ? 'arraybuffer' : null,
	} : null, {
		manual: manual || !route || !headers,
	});

	function wrappedExecute(data) {
		return new Promise((resolve, reject) => {
			user.getToken().then((token) => {
				setHeaders({
					'Authorization': 'Bearer ' + token,
					//'Access-Control-Allow-Origin': '*',
				});	
				if (manual) {
					setTimeout(() => {
						execute(data).then((data) => resolve(data)).catch((error) => reject(error));
					}, 1);
				}
			}).catch((error) => {
				console.log("Get token error", error);
			});
		})
	}

	return {
		data: isFile ? data : (data ? data.data : null),
		loading: loading,
		error: error,
		execute: wrappedExecute,
	};


}


