import { useContext, useEffect, useRef, useState } from "react";
import StaticDataContext from "contexts/StaticDataContext";
import { useDelete, useGet, usePost, usePut, useUpload } from "hooks/useAPI";
import API from "API";
import PageTitle from "components/common/PageTitle";
import BondStatus from "components/common/BondStatus";
import { formatMoney, formatNumber, searchByField, formatPercentage, isBlank, checkFormValidity } from "Utils";
import FlexBox from "components/layout/FlexBox";
import SectionTitle from "components/common/SectionTitle";
import SectionCard from "components/common/SectionCard";
import { Alert, Button, Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, IconButton, MenuItem, Stack, TextField, Typography } from "@mui/material";
import DisplayField from "components/common/DisplayField";
import DaysRemaining from "components/modules/DaysRemaining";
import DatePicker from "components/common/DatePicker";
import { Box } from "@mui/system";
import { BOND_STATUS_APPLIED, BOND_STATUS_EXTENDED, BOND_STATUS_ISSUED, BOND_STATUS_QUOTE, calculateBondDays, calculateBondGST, calculateBondStatusUsage, checkBondFronted, clientSelfIndemnifierId, doBondCalculations, missingHistoricChargeText, statusId, statusName } from "BondFunctions";
import moment from "moment";
import CentreLoader from "components/common/CentreLoader";
import BondTotals from "components/modules/BondTotals";
import LayoutContext from "contexts/LayoutContext";
import { useQuery } from "hooks/useQuery";
import NullableTextField from "components/common/NullableTextField";
import cloneDeep from 'lodash/cloneDeep';
import SaveDrawer from "components/modules/SaveDrawer";
import BondHistory from "components/modules/BondHistory";
import { useNavigate } from "react-router";
import RelatedBonds from "components/modules/RelatedBonds";
import CenteredModal from "components/layout/CentredModal";
import BooleanSelect from "components/common/BooleanSelect";
import { ContentCopy } from "@mui/icons-material";
import Dialog from "components/modules/Dialog";
import ApplicationPDFForm from "components/modules/pdf/ApplicationPDFForm";
import { DollarTextField } from "components/common/FormattedTextField";
import BondFiles from "components/modules/BondFiles";
import UserContext from "contexts/UserContext";
import { useDetectClient } from "hooks/useDetectClient";
import CardTitle from "components/common/CardTitle";
import { Link } from "react-router-dom";


export function AdminBondDetailPage({ extensionMode }) {
	const query = useQuery();
	const id_bond = query.id;

	const { user } = useContext(UserContext);

	const staticData = useContext(StaticDataContext);

	const navigate = useNavigate();

	const [bondData, bondDataLoading] = useGet(API.getBond(user, id_bond));
	const { bond: downloadedBond, bond_history, bond_related, bond_return, bond_files, project } = (bondData || {});
	const [bond, setBond] = useState(downloadedBond);	

	const [applicationData, applicationDataLoading] = useGet(API.getBondApplication(user, id_bond));
	const application = (applicationData && applicationData.bond_application) ?? null;

	const [clientData] = useGet(bond ? API.getClient(user, bond.id_client) : null);
	const { client, facilitys, indemnifiers } = (clientData || {});

	const [revertTarget, setRevertTarget] = useState(bondData && bondData.bond);

	const [changed, setChanged] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [putBond] = usePut(API.putBond(user, id_bond));
	const [postBond] = usePost(API.postBond(user));
	const [postApplication] = usePost(API.postBondApplication(user, id_bond));
	const [postLodgeBondReturn] = usePost(API.postBondReturn(user, id_bond));
	const [deleteBond] = useDelete(API.deleteBond(user, id_bond));
	
	const [postFileUpload] = useUpload(API.uploadBondFile(user, id_bond));
	
	const formRef = useRef();

	useDetectClient(bond && bond.id_client);


	useEffect(() => {
		if (staticData && !bondDataLoading && downloadedBond && (!bond || downloadedBond.id_bond !== bond.id_bond)) {
			if (extensionMode) downloadedBond.id_bond_status = statusId(BOND_STATUS_ISSUED, staticData);
			setBond(downloadedBond);
			setRevertTarget(downloadedBond);
		}
	}, [extensionMode, staticData, bondData, bondDataLoading, downloadedBond, bond, setBond, setRevertTarget]);

	function revert() {
		setBond(cloneDeep(revertTarget));
		setChanged(false);
	}

	function onBondUpdated(updatedBond) {
		setBond(updatedBond);
		setChanged(true);
	}


	function extendBond(event) {
		if (checkFormValidity(formRef.current)) {
			setSubmitting(true);
			let clone = cloneDeep(bond);
			clone.id_bond_status = statusId(BOND_STATUS_EXTENDED, staticData);
			clone.id_bond_history = clone.id_bond;
			putBond({
				bond: clone,
			}).then((responseData) => {
				setSubmitting(false);
				setRevertTarget(cloneDeep(bond));
				setChanged(false);

				// Navigate to url to create extension with issued status
				const newId = responseData.bond ? responseData.bond.id_bond : null;
				if (!isBlank(newId)) navigate("/bond/extend/" + newId);

			}).catch((error) => {console.log("Put bond (extend) error", error)})
		}
	}

	function saveExtension(event) {
		saveRevision(event);
	}

	function saveEdit(event) {
		if (checkFormValidity(formRef.current)) {
			setSubmitting(true);
			let clone = cloneDeep(bond);
			clone.id_bond_history = clone.id_bond;
			if (clone.id_indemnifier === clientSelfIndemnifierId) clone.id_indemnifier = null;
			putBond({
				bond: clone,
			}).then((responseData) => {
				setSubmitting(false);
				setRevertTarget(cloneDeep(bond));
				setChanged(false);

				// Navigate to newly created bond's url
				const newId = responseData.bond ? responseData.bond.id_bond : null;
				if (!isBlank(newId)) navigate("/bond/" + newId + "/detail");

			}).catch((error) => {console.log("Put bond error", error)})
		}
	}

	function saveRevision(event) {
		if (checkFormValidity(formRef.current)) {
			setSubmitting(true);
			let clone = cloneDeep(bond);
			clone.id_bond_history = clone.id_bond;
			if (clone.id_indemnifier === clientSelfIndemnifierId) clone.id_indemnifier = null;
			postBond({
				bond: clone,
			}).then((responseData) => {
				setSubmitting(false);
				setRevertTarget(cloneDeep(bond));
				setChanged(false);

				// Navigate to newly created bond's url
				const newId = (responseData.bonds && responseData.bonds.length > 0) ? responseData.bonds[0].id_bond : null;
				if (!isBlank(newId)) navigate("/bond/" + newId + "/detail");

			}).catch((error) => {console.log("Post bond error", error)})
		}
	}

	return <BondDetailPage extensionMode={extensionMode} adminMode={true} undercoverMode={false} bond={bond} revertTarget={revertTarget} bond_history={bond_history} bond_related={bond_related} bond_return={bond_return} bond_files={bond_files} project={project} client={client} facilitys={facilitys} indemnifiers={indemnifiers}
		application={application} applicationLoading={applicationDataLoading}
		onBondUpdated={onBondUpdated} changed={changed} submitting={submitting} saveEdit={saveEdit} saveRevision={saveRevision} revert={revert} extendBond={extendBond} saveExtension={saveExtension} formRef={formRef}
		postApplication={postApplication} postLodgeBondReturn={postLodgeBondReturn} deleteBond={deleteBond} postFileUpload={postFileUpload}
	/>
}

export function UndercoverBondDetailPage() {
	const query = useQuery();
	const id_bond = query.id;

	const { user } = useContext(UserContext);

	const [bondData] = useGet(API.getBond(user, id_bond));
	const { bond, bond_history, bond_related, bond_return, bond_files, project } = (bondData || {});

	const [applicationData, applicationDataLoading] = useGet(API.getBondApplication(user, id_bond));
	const application = (applicationData && applicationData.bond_application) ?? null;

	useDetectClient(bond && bond.id_client);

	const [clientData] = useGet(bond ? API.getClient(user, bond.id_client) : null);
	const { client, facilitys, indemnifiers } = (clientData || {});

	const [postApplication] = usePost(API.postBondApplication(user, id_bond));
	const [postLodgeBondReturn] = usePost(API.postBondReturn(user, id_bond));
	const [deleteBond] = useDelete(API.deleteBond(user, id_bond));

	return <BondDetailPage adminMode={false} undercoverMode={true} bond={bond} bond_history={bond_history} bond_related={bond_related} bond_return={bond_return} bond_files={bond_files} project={project} client={client} facilitys={facilitys} indemnifiers={indemnifiers}
		application={application} applicationLoading={applicationDataLoading}
		postApplication={postApplication} postLodgeBondReturn={postLodgeBondReturn} deleteBond={deleteBond}
	/>
}

export function ClientBondDetailPage() {
	const query = useQuery();
	const id_bond = query.id;

	const { user } = useContext(UserContext);

	const [bondData] = useGet(API.getBond(user, id_bond));
	const { bond, bond_history, bond_related, bond_return, bond_files, project } = (bondData || {});

	const [applicationData, applicationDataLoading] = useGet(API.getBondApplication(user, id_bond));
	const application = (applicationData && applicationData.bond_application) ?? null;

	useDetectClient(bond && bond.id_client);

	const [clientData] = useGet(bond ? API.getClient(user, bond.id_client) : null);
	const { client, facilitys, indemnifiers } = (clientData || {});

	const [postApplication] = usePost(API.postBondApplication(user, id_bond));
	const [postLodgeBondReturn] = usePost(API.postBondReturn(user, id_bond));
	const [postRequestExtension] = usePost(API.postBondExtensionRequest(user, id_bond));
	const [deleteBond] = useDelete(API.deleteBond(user, id_bond));

	return <BondDetailPage adminMode={false} undercoverMode={false} bond={bond} bond_history={bond_history} bond_related={bond_related} bond_return={bond_return} bond_files={bond_files} project={project} client={client} facilitys={facilitys} indemnifiers={indemnifiers}
		application={application} applicationLoading={applicationDataLoading}
		postApplication={postApplication} postLodgeBondReturn={postLodgeBondReturn} postRequestExtension={postRequestExtension} deleteBond={deleteBond}
	/>

}



function BondDetailPage({
	extensionMode, adminMode, undercoverMode, revertTarget,
	bond, bond_history, bond_related, bond_return, bond_files,
	application, applicationLoading,
	project, client, indemnifiers, facilitys,
	onBondUpdated,
	changed, submitting, saveEdit, saveRevision, revert,
	formRef,
	postApplication, postLodgeBondReturn, postRequestExtension, deleteBond, extendBond, saveExtension, postFileUpload
}) {

	const { user } = useContext(UserContext);

	const staticData = useContext(StaticDataContext);

	const navigate = useNavigate();

	const { wideLayout } = useContext(LayoutContext);

	const [requestExtensionModalOpen, setRequestExtensionModalOpen] = useState(false);
	const [lodgeBondReturnModalOpen, setLodgeBondReturnModalOpen] = useState(false);
	const [viewLodgedBondReturnModalOpen, setViewLodgedBondReturnModalOpen] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showPDFForm, setShowPDFForm] = useState(application !== null);
	const [showSaveConfirm, setShowSaveConfirm] = useState(false);

	const pdfScrollRef = useRef();

	useEffect(() => {
		setShowSaveConfirm(false);
	}, [changed, setShowSaveConfirm])

	useEffect(() => {
		if (application) setShowPDFForm(true);
	}, [application, setShowPDFForm]);

	if (!bond || !bond_history || applicationLoading || !client || !indemnifiers || !facilitys) return <CentreLoader />
	
	calculateBondGST(bond);


	function updateBond(updatedFields) {
		var updatedBond = { ...bond, ...updatedFields };
		doBondCalculations(updatedBond, searchByField(facilitys, 'id_facility', updatedBond.id_facility), staticData);
		onBondUpdated(updatedBond);
	}

	function onDelete() {
		deleteBond({
			bond: { id_bond: bond.id_bond, }
		}).then((responseData) => {
			navigate("/");
		}).catch((error) => {console.log("Delete bond error", error)})
	}

	function submitFormData(formData) {
		if (!postApplication) return;
		const culledData = { };
		for (var key in formData) {
			let value = formData[key];
			if (key === "signature") continue;
			if (value === null || value === undefined) continue;
			if (value instanceof Array) continue;
			culledData[key] = value;			
		}
		// remove array
		postApplication({
			bond_application: culledData,
		});
	}


	let indemnifier = (client && bond.id_indemnifier && (bond.id_indemnifier !== clientSelfIndemnifierId)) ? searchByField(indemnifiers, 'id_indemnifier', bond.id_indemnifier) : null;
	let facility = client ? searchByField(facilitys, 'id_facility', bond.id_facility) : null;
	let frontingBank = staticData ? searchByField(staticData.banks, 'id_bank', bond.id_bank) : null;
	let bondType = staticData ? searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type) : null;
	let bondStatus = staticData ? searchByField(staticData.bond_statuses, 'id_bond_status', bond.id_bond_status) : null;
	let bondTypeFacilityRate = (staticData && bondType && facility) ? searchByField(facility.bond_type_facility_rates, 'id_bond_type', bondType.id_bond_type) : null;

	if (!staticData || !facility || !bondType) return <CentreLoader />

	// Filter down to only facilities applicable to the selected indemnifier
	var availableFacilitys = client ? facilitys.concat() : null;
	if (availableFacilitys && indemnifier) {
		for (var i = availableFacilitys.length - 1; i >= 0; i--) {
			var f1 = availableFacilitys[i];
			var found = false;
			for (var j = indemnifier.facility_indemnifiers.length - 1; j >= 0; j--) {
				var f2 = indemnifier.facility_indemnifiers[j];
				if (f1.id_facility === f2.id_facility) found = true;
			}
			if (!found) availableFacilitys.splice(i, 1);
		}
	}

	calculateBondDays(bond);
	checkBondFronted(bond, staticData);

	const daysString = isNaN(bond.days) ? "" : formatNumber(bond.days) + " " + (bond.days === 1 ? "day" : "days");

	const isMostRecent = (bond_history.length === 0) || ((revertTarget || bond).id_bond === bond_history[bond_history.length - 1].id_bond);

	const canExtendBond = (adminMode && ((statusName((revertTarget || bond).id_bond_status, staticData) === BOND_STATUS_ISSUED) || (statusName((revertTarget || bond).id_bond_status, staticData) === BOND_STATUS_EXTENDED)));
	const canReturnBond = !user.isDataroom && (statusName((revertTarget || bond).id_bond_status, staticData) !== BOND_STATUS_QUOTE) && (statusName((revertTarget || bond).id_bond_status, staticData) !== BOND_STATUS_APPLIED);
	const canDeleteBond = !user.isDataroom && (adminMode || (statusName((revertTarget || bond).id_bond_status, staticData) === BOND_STATUS_QUOTE));
	//const canFillPDF = (adminMode && (bond.issuer_name === "Assetinsure" || bond.issuer_name === "Swiss Re"));
	//const canFillPDF = adminMode;
	const canFillPDF = !user.isDataroom;

	const { pending, active } = calculateBondStatusUsage(facility.bond_status_totals, staticData.bond_statuses);
	const overCapacity = pending + active > facility.limit;
	

	function scrollToPDFForm() {
		setTimeout(() => {
			window.scrollTo({ behavior: 'smooth', top: pdfScrollRef.current.offsetTop - 64 })
		}, 1);
	}


	function copyDetailsToClipboard() {
		let output = "";
		output += "Client\n" + (client.name || "") + "\n";
		output += "Project\n" + (bond.project || "") + "\n";
		output += "Principal\n" + (bond.principal || "") + "\n";
		output += "Issuer\n" + (bond.issuer_name || "") + "\n";
		output += "Indemnifier\n" + (indemnifier ? indemnifier.name : client.name) + "\n";
		if (bond.fronted) output += "Fronting Bank\n" + (frontingBank ? frontingBank.name : "") + "\n";
		output += "Bond Type\n" + (bondType ? bondType.name : "") + "\n";
		output += "Bond Value\n" + ((bond.face_value !== undefined) ? formatMoney(bond.face_value, 2) : "-") + "\n";
		output += "Invoice Number\n" + (bond.invoice || "-") + "\n";
		output += "Reference\n" + (bond.reference || "") + "\n";
		output += "Status\n" + (bondStatus ? bondStatus.name : "") + "\n";
		output += "Start Date\n" + moment(bond.date_start).format("DD-MMM-YYYY") + "\n";
		output += "End Date\n" + moment(bond.date_finish).format("DD-MMM-YYYY") + "\n";
		output += "Days\n" + daysString + "\n";
		output += "Expires\n" + (bond.expires ? "Yes" : "No") + "\n";

		const bondCharge = isBlank(bond.bond_fee_override) ? bond.calculated_bond_fee : bond.bond_fee_override;
		const minimumBondCharge = facility ? facility.minimum_bond_charge : 0;
		const showMinimumBondChargeWarning = !isBlank(bondCharge) && (bondCharge < minimumBondCharge);		

		const frontingCharge = isBlank(bond.fronting_fee_override) ? bond.calculated_fronting_fee : bond.fronting_fee_override;
		const minimumFrontingCharge = frontingBank ? frontingBank.minimum_fronting_charge : 0;
		const showMinimumFrontingChargeWarning = !isBlank(frontingCharge) && (frontingCharge < minimumFrontingCharge);

		const issuance_fee = !isBlank(bond.issuance_fee_override) ? bond.issuance_fee_override : bond.calculated_issuance_fee;

		output += "Rate\n" + ((bond.rate !== undefined) ? formatPercentage(bond.rate, 2) : "-") + "\n";
		if (bond.fronted) output += "Fronting Rate\n" + ((bond.fronting_rate !== undefined) ? formatPercentage(bond.fronting_rate, 2) : "-") + "\n";

		output += "Bond Fee\n" + ((bondCharge !== undefined) ? formatMoney(bondCharge, 2) : missingHistoricChargeText) + "\n";
		if (showMinimumBondChargeWarning) output += ("This bond has a minimum Bond Fee of " + formatMoney(minimumBondCharge) + "\n");

		if (bond.fronted) {
			output += "Fronting Fee\n" + ((frontingCharge !== undefined) ? formatMoney(frontingCharge, 2) : missingHistoricChargeText) + "\n";
			if (showMinimumFrontingChargeWarning) output += ("This bond has a minimum Fronting Fee of " + formatMoney(minimumFrontingCharge) + "\n");
		}

		output += "Issuance Fee ex GST\n" + ((issuance_fee !== undefined) ? formatMoney(issuance_fee, 2) : missingHistoricChargeText) + "\n";
		output += "Issuance Fee inc GST\n" + ((bond.issuanceFeeIncGST !== undefined) ? formatMoney(bond.issuanceFeeIncGST, 2) : missingHistoricChargeText) + "\n";

		output += "Total\n" + ((bond.total_charge !== undefined) ? formatMoney(bond.total_charge, 2) : missingHistoricChargeText) + "\n";

		navigator.clipboard.writeText(output);
	}

	return <>
		<PageTitle title={
			<FlexBox center>
				<span style={{marginRight: '1rem'}}>
					{ extensionMode ? "Extend bond" : "Bond detail" }
				</span>
				{ !extensionMode && <BondStatus bondStatus={searchByField(staticData.bond_statuses, 'id_bond_status', bond.id_bond_status)}/> }
			</FlexBox>
		} actions={ (!extensionMode && !user.isDataroom) && <>
			{ canFillPDF &&
				<Button variant="outlined" onClick={() => {setShowPDFForm(true); scrollToPDFForm();}}>Application form</Button>
			}
			{ canExtendBond &&
				<Button variant="outlined" color="success" onClick={extendBond}>Extend Bond</Button>
			}
			{ canDeleteBond &&
				<Button variant="outlined" color="error" disabled={!isMostRecent} onClick={() => {setShowDeleteDialog(true)}}>Delete bond</Button>
			}
			<Button variant="outlined" disabled={!postRequestExtension} onClick={() => {setRequestExtensionModalOpen(true)}}>Request extension</Button>
			{ isBlank(bond_return) ?
				<Button variant="outlined" disabled={!postLodgeBondReturn || !canReturnBond} onClick={() => {setLodgeBondReturnModalOpen(true)}}>Lodge bond return</Button>
			:	
				<Button variant="outlined" color="success" onClick={() => {setViewLodgedBondReturnModalOpen(true)}}>View bond return</Button>				
			}
		</>} />			

		<Grid container columnSpacing={2}>
			<Grid item xs={12} md={8} mb={2}>

				<form ref={formRef}>
					<SectionCard mb={2}>
						<SectionTitle title="Details" actions={
							!extensionMode && <IconButton title="Copy to clipboard" onClick={copyDetailsToClipboard} ><ContentCopy/></IconButton>
						}/>

						{ adminMode && !extensionMode && (
							(bond.timestamp_created === bond.timestamp_updated) ? <>
								<Box mb={3}>
									<Typography variant="tertiary">Submitted on {moment(bond.timestamp_created).format("DD/MM/YYYY")} by {bond.contact_name}</Typography>
								</Box>
							</> : (bond.timestamp_created > bond.timestamp_updated) ? <>
								<Box mb={3}>
									<Typography variant="tertiary">Submitted on {moment(bond.timestamp_created).format("DD/MM/YYYY")} by {bond.contact_name}. Last updated by {moment(bond.timestamp_updated).format("DD/MM/YYYY")} by {bond.contact_name}</Typography>
								</Box>
							</> : <>
								<Box mb={3}>
									<Typography variant="tertiary">Last updated by {moment(bond.timestamp_updated).format("DD/MM/YYYY")} by {bond.contact_name}</Typography>
								</Box>
							</>
						)}

						<Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
							<Grid item xs={12} md={6}>
								<DisplayField label="Client" fullWidth value={bond.client_name || ''} />
							</Grid>
							<Grid item xs={12} md={6}>
								{ adminMode ? <NullableTextField label="Invoice Number" type="text" fullWidth value={bond.invoice} onChange={(value) => { updateBond({ invoice: value }); }} />
								: <DisplayField label="Invoice Number" fullWidth value={bond.invoice} />}
							</Grid>
							<Grid item xs={12} md={6}>
								{ adminMode && !extensionMode ?
									<TextField label="Status" fullWidth value={(bondStatus ? bondStatus.id_bond_status : null) || ''} select onChange={(event) => { updateBond({ id_bond_status: event.target.value }); }}>
										{ staticData.bond_statuses.map((bondStatus) => {
											return <MenuItem key={bondStatus.id_bond_status} value={bondStatus.id_bond_status}>{bondStatus.name}</MenuItem>
										})}				
									</TextField>						
								:
									<DisplayField label="Status" fullWidth value={(bondStatus || {}).name || ''} />
								}
							</Grid>
							<Grid item xs={12} md={6}>
								{ adminMode ? <NullableTextField label="Reference" type="text" fullWidth value={bond.reference} onChange={(value) => { updateBond({ reference: value }); }} />
								: <DisplayField label="Reference" fullWidth value={bond.reference} />}
							</Grid>
						</Grid>

						<Divider sx={{marginTop: 3, marginBottom: 3}} />
						
						<Grid container columnSpacing={2} rowSpacing={2} alignItems="center">

							<Grid item xs={12} md={12}>
								{ adminMode ? <NullableTextField label="Project" type="text" fullWidth required value={bond.project} onChange={(value) => { updateBond({ project: value }); }} />
								: <DisplayField label="Project" fullWidth value={bond.project}/> }
							</Grid>
							<Grid item xs={12} md={6}>
								{ adminMode ? <NullableTextField label="Principal" type="text" fullWidth required value={bond.principal} onChange={(value) => { updateBond({ principal: value }); }} />
								: <DisplayField label="Principal" fullWidth value={bond.principal} /> }
							</Grid>

						</Grid>

						<Divider sx={{marginTop: 3, marginBottom: 3}} />
						
						<Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
							<Grid item xs={12} md={12}>
								{ adminMode ? <NullableTextField label="Custodian" type="text" fullWidth value={(bond.custodian_name) || ''} onChange={(value) => { updateBond({ custodian_name: value }); }} />
								: <DisplayField label="Custodian" fullWidth value={(bond.custodian_name)} /> }
							</Grid>
							<Grid item xs={12} md={6}>
								{ adminMode ? <NullableTextField label="Email" type="email" fullWidth value={(bond.custodian_email) || ''} onChange={(value) => { updateBond({ custodian_email: value }); }} />
								: <DisplayField label="Email" fullWidth value={(bond.custodian_email) || ''} /> }
							</Grid>
							<Grid item xs={12} md={6}>
								{ adminMode ? <NullableTextField label="Number" type="text" fullWidth value={(bond.custodian_phone) || ''} onChange={(value) => { updateBond({ custodian_phone: value }); }} />
								: <DisplayField label="Number" fullWidth value={(bond.custodian_phone) || ''} /> }
							</Grid>
						</Grid>
					</SectionCard>

					<SectionCard>

						<DaysRemaining start={bond.date_start} end={bond.date_finish} />						

						<Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
							{ adminMode ?
								<Grid item xs={12} md={6}>
									<DatePicker
										value={bond.timestamp}
										label={"Effective date"}
										renderInput={(params) => <TextField {...params} fullWidth={true} />}
										onChange={(value) => { updateBond({ timestamp: value }); }}
									/>
								</Grid>
							: null }															
							<Grid item xs={12} md={8}>
								{ adminMode ? 
									<TextField label="Bond Type" fullWidth required value={(bondType ? bondType.id_bond_type : null) || ''} select onChange={(event) => { updateBond({ id_bond_type: event.target.value }); }}>
										{ staticData.bond_types.map((bondType) => {
											return <MenuItem key={bondType.id_bond_type} value={bondType.id_bond_type}>{bondType.name}</MenuItem>
										})}									
									</TextField>
								: <DisplayField label="Bond Type" fullWidth value={(searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type) || { }).name || ""} />}
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControlLabel label="Expires" control={
									<Checkbox checked={bond.expires || false} onChange={(event) => {updateBond({ expires: event.target.checked })}} sx={{marginLeft: 1}} />									
								} />
							</Grid>
						</Grid>


						<Divider sx={{marginTop: 3, marginBottom: 3}} />


						<Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
							<Grid item xs={12} md={12}>
								{ adminMode ?
									<DollarTextField label="Bond Value" fullWidth
										value={bond.face_value}
										onChange={(numericValue) => { updateBond({ face_value: numericValue }); }}
									/>
								:
									<DisplayField label="Bond Value" fullWidth value={formatMoney(bond.face_value, 2)} />
								}
							</Grid>
							<Grid item xs={12} md={12}>
								<Stack direction={ wideLayout ? "row" : "column" } alignItems="center" spacing={2}>
									{ adminMode ?
										<DatePicker
											value={bond.date_start}
											label={wideLayout ? null : "Start date"}
											renderInput={(params) => <TextField {...params} fullWidth={!wideLayout} />}
											onChange={(value) => { updateBond({ date_start: value }); }}
										/>
									:
										<DatePicker
											value={bond.date_start}
											label={wideLayout ? null : "Start date"}
											renderInput={(params) => <DisplayField {...params} fullWidth={!wideLayout} />}
											onChange={() => {}}
											disabled
										/>
									}
									{ wideLayout && <Typography>to</Typography> }
									{ adminMode ?
										<DatePicker
											value={bond.date_finish}
											label={wideLayout ? null : "End date"}
											renderInput={(params) => <TextField {...params} fullWidth={!wideLayout} />}
											onChange={(value) => { updateBond({ date_finish: value }); }}
										/>
									:
										<DatePicker
											value={bond.date_finish}
											label={wideLayout ? null : "End date"}
											renderInput={(params) => <DisplayField {...params} fullWidth={!wideLayout} />}
											onChange={() => {}}
											disabled
										/>
									}
									<Typography sx={{whiteSpace:"nowrap", paddingRight: 2, color: (bond.days < 0 ? "red" : null) }}>{daysString}</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} md={12}>
								{ adminMode ? 
									<TextField label="Indemnifier" fullWidth value={(indemnifier ? indemnifier.id_indemnifier : clientSelfIndemnifierId) || ''} select onChange={(event) => {
										updateBond({ id_indemnifier: event.target.value})									
									}}>
										<MenuItem key={clientSelfIndemnifierId} value={clientSelfIndemnifierId}>{client.name}</MenuItem>
										{ indemnifiers.map((indemnifier) => {
											// Check that indemnifier is applicable to the selected facility
											if (!indemnifier.active) return null;
											let foundFacility = false;
											indemnifier.facility_indemnifiers.forEach((facilityIndemnifier) => {
												if (facilityIndemnifier.id_facility === facility.id_facility) foundFacility = true;
											});
											if (!foundFacility) return null;
											return <MenuItem key={indemnifier.id_indemnifier} value={indemnifier.id_indemnifier}>{indemnifier.name}</MenuItem>
										})}									
									</TextField>
								: <DisplayField label="Indemnifier" fullWidth value={
									(bond.id_indemnifier === null) ? client.name
									: (searchByField(indemnifiers, 'id_indemnifier', bond.id_indemnifier) || { }).name || ""
								} />}

							</Grid>
							<Grid item xs={12} md={12}>
								<DisplayField label="Issuer" fullWidth value={
									bond.issuer_name + ((adminMode && bondTypeFacilityRate) ? " - " + formatPercentage(bondTypeFacilityRate.rate) : '')
								} />
							</Grid>
							{ bond.fronted && bond.id_facility && facility &&
								<Grid item xs={12} md={12}>
									<TextField label="Fronting Bank" fullWidth value={bond.id_bank || ''} select onChange={(event) => { updateBond({ id_bank: event.target.value }); }}>
										{ facility.facility_banks.map((facility_bank) => {
											if (!facility_bank.active) return null;
											return <MenuItem key={facility_bank.id_bank} value={facility_bank.id_bank}>{searchByField(staticData.banks, 'id_bank', facility_bank.id_bank).name + (bond.id_duration ? ' - ' + searchByField(facility_bank.facility_bank_duration_rates, 'id_duration', bond.id_duration).rate + '%' : '')}</MenuItem>
										})}		
									</TextField>
								</Grid>
							}												
						</Grid>

						<Divider sx={{my: 4}}/>

						<BondTotals creatingNew={false} bond={bond} updateBond={updateBond} frontingBank={frontingBank} adminMode={adminMode} />

						{ overCapacity &&
							<Alert severity="error" sx={{marginTop: 1}}>Bond capacity exceeded</Alert>
						}


					</SectionCard>					

				</form>


				{ (showPDFForm || application) && 
					<>
						<div ref={pdfScrollRef} />
						<PageTitle title="Bond Application Form" />
						<SectionCard>
							<ApplicationPDFForm storedData={application} bond={bond} relatedBonds={bond_related} client={client} indemnifiers={indemnifiers} staticData={staticData} onClose={() => {setShowPDFForm(false)}} onSubmit={(formData) => {submitFormData(formData)}} />			
						</SectionCard>
					</>
				}


			</Grid>

			<Grid item xs={12} md={4}>
				{ !extensionMode && <Stack spacing={2}>

					{ user.isSubsidiary ? null :
						<Card>		
							<CardContent>
								<CardTitle title="Project"/>
								<Link to={"/project/" + (project ? project.id_project : "add?b=" + bond.id_bond) }>
									<Typography>{(project ? project.name || "Project" : "Create Project")}</Typography>
								</Link>
							</CardContent>
						</Card>
					}

					{ <BondFiles adminMode={adminMode} bond={bond} files={bond_files} postFileUpload={postFileUpload} /> }
					
					<BondHistory undercoverMode={undercoverMode} staticData={staticData} bond={bond} bond_history={bond_history} />
					
					<RelatedBonds undercoverMode={undercoverMode} staticData={staticData} bond_related={bond_related} />
					
					{ adminMode && <Card>		
						<CardContent>		
							{/*<CardTitle title="Comments"/>*/}
							<TextField label="Comments" multiline rows={6} inputProps={{ maxLength: 200 }} fullWidth value={bond.comment || ""} onChange={(event) => {
								let comment = event.target.value;
								if (!comment || comment.length === 0) comment = null;
								updateBond({ comment: comment });
							}} />
							
						</CardContent>		
					</Card> }
				</Stack> }
			</Grid>
		</Grid>
		
		<SaveDrawer open={changed || extensionMode} actions={
			extensionMode ? 
				<Button variant="contained" disabled={submitting} onClick={saveExtension}>Save extension</Button>
			: <>
				<Button variant="contained" disabled={submitting} onClick={() => { setShowSaveConfirm(true) }}>Save</Button>
				<Button variant="outlined" disabled={submitting} onClick={revert}>Cancel</Button>
			</>
		}/>

		{ requestExtensionModalOpen && <RequestExtensionModal bond={bond} postRequestExtension={postRequestExtension} onClose={() => setRequestExtensionModalOpen(false)} /> }
		{ lodgeBondReturnModalOpen && <LodgeBondReturnModal bond={bond} postLodgeBondReturn={postLodgeBondReturn} onClose={() => setLodgeBondReturnModalOpen(false)} /> }
		{ viewLodgedBondReturnModalOpen && <ViewLodgedBondReturnModal bond_return={bond_return} onClose={() => setViewLodgedBondReturnModalOpen(false)} /> }
		{ showDeleteDialog && <Dialog message="Are you sure you would like to delete this bond?" width={400} actions={<>
			<Button variant="contained" color="error" disabled={submitting} onClick={onDelete}>Delete</Button>
			<Button variant="outlined" disabled={submitting} onClick={() => {setShowDeleteDialog(false)}}>Cancel</Button>
		</>}/> }
		{ (showSaveConfirm) && <Dialog message="Are you sure you want to overwrite the current bond?" width={400} actions={<>
			<Button key="ok" variant="outlined" disabled={submitting} onClick={saveEdit}>Ok</Button>
			<Button key="cancel" variant="outlined" disabled={submitting} onClick={() => {setShowSaveConfirm(false)}}>Cancel</Button>
		</>} /> }

	</>

};



function RequestExtensionModal({ bond, postRequestExtension, onClose }) {
	
	const [projectOnTrack, setProjectOnTrack] = useState(null);
	const [issueDisputesRequired, setIssueDisputesRequired] = useState(null);
	const [issueDisputesText, setIssueDisputesText] = useState(null);
	const [extensionReason, setExtensionReason] = useState(null);
	const [covidRequired, setCovidRequired] = useState(null);
	const [covidText, setCovidText] = useState(null);
	const [revisedEndDate, setRevisedEndDate] = useState(null);

	const [submitting, setSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);

	const formRef = useRef();

	function send(event) {
		if (checkFormValidity(formRef.current)) {
			setSubmitting(true);

			postRequestExtension({
				bond_extension_request: {
					id_bond: bond.id_bond,
					project_on_track: projectOnTrack,
					issues_disputes: issueDisputesText || "No",
					extension_reason: extensionReason,
					covid: covidText || "No",
					revised_end_date: moment(revisedEndDate).format("DD-MM-YYYY"),
				}				
			}).then((responseData) => {
				setSubmitting(false);
				setSuccess(true);
			}).catch((error) => {console.log("Post request extension error", error)})
		}
	}

	return <CenteredModal width={724} headerContent={
		<SectionTitle title="Request Extension" sx={{marginBottom: 0}} actions={<>
				<Button disabled={submitting || success} variant="contained" onClick={() => {send();}}>Submit</Button>
				<Button variant="outlined" onClick={() => {onClose()}}>Close</Button>
		</> }/>
	}>

		{ (success) &&
			<Box mb={2}>
				<Alert>You will receive a copy of the submission via email</Alert>
			</Box>
		}

		<form ref={formRef}>
			<Stack spacing={2} mt={1} mb={2}>
				<Box>
					<Typography mb={1}>Is the project on program and budget?</Typography>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={6}>
							<BooleanSelect fullWidth disabled={(submitting || success)} required value={projectOnTrack} setValue={setProjectOnTrack} />
						</Grid>
					</Grid>
				</Box>
				<Box>
					<Typography mb={1}>Are there any issues or disputes?</Typography>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={12}>
							<Grid item xs={12} md={6}>
								<BooleanSelect fullWidth disabled={(submitting || success)} required value={issueDisputesRequired} setValue={setIssueDisputesRequired} />
							</Grid>
							{ issueDisputesRequired ? 
								<Grid item xs={12} md={12}>
									<NullableTextField fullWidth disabled={(submitting || success)} required multiline minRows={2} value={issueDisputesText} sx={{marginTop: 1}} onChange={(value) => setIssueDisputesText(value)} />
								</Grid>
							: null }
						</Grid>
					</Grid>
				</Box>
				<Box>
					<Typography mb={1}>Brief explanation/reason for extension</Typography>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={12}>
							<NullableTextField fullWidth disabled={(submitting || success)} required multiline minRows={2} value={extensionReason} onChange={(value) => setExtensionReason(value)} />
						</Grid>
					</Grid>
				</Box>
				<Box>
					<Typography mb={1}>Are you experiencing any COVID-19 issues and if so please elaborate on action taken to mitigate risk/loss</Typography>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={6}>
							<BooleanSelect fullWidth disabled={(submitting || success)} required value={covidRequired} setValue={setCovidRequired} />
						</Grid>
						{ covidRequired ? 
							<Grid item xs={12} md={12}>
								<NullableTextField fullWidth disabled={(submitting || success)} required multiline minRows={2} value={covidText} sx={{marginTop: 1}} onChange={(value) => setCovidText(value)} />
							</Grid>
						: null }
					</Grid>
				</Box>
				<Box>
					<Typography mb={1}>Revised end date</Typography>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={6}>
							<DatePicker
								value={revisedEndDate}
								renderInput={(params) => <TextField fullWidth {...params} />}
								onChange={(value) => { setRevisedEndDate(value); }}							
								disabled={(submitting || success)}
								required
							/>
						</Grid>
					</Grid>
				</Box>

			</Stack>
		</form>
		
	</CenteredModal>
}

function LodgeBondReturnModal({ bond, postLodgeBondReturn, onClose }) {
	
	const [sentTo, setSentTo] = useState(null);
	const [dateSent, setDateSent] = useState(null);
	const [address, setAddress] = useState(null);
	const [carrier, setCarrier] = useState(null);
	const [trackingNumber, setTrackingNumber] = useState(null);

	const [submitting, setSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);

	const formRef = useRef();

	function send(event) {		
		if (checkFormValidity(formRef.current)) {
			setSubmitting(true);

			postLodgeBondReturn({
				bond_return: {
					id_bond: bond.id_bond,
					sent_to: sentTo,
					date_sent: dateSent,
					address: address,
					carrier: carrier,
					tracking_number: trackingNumber,
				}
			}).then((responseData) => {
				setSubmitting(false);
				setSuccess(true);
			}).catch((error) => {console.log("Post lodge bond return error", error)})
		}
	}

	return <CenteredModal width={724} headerContent={
		<SectionTitle title="Lodge bond return" sx={{marginBottom: 0}} actions={<>
				<Button disabled={submitting || success} variant="contained" onClick={() => {send();}}>Lodge</Button>
				<Button variant="outlined" onClick={() => {onClose()}}>Close</Button>
		</> }/>
	}>
		
		{ (success) &&
			<Box mb={2}>
				<Alert>Bond return has been lodged</Alert>
			</Box>
		}

		<form ref={formRef}>
			<Grid container columnSpacing={2} rowSpacing={2} mt={1} mb={2}>
				<Grid item xs={12} md={6}>
					<NullableTextField label="Sent to" fullWidth disabled={(submitting || success)} required value={sentTo} onChange={(value) => setSentTo(value)} />
				</Grid>
				<Grid item xs={12} md={6}>
					<DatePicker
						value={dateSent}
						label="Date sent"
						renderInput={(params) => <TextField required {...params} fullWidth /> }
						onChange={(value) => { setDateSent(value); }}
						disabled={(submitting || success)}
						required
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<NullableTextField label="Address" fullWidth disabled={(submitting || success)} required value={address} onChange={(value) => setAddress(value)} />
				</Grid>
				<Grid item xs={12} md={6}>
					<NullableTextField label="Carrier" fullWidth disabled={(submitting || success)} required value={carrier} onChange={(value) => setCarrier(value)} />
				</Grid>
				<Grid item xs={12} md={6}>
					<NullableTextField label="Tracking number" fullWidth disabled={(submitting || success)} required value={trackingNumber} onChange={(value) => setTrackingNumber(value)} />
				</Grid>
			</Grid>
		</form>

	</CenteredModal>
}


function ViewLodgedBondReturnModal({ bond_return, onClose }) {
	
	return <CenteredModal width={724} headerContent={
		<SectionTitle title="Lodged bond return" sx={{marginBottom: 0}} actions={
			<Button variant="outlined" onClick={() => {onClose()}}>Close</Button>
		}/>
	}>
		
		<Typography variant="tertiary">Submitted on {moment(bond_return.timestamp_created).format("DD/MM/YYYY")} by {bond_return.contact_name}</Typography>

		<Grid container columnSpacing={2} rowSpacing={2} mt={1} mb={2}>
			<Grid item xs={12} md={6}>
				<DisplayField label="Sent to" fullWidth value={bond_return.sent_to} />
			</Grid>
			<Grid item xs={12} md={6}>
				<DatePicker
					value={bond_return.date_sent}
					label="Date sent"
					renderInput={(params) => <DisplayField {...params} fullWidth />}
					onChange={(value) => { }}
					disabled
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<DisplayField label="Address" fullWidth value={bond_return.address} />
			</Grid>
			<Grid item xs={12} md={6}>
				<DisplayField label="Carrier" fullWidth value={bond_return.carrier} />
			</Grid>
			<Grid item xs={12} md={6}>
				<DisplayField label="Tracking number" fullWidth value={bond_return.tracking_number} />
			</Grid>
		</Grid>

	</CenteredModal>
}
