import { useTheme } from "@emotion/react";
import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { missingNewChargeText } from "BondFunctions";
import FlexBox from "components/layout/FlexBox";
import { formatMoney, isBlank, searchByField } from "Utils";


export default function BondsSummary({ staticData, facilitys, bonds, sx, ...props }) {

	const theme = useTheme();

	var bondsTotal = 0;
	if (bonds) bonds.forEach((bond) => {
		if (!isBlank(bond.total_charge)) bondsTotal += Math.round(bond.total_charge * 100) / 100;
	});

	return <Card {...props} sx={{ marginBottom: 2, ...sx }}>
		<CardContent>
			
			<Stack direction="column" divider={<Divider />} spacing={2}>
				{ bonds && bonds.map((bond, index) => {
					var facility = facilitys && searchByField(facilitys, 'id_facility', bond.id_facility);					
					var issuer = staticData && facility && searchByField(staticData.issuers, 'id_issuer', facility.id_issuer);
					var bondType = staticData && searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type);
					var bondInfo = issuer && issuer.name ? issuer.name : "";
					if (bondType) {
						let bondTypeLabel = bondType.name.toLowerCase().replace("bond", "").trim();
						if (bondType.fronted) bondTypeLabel = bondTypeLabel.replace("fronted", "").trim() + " fronted"
						if (bondInfo.length > 0) {
							bondInfo = bondInfo + ", " + bondTypeLabel;
						} else {
							bondInfo = bondTypeLabel.charAt(0).toUpperCase() + bondTypeLabel.substr(1);
						}
					}
					var bondColor = theme.bondColors[index % theme.bondColors.length];
					return <Box key={index}>
						<FlexBox>
							<Typography variant="h6" >{"Bond " + (index + 1)}</Typography>
							<Box ml="auto" my="auto" sx={{
								background: bondColor,
								width: '10px',
								height: '10px',
								borderRadius: '50%',
							}}/>
						</FlexBox>
						<Box mt={1} mb={2}>
							<Typography variant="tertiary">{bondInfo || ''}</Typography>
						</Box>
						<FlexBox mb={1}>
							<Typography variant="secondary">SubTotal</Typography>
							<Typography variant="secondary" ml="auto">{!isBlank(bond.total_charge) ? formatMoney(bond.total_charge, 2) : missingNewChargeText}</Typography>
						</FlexBox>
					</Box>
				})}
				<Box>
					<FlexBox>
						<Typography variant="h6" >Total</Typography>
						<Typography variant="h6" ml="auto">{formatMoney(bondsTotal, 2)}</Typography>
					</FlexBox>
				</Box>
			</Stack>

		</CardContent>
	</Card>

}