import BondStatus from "components/common/BondStatus";
import StaticDataContext from "contexts/StaticDataContext";
import moment from "moment";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { compareMoneyStrings, compareDateStrings, formatMoney, searchByField } from "Utils";
import FilterableTable from "components/modules/tables/FilterableTable";

export default function BondReturnsTable({ title, bondReturns, clients, actions }) {
	
	const navigate = useNavigate();

	const staticData = useContext(StaticDataContext);

	var columns = [
		{ key: 'date_sent', cell: "Date sent", compareFunc: compareDateStrings }, 
		{ key: 'sent_to', cell: "Sent to", minWidth: 130 }, 
		{ key: 'tracking', cell: "Tracking", minWidth: 200 }, 
		{ key: 'reference', cell: "Reference" }, 
		{ key: 'status', cell: "Status" }, 
		{ key: 'client', cell: "Client" },
		{ key: 'bond_type', cell: "Type" },
		{ key: 'fronted', cell: "Fronted" },
		{ key: 'issuer', cell: "Issuer" },
		{ key: 'face_value', cell: "Face Value", compareFunc: compareMoneyStrings, align: 'right' },
		{ key: 'date_finish', cell: "End Date", compareFunc: compareDateStrings },
		{ key: 'principal', cell: "Principal" },
		{ key: 'invoice', cell: "Invoice Number" },
	];


	const rows = bondReturns ? bondReturns.map((bondReturn) => {
		let bond = bondReturn;

		var client = clients && searchByField(clients, 'id_client', bond.id_client);
		var bondType = searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type);
		var bondStatus = searchByField(staticData.bond_statuses, 'id_bond_status', bond.id_bond_status);

		var cells = [
			moment(bondReturn.date_sent).format('DD/MM/YYYY'),
			bondReturn.sent_to,
			bondReturn.tracking_number,
			<span style={{whiteSpace: 'nowrap'}}>{bond.reference || ""}</span>,
			<BondStatus bondStatus={bondStatus} />,
			clients && <Link to={"/client/" + bond.id_client}><span style={{whiteSpace: 'nowrap'}}>{client ? client.name : "Unknown"}</span></Link>,
			((bondType && bondType.name) || "").replace("Fronted", "").replace("Bond", "").trim(),
			bondType ? bondType.fronted ? "Yes" : "No": "",
			bond.issuer_name || "Unknown",
			formatMoney(bond.face_value, 2),
			moment(bond.date_finish).format('DD/MM/YYYY'),
			<span style={{whiteSpace: 'nowrap'}}>{bond.principal}</span>,
			bond.invoice || "",
		];

		return {
			key: bondReturn.id_bond,
			cells: cells,
			bond: bond, 
			onClick: (event) => { navigate('/bond/' + bondReturn.id_bond + '/detail') },
		}
	}) : [];

	return <FilterableTable title={title} actions={actions} columns={columns} rows={rows} sx={{ marginBottom: 4}} />

};
