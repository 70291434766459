import { Typography } from "@mui/material";
import SectionTitle from "components/common/SectionTitle";
import CenteredModal from "components/layout/CentredModal";
import FlexBox from "components/layout/FlexBox";
import Actions from "components/modules/Actions";


export default function Dialog({title, message, width, actions}) {

	return <CenteredModal width={width || 724}>
		{ title ? <SectionTitle title={title} /> : null }
		{ message ? <Typography mb={3}>{message}</Typography> : null }
		<FlexBox justify="end">{actions ? <Actions>{actions}</Actions> : null}</FlexBox>
	</CenteredModal>

}