import seedrandom from 'seedrandom';

const MOCKED_CLIENTS = 6;

const mockedData = {
	issuers: mockIssuers(),
	banks: mockBanks(),
	bond_types: mockBondTypes(),
	facility_fee_types: mockFeeTypes(),
	durations: mockDurations(),
	bond_statuses: mockBondStatuses(),
};

mockedData.clients = mockClients();
mockedData.facilitys = mockFacilitys(mockedData.clients, mockedData.issuers, mockedData.banks);
mockedData.bonds = mockBonds(mockedData.clients, mockedData.facilitys, mockedData.issuers);
mockedData.indemnifiers = mockIndemnifiers(mockedData.clients, mockedData.facilitys, mockedData.issuers, mockedData.banks);
mockedData.contacts = mockContacts(mockedData.clients);

mockedData.facilitys.forEach((facility) => {
	let bonds = mockedData.bonds.filter(bond => bond.id_facility === facility.id_facility);
	var totals = { };
	bonds.forEach((bond) => {
		let statusId = bond.id_bond_status;
		if (!totals[statusId]) totals[statusId] = 0;
		totals[statusId] += parseFloat(bond.face_value);
	});
	facility.bond_status_totals = [];
	for (var s in totals) {
		facility.bond_status_totals.push({
			id_bond_status: parseInt(s),
			face_value_total: "" + totals[s],
		});
	}	
});

export default mockedData;




// Dynamic data	
function mockClient(id_client) {
	return {
		id_client: id_client,
		name: "Client " + id_client,
		abn: '12345678901',
	}		
}

function mockClients() {
	var clients = [];
	for (var i = 1; i <= MOCKED_CLIENTS; i++) {
		clients.push(mockClient(i));
	}
	return clients;
}

function mockFacilitys(clients, issuers, banks) {
	var facilitys = [];
	clients.forEach((client) => {
		var rng = seedrandom(client.id_client);
		issuers.forEach((issuer, issuerIndex) => {
			if (rng() < 0.8) {
				const id_facility = client.id_client * issuers.length + issuerIndex + 1;
				const facility = {
					id_facility: id_facility,
					id_client: client.id_client,
					id_issuer: issuer.id_issuer,
					renewal_date: new Date(),
					limit: '50000000.00',
					prime_rate: '1.0',
					bond_type_facility_rates: mockBondRates(Math.floor(rng() * 10000), id_facility),
					facility_fees: mockFees(Math.floor(rng() * 10000), 1),
					facility_banks: [],
				}
				banks.forEach((bank) => {
					if (rng() < 0.8) {
						facility.facility_banks.push({
							id_bank: bank.id_bank,
							active: true,
							facility_bank_duration_rates: mockBankRates(Math.floor(rng() * 10000), facility.id_facility, bank.id_bank),
						});
					}
				});
				facilitys.push(facility);
			}
		});
	})
	return facilitys;
}	

function mockIndemnifiers(clients, facilitys) {
	var indemnifiers = [];
	clients.forEach((client) => {
		var rng = seedrandom(client.id_client);		
		var numIndemnifiers = Math.floor(2 * rng() + 3);
		for (var i = 1; i <= numIndemnifiers; i++) {
			let id_indemnifier = i + client.id_client * 10;
			var indemnifier = {
				id_indemnifier: id_indemnifier,
				id_client: client.id_client,
				name: "Indemnifer " + i,
				abn: "12345678901",
				facility_indemnifiers: [],
			}
			for (var j = 0; j < facilitys.length; j++) {
				if (rng() < 0.6) {
					var facility = facilitys[j];
					indemnifier.facility_indemnifiers.push({
						id_indemnifier: id_indemnifier,
						id_facility: facility.id_facility,
					})
				}
			}
			indemnifiers.push(indemnifier);
		}
	});
	return indemnifiers;
}

function mockBonds(clients, facilitys, issuers) {
	var bonds = [];
	clients.forEach((client) => {
		const rng = seedrandom(client.id_client);
		const numBonds = Math.floor(4 + rng() * 8);
		const clientFacilitys = facilitys.filter(facility => facility.id_client === client.id_client);
		for (var i = 0; i < numBonds; i++) {
			const facility = (clientFacilitys.length > 0) ? (clientFacilitys[Math.floor(rng() * clientFacilitys.length)]) : null;
			const id_issuer = facility && facility.id_issuer;
			const issuer = issuers.filter(issuer => issuer.id_issuer === id_issuer)[0];
			bonds.push({
				id_bond: client.id_client * 1000 + i,
				id_bond_status: Math.floor(1 + rng() * 6),
				id_bond_type: Math.floor(1 + rng() * 12),
				id_bank: null,
				id_client: client.id_client,
				id_facility: facility && facility.id_facility,
				date_finish: new Date(),
				date_start: new Date(),
				expires: (rng() > 0.4),
				face_value: Math.floor(4 + 5 * rng()) + '000000.00',
				principal: (rng() > 0.5) ? "Melbourne City Council" : "Schools board",
				reference: "xxx yyyyy zzzz",

				// Not part of table
				issuer: issuer,
			});
		}
	});
	return bonds;
}	





// Helpers
function mockBondRates(seed, id_facility) {
	var rng = seedrandom(seed);
	var rates = [];
	mockBondTypes().forEach((bondType) => {
		rates.push({
			id_bond_type: bondType.id_bond_type,
			id_facility: id_facility,
			rate: (id_facility % 4 === 3 && bondType.id_bond_type % 6 === 5) ? parseFloat(null) : Math.round(100 * (0.9 + 0.2 * rng())) / 100,
		});
	});
	return rates;
}

function mockFees(seed, id_facility) {
	var rng = seedrandom(seed);
	var rates = [];
	mockFeeTypes().forEach((feeType) => {
		rates.push({
			id_facility_fee_type: feeType.id_facility_fee_type,
			id_facility: id_facility,
			fee: Math.round(100 * (100 + 200 * rng())) / 100,
		});
	});
	return rates;
}

function mockBankRates(seed, id_facility, id_bank) {
	var rng = seedrandom(seed);
	var rates = [];
	mockDurations().forEach((duration) => {
		rates.push({
			id_duration: duration.id_duration,
			id_facility: id_facility,
			id_bank: id_bank,
			rate: Math.round(100 * (0.2 + 0.09 * rng())) / 100,
		});
	});
	return rates;
}


function mockContacts(clients) {
	var rng = seedrandom(1);
	var contacts = [];
	clients.forEach((client) => {
		var numUsers = Math.floor(4 + rng() * 3);
		for (var i = 0; i < numUsers; i++) {
			const id = client.id_client * 10 + i + 1;
			const email = "user" + id + "@example.com";
			contacts.push({
				id_contact: 167 + id,
				id_contact_type: 1,
				name: ((rng() > 0.5) ? "Jane" : "John") + " Doe" + id,
				address: (26 + 3 * id) + " example st",
				role: ["Accountant", "CEO", "Janitor"][Math.floor(rng() * 3)],
				email: email,
				landline: "1234567890",
				mobile: "0412345678",

				// joins
				id_client: client.id_client,
				contact_type: {
					// ?
				},
				user:  (rng() > 0.5) && {
					id_user: id,
					id_contact: 0,
					email: email,
					active: (i !== client.id_client),
					read: true,
					write: true,
	
					// joins
					groups: [{ id_user_group: 1, name: "Client" }],	
				},
			});
		}
	});

	const id_base = (contacts.length + 5) * 10;
	for (let i = 0; i < 3; i++) {
		const id = id_base + i + 1;
		const email = "user" + id + "@example.com";
		contacts.push({
			id_contact: 167 + id,
			id_contact_type: 1,
			name: ((rng() > 0.5) ? "Jane" : "John") + " Doe" + id,
			address: (26 + 3 * id) + " example st",
			role: ["Accountant", "CEO", "Janitor"][Math.floor(rng() * 3)],
			email: email,
			landline: "1234567890",
			mobile: "0412345678",

			// joins
			id_client: null,
			contact_type: {
				// ?
			},
			user:  (rng() > 0.5) && {
				id_user: id,
				id_contact: 0,
				email: email,
				active: (i % 2 === 0),
				read: true,
				write: true,

				// joins
				groups: [{ id_user_group: 1, name: "Client" }],	
			},
		});
	}

	return contacts;
}




// Static data
/*
function mockUserGroups() {
	return [
		{
			id_group: 1,
			name: 'Client',
		},
		{
			id_group: 2,
			name: 'Subsidiary',
		},
		{
			id_group: 3,
			name: 'Dataroom',
		},
	];		
}
*/

function mockIssuers() {
	return [
		{
			id_issuer: 1,
			name: 'AIG',
		},
		{
			id_issuer: 2,
			name: 'Berkshire',
		},
		{
			id_issuer: 3,
			name: 'AI/Swiss RE',
		},
		{
			id_issuer: 8,
			name: 'Fake Issuer',
		},
	];		
}

function mockBondTypes() {
	return [
		{
			id_bond_type: 1,
			name: "Performance Bond",
			fronted: 0,
		},
		{
			id_bond_type: 2,
			name: "Maintenance Bond",
			fronted: 0,
		},
		{
			id_bond_type: 3,
			name: "Advance Payment Bond",
			fronted: 0,
		},
		{
			id_bond_type: 4,
			name: "Materials Bond",
			fronted: 0,
		},
		{
			id_bond_type: 5,
			name: "Lease Bond",
			fronted: 0,
		},
		{
			id_bond_type: 6,
			name: "Other Bond",
			fronted: 0,
		},
		{
			id_bond_type: 7,
			name: "Fronted Performance Bond",
			fronted: 1,
		},
		{
			id_bond_type: 8,
			name: "Fronted Maintenance Bond",
			fronted: 1,
		},
		{
			id_bond_type: 9,
			name: "Fronted Advance Payment Bond",
			fronted: 1,
		},
		{
			id_bond_type: 10,
			name: "Fronted Materials Bond",
			fronted: 1,
		},
		{
			id_bond_type: 11,
			name: "Fronted Lease Bond",
			fronted: 1,
		},
		{
			id_bond_type: 12,
			name: "Fronted Other Bond",
			fronted: 1,
		},
	];		
}

function mockFeeTypes() {
	return [
		{
			id_facility_fee_type: 1,
			name: 'Bond Issuance Fee',
			fronted: false,
		},
		{
			id_facility_fee_type: 2,
			name: 'Fronted BG Issuance Fee',
			fronted: true,
		},
	];
}

function mockDurations() {
	return [
		{
			id_duration: 1,
			duration: '0-365 Days',
			min: 0,
			max: 365,
		},
		{
			id_duration: 2,
			duration: '366-730 Days',
			min: 366,
			max: 730,
		},
		{
			id_duration: 3,
			duration: '731-1095 Days',
			min: 731,
			max: 1095,
		},
		{
			id_duration: 4,
			duration: '1096-1460 Days',
			min: 1096,
			max: 1460,
		},
		{
			id_duration: 5,
			duration: '1461+ Days',
			min: 1461,
			max: null,
		},
	];
}

function mockBanks() {
	return [
		{
			id_bank: 1,
			name: 'ANZ',
		},
		{
			id_bank: 2,
			name: 'HSBC',
		},
	];
}

function mockBondStatuses() {
	return [
		{
			id_bond_status: 1,
			name: 'Quote',
		},
		{
			id_bond_status: 2,
			name: 'Applied',
		},
		{
			id_bond_status: 3,
			name: 'Issued',
		},
		{
			id_bond_status: 4,
			name: 'Extended',
		},
		{
			id_bond_status: 5,
			name: 'Expired',
		},
		{
			id_bond_status: 6,
			name: 'Returned',
		},
		{
			id_bond_status: 7,
			name: 'Ended',
		},
		{
			id_bond_status: 8,
			name: 'Draft',
		},
	];
}
