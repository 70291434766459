import { TextField, Typography } from "@mui/material";

export default function NullableTextField({ value, onChange, compact, smallText, expands, sx, ...props }) {

	if (value === null || value === undefined) value = '';

	function handleChange(event) {
		var value = event.target.value;
		if (value === '') value = null;
		if (onChange) onChange(value, event);
	}

	if (compact || smallText) sx = {
		...sx,
		input: {
			padding: compact ? '10.5px 14px' : null, // default = 16.5px 14px
			textAlign: props.align,
		}
	};

	if (expands) {
		return <>
			<TextField value={value} className={smallText ? "small-text" : ""} onChange={handleChange} sx={sx} {...props} />
			<Typography 
				variant={smallText ? 'smallTextField' : null}
				sx={{
					display: 'block',
					whiteSpace: 'pre',
					padding: '0 14px',
					height: 0,
					visibility: 'hidden',
				}}
			>{value}</Typography>
		</>
	} else {
		return <TextField value={value} className={smallText ? "small-text" : ""} onChange={handleChange} sx={sx} {...props} />
	}
}