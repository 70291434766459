import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import SectionedBar from "components/common/SectionedBar";
import FlexBox from "components/layout/FlexBox";
import moment from "moment";

export default function DaysRemaining({ start, end, sx, ...props }) {

	if (!start || !end) return null;

	const blue = '#4355F6';

	const mStart = moment(start).startOf('day');
	const mEnd = moment(end).startOf('day');
	const mNow = moment(new Date()).startOf('day');

	const daysRemaining = Math.max(0, mEnd.diff(mNow, 'days'));
	
	var sections = [{
		color: blue,
		progress: Math.max(0, (mEnd - mNow) / (mEnd - mStart)),
	}];

	return <Box sx={{ marginBottom: 4, ...sx }} {...props}>
		<FlexBox>
			<Box sx={{textAlign: "left"}}>
				<Typography variant="label">Start date</Typography>
				<Typography variant="primary" sx={{display: 'block', marginTop: 0.5}}>{ mStart.format('DD/MM/YYYY') }</Typography>
			</Box>
			<Box sx={{textAlign: 'center', marginLeft: "auto", marginRight: "auto", marginBottom: 0.2, marginTop: "auto", padding: '0 5px'}}>
				<Typography variant="primary" sx={{color: blue}}>
					{ daysRemaining + (daysRemaining === 1 ? " day" : " days") + ' remaining' }
				</Typography>
			</Box>
			<Box sx={{textAlign: "right"}}>
				<Typography variant="label">End date</Typography>
				<Typography variant="primary" sx={{display: 'block', marginTop: 0.5}}>{ mEnd.format('DD/MM/YYYY') }</Typography>
			</Box>
		</FlexBox>
		<SectionedBar right sections={sections} mt={1}/>
	</Box>

}