export const ENV = {
	apiBase: process.env.REACT_APP_API_BASE,
	mock: process.env.REACT_APP_USE_MOCK_DATA === "true",
	quickStart: process.env.REACT_APP_QUICK_START === "true",
	logApiCalls: process.env.REACT_APP_LOG_API_CALLS === "true",
	headerColor: process.env.REACT_APP_HEADER_COLOUR,
}

export const AWS = {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_AWS_APP_CLIENT_ID,
};