import DatePicker from "components/common/DatePicker";
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import DownloadButton from "components/common/DownloadButton";
import FlexBox from "components/layout/FlexBox";
import LayoutContext from "contexts/LayoutContext";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { downloadStatementSpreadsheet } from "Spreadsheets";
import deepEqual from "deep-equal";
import { useGet } from "hooks/useAPI";
import API from "API";
import UserContext from "contexts/UserContext";

export default function StatementDownloader({ adminMode, client, facilitys, ...props }) {

	const { wideLayout } = useContext(LayoutContext);

	const { user } = useContext(UserContext);

	let start = moment("1900-01-01");
	const [startDate, setStartDate] = useState(start.toDate());
	const [endDate, setEndDate] = useState(new Date());

	const [dateRange, setDateRange] = useState(null);

	const [issuedOnly, setIssuedOnly] = useState(false);

	const [downloadingStatement, setDownloadingStatement] = useState(false);
	const [downloadedStatementData, setDownloadedStatementData] = useState(null);
	const [dateRangeStatementData,,, getDateRangeStatement] = useGet(API.getStatement(user, client.id_client, startDate, endDate, issuedOnly), true);


	function downloadDateRangeStatement() {
		setDateRange({ startDate: startDate, endDate: endDate });
	}
	useEffect(() => {
		const newData = dateRangeStatementData && !deepEqual(dateRangeStatementData, downloadedStatementData);
		if (dateRange && !downloadingStatement) {
			getDateRangeStatement();
			setDownloadingStatement(true);
		}
		if (newData && dateRange && downloadingStatement && client && facilitys) {
			setDateRange(null);
			setDownloadingStatement(false);			
			downloadStatementSpreadsheet(new Date(dateRangeStatementData.dates.date_end), dateRangeStatementData.bonds, client, facilitys);
			setDownloadedStatementData(dateRangeStatementData);
		}
	}, [dateRange, dateRangeStatementData, downloadingStatement, getDateRangeStatement, downloadedStatementData, setDateRange, setDownloadingStatement, client, facilitys])



	return <>

		<FlexBox center mb={2} sx={{ flexWrap: 'wrap' }}>
			<Typography mr={4}>Generate from date range</Typography>
			<FormControlLabel label="Issued only" control={
				<Checkbox checked={issuedOnly} onChange={(event) => {setIssuedOnly(event.target.checked)}} />
			} />
		</FlexBox>
		<Grid container columnSpacing={2} rowSpacing={2}>
			<Grid item xs={6} md={3}>
				<DatePicker
					value={startDate}
					label={"Start date"}
					renderInput={(params) => <TextField {...params} fullWidth={!wideLayout} />}
					onChange={(value) => { setStartDate(value) }}
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<DatePicker
					value={endDate}
					label={"End date"}
					renderInput={(params) => <TextField {...params} fullWidth={!wideLayout} />}
					onChange={(value) => { setEndDate(value) }}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<FlexBox center sx={{ height: '100%', flexWrap: 'wrap' }}>
					<DownloadButton sx={{ minWidth: '226px', marginRight: 2 }} onClick={downloadDateRangeStatement}>Download statement</DownloadButton>
				</FlexBox>
			</Grid>
		</Grid>
	</>
}