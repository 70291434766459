import { Card, CardContent, Stack, Typography } from "@mui/material"
import BondFileLink from "components/common/BondFileLink"
import CardTitle from "components/common/CardTitle"
import FileUpload from "components/modules/FileUpload"

export default function BondFiles({ adminMode, bond, files, postFileUpload }) {

	return <Card>
		<CardContent>
			
			<CardTitle title="Bond File"/>

			{ files && 
				(files.length > 0) ?
					<Stack spacing={1}>
						{ files.map((file) => {
							return <BondFileLink key={file.id_file} file={file} adminMode={adminMode} />
						}) }
					</Stack>
				:
					<Typography variant="secondary">No file has been uploaded</Typography>
			}

			{ adminMode && 
				<FileUpload sx={{mt: 2}} postFileUpload={postFileUpload} data={{ id_bond: bond.id_bond, id_client: bond.id_client }}/>
			}

		</CardContent>
	</Card>

}