import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

export default function StandardTableBase({ columns, rows, firstRow, lastRow, stickyHeader, compact, verticalAlign, ...props }) {

	var sortedRows = rows;

	if (firstRow !== undefined && lastRow !== undefined) sortedRows = sortedRows.slice(firstRow, lastRow);

	return <Table stickyHeader {...props}>
		{ columns &&
			<TableHead>
				<TableRow>
					{columns.map((column, index) => {
						return <TableCell key={column.key} align={column.align} sx={{minWidth: column.minWidth, width: column.width, textAlign: column.alignHeader, whiteSpace: "nowrap"}}>
							{column.cell}
						</TableCell>
					})}
				</TableRow>
			</TableHead>
		}

		<TableBody>
			{ (sortedRows && sortedRows.length > 0) ?
				sortedRows.map((row, index) => {
					if (row.element) {
						return row.element;
					} else if (row.space) {
						return <TableRow key={"space" + index} sx={{height: row.space}}>
							<TableCell colSpan={columns.length} sx={{padding: 0}}/>
						</TableRow>
					} else if (row.addButton) {
						return <TableRow key={"add" + index}>
							<TableCell colSpan={columns.length} sx={{padding: '5px 0', textAlign: 'center'}}>
								<IconButton onClick={row.onAdd}>
									<AddCircleOutlineOutlined />
								</IconButton>
							</TableCell>
						</TableRow>
					} else if (row.cells) {
						return <TableRow key={row ? row.key : "_" + index } className={row.onClick ? "clickable" : ''} sx={row.sx}
							onClick={(event) => {
								if (row.onClick) {
									var isRowClick = true;
									for (var node = event.target; !node || node.tagName !== "TD"; node = node.parentNode) {
										if (node.tagName === "A") isRowClick = false;
									}
									if (isRowClick) {
										row.onClick(event)}
									}
								}
							}
						>
							{ row && row.cells && row.cells.map((content, index) => {

								let column = columns[index];

								let cellStyles = { 
									verticalAlign: verticalAlign,
								};						
								if (compact) {
									cellStyles.padding = '3px';
								} else {
									if (row.short) {
										cellStyles.paddingTop = '3px';
										cellStyles.paddingBottom = '3px';
									}
									if (column.thin) {
										cellStyles.paddingLeft = '3px';
										cellStyles.paddingRight = '3px';
									}
								}

								return <TableCell key={index} align={columns[index].align} sx={cellStyles}>{content}</TableCell>
							})}
						</TableRow>
					} else {
						return <TableRow />
					}
				})
			:
				<TableRow>
					<TableCell colSpan={columns.length} sx={{textAlign: 'center'}}>No results found</TableCell>
				</TableRow>
			}
		</TableBody>

	</Table>
}