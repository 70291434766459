import { Paper, TableContainer } from "@mui/material";
import SortableTableBase from "./SortableTableBase";

export default function SortableTable({ columns, rows, stickyHeader, sx, ...props }) {

	return <Paper sx={{ width: '100%', marginBottom: 2, ...sx }} {...props}>
		<TableContainer>
			<SortableTableBase
				stickyHeader={stickyHeader}
				columns={columns}
				rows={rows}
			/>
		</TableContainer>
	</Paper>

}