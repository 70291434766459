import React, { useContext, useMemo } from "react";
import { Divider } from "@mui/material";
import StaticDataContext from "contexts/StaticDataContext";
import PageTitle from "components/common/PageTitle";
import CentreLoader from "components/common/CentreLoader";
import { useDetectClient } from "hooks/useDetectClient";
import { useQuery } from "hooks/useQuery";
import ProjectsTable from "components/modules/tables/ProjectsTable";
import ProjectsChart from "components/modules/ProjectsChart";
import { useState } from "react";
import { useEffect } from "react";
import API from "API";
import UserContext from "contexts/UserContext";
import { useGet } from "hooks/useAPI";
import ProjectDistributionGraph from "components/common/ProjectDistributionGraph";
import AddButton from "components/common/AddButton";
import { prepareProject } from "ProjectFunctions";
import { FilterableBondsTable } from "components/modules/tables/BondsTable";
import { indexByField } from "Utils";

export function AdminProjectsPage() {

	const { user } = useContext(UserContext);

	const [projectsData] = useGet(API.getGraphProjects(user));
	const projects = projectsData && projectsData.projects;
	const [distributionsData] = useGet(API.getProjectDistributions(user));
	const distributions = distributionsData && distributionsData.projects_distributions;
	const [orphansData] = useGet(API.getOrphanBonds(user));
	const orphans = orphansData && orphansData.bonds;

	return <>
		<ProjectsPage adminMode={true} title={"Projects"} projects={projects} distributions={distributions} />

		{ orphans ? <FilterableBondsTable title="Bonds Without Projects" bonds={orphans}/> : null }
	</>
	 
};


export function ClientProjectsPage() {

	const { user } = useContext(UserContext);

	const [clientData] = useGet(API.getClient(user));
	const { client } = (clientData) || {};

	const [projectsData] = useGet(API.getGraphProjects(user));
	const projects = projectsData && projectsData.projects;
	const [distributionsData] = useGet(API.getProjectDistributions(user));
	const distributions = distributionsData && distributionsData.projects_distributions;

	if (!client) {
		return <CentreLoader />
	}

return <>
		<ProjectsPage adminMode={false} title={client.name} projects={projects} distributions={distributions} />
	</>
	 
};


export function DataroomProjectsPage() {

	const query = useQuery();
	const id_client = parseInt(query.id);

	useDetectClient(id_client);

	const { user } = useContext(UserContext);

	const [clientData] = useGet(id_client ? API.getClient(user, id_client) : null);
	const { client } = (clientData) || {};

	const [projectsData] = useGet(API.getGraphProjects(user, id_client));
	const projects = projectsData && projectsData.projects;
	const [distributionsData] = useGet(API.getProjectDistributions(user, id_client));
	const distributions = distributionsData && distributionsData.projects_distributions;


	if (!client) {
		return <CentreLoader />
	}

	return <>
		<ProjectsPage adminMode={false} title={client.name} projects={projects} distributions={distributions} />
	</>
	 
};

export function UndercoverProjectsPage() {

	const query = useQuery();
	const id_client = parseInt(query.id);

	useDetectClient(id_client);
	
	const { user } = useContext(UserContext);

	const [clientData] = useGet(id_client ? API.getClient(user, id_client) : null);
	const { client } = (clientData) || {};

	const [projectsData] = useGet(API.getGraphProjects(user, id_client));
	const projects = projectsData && projectsData.projects;
	const [distributionsData] = useGet(API.getProjectDistributions(user, id_client));
	const distributions = distributionsData && distributionsData.projects_distributions;


	if (!client) {
		return <CentreLoader />
	}

	return <>
		<ProjectsPage adminMode={false} title={client.name} projects={projects} distributions={distributions} />
	</>
	 
};


function ProjectsPage({ adminMode, title, projects, distributions }) {

	const staticData = useContext(StaticDataContext);


	const preparedProjects = useMemo(() => {
		
		if (!projects || !staticData) return null;

		return projects.map(({ project, project_extensions, project_margins, project_bonds }) => {
			return prepareProject(project, project_extensions, project_margins, project_bonds, staticData) || { };
		})

	}, [projects, staticData]);


	const processedDistributions = useMemo(() => {
		if (!staticData) return { };

		let squishedDistributions = { };
		for (let key in distributions) {
			if (key === "issuers") {
				squishedDistributions[key] = { "Bonds": 0 };
				for (let key2 in distributions[key]) {
					if (key2 === "External Bank") squishedDistributions[key][key2] = distributions[key][key2];
					else squishedDistributions[key]["Bonds"] += distributions[key][key2];
				}
			} else {
				squishedDistributions[key] = {...distributions[key]}
			}		
		}

		let arrays = { };		
		for (let key in squishedDistributions) {
			arrays[key] = [];
			for (let key2 in squishedDistributions[key]) {
				arrays[key].push({
					key: key2,
					value: squishedDistributions[key][key2],
				})
			}			

			let ref = staticData[key];
			arrays[key].sort((a, b) => {
				let aIndex = indexByField(ref, 'name', a.key);
				let bIndex = indexByField(ref, 'name', b.key);
				return aIndex - bIndex;
			})
		}


		return arrays;
	}, [distributions, staticData]);

	
	const [enabledProjects, setEnabledProjects] = useState(null);

	useEffect(() => {
		if (preparedProjects && !enabledProjects) {
			let enabled = { };
			preparedProjects.forEach(({ project }) => {
				enabled[project.id_project] = true;
			});
			setEnabledProjects(enabled);
		}
	}, [preparedProjects, enabledProjects]);

	function onToggleProject(id_project, enabled) {
		let newEnabledProjects = {...enabledProjects}
		newEnabledProjects[id_project] = enabled;
		setEnabledProjects(newEnabledProjects);
	}

	const filteredProjects = useMemo(() => {
		return (preparedProjects && enabledProjects) ? preparedProjects.filter(({ project }) => {
			return (enabledProjects[project.id_project]) ? project : null;
		}) : [];
	}, [preparedProjects, enabledProjects]);


	if (!staticData || !filteredProjects || !preparedProjects || !enabledProjects || !processedDistributions) {			
		return <CentreLoader />
	}	

	return <>
		<PageTitle title={title} />

		<Divider sx={{mb: 4}} />


		<ProjectsChart title="Projects Chart" sx={{mb: 4}} preparedProjects={filteredProjects} 
			/*actions={
				<Button>Export PDF</Button>
			}*/
		/>

		<ProjectsTable title="Project List" projects={preparedProjects} enabledProjects={enabledProjects} onToggleProject={onToggleProject}  actions={
			adminMode ? <AddButton to="/project/add">Add unbonded project</AddButton> : null
		} />

		<ProjectDistributionGraph title="Issuer Mix" sx={{mb: 4}} distribution={processedDistributions.issuers} distributionKey="issuers" />
		<ProjectDistributionGraph title="Revenue Type Mix" sx={{mb: 4}} distribution={processedDistributions.price_structures} distributionKey="price_structures" />
		<ProjectDistributionGraph title="Project Type Mix" sx={{mb: 4}} distribution={processedDistributions.project_types} distributionKey="project_types" />
		<ProjectDistributionGraph title="Principal Type Mix" sx={{mb: 4}} distribution={processedDistributions.principal_types} distributionKey="principal_types" />

	</>
	 
};

