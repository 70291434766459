import { DatePicker as LabDatePicker } from "@mui/lab";
import { useRef } from "react";

export default function DatePicker({renderInput, onChange, ...props}) {

	let ref = useRef(null);

	return <LabDatePicker
		renderInput={(params) => {
			return <div ref={ref} className="datepicker-wrapper">
				{ renderInput(params) }
			</div>
		}}
		inputFormat='dd/MM/yyyy'
		onChange={(value) => {

			setTimeout(() => {
				let div = ref.current;

				if (div) {
					div.querySelectorAll('input').forEach((datePicker) => {
						let invalidDate = datePicker.getAttribute('aria-invalid') === 'true';
						datePicker.setCustomValidity(invalidDate ? 'Invalid date' : '');
						datePicker.reportValidity();
					});	
				}
			}, 0)

			onChange(value);
		
		}}
		{...props}
	/>

}