import { useEffect, useRef } from "react";

// Width and height are css string values
export default function ResponsiveCanvas({ width, height, redraw }) {

	const canvasRef = useRef();

	useEffect(() => {
		if (canvasRef.current) redraw(canvasRef.current);
	}, [canvasRef, redraw]);

	useEffect(() => {

		function handleResize() {
			if (canvasRef.current) redraw(canvasRef.current);
		}
	
		window.addEventListener('resize', handleResize)
		return () => { window.removeEventListener('resize', handleResize) };
	});

	return <canvas ref={canvasRef} style={{width: width, height: height}}/>

}