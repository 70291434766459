import { MenuItem, TextField } from "@mui/material"
import LayoutContext from "contexts/LayoutContext";
import { useContext } from "react";

function valueToOption(value) {
	if (value === true) return "Yes";
	else if (value === false) return "No";
	else return "";
}

function optionToValue(option) {
	if (option === "Yes") return true;
	else if (option === "No") return false;
	else return null;
}

export default function BooleanSelect({ value, setValue, sx, ...props}) {

	const { wideLayout } = useContext(LayoutContext);

	return <TextField
		select
		value={valueToOption(value)}
		onChange={(event) => {
			setValue(optionToValue(event.target.value))
		}}
		sx={{minWidth: wideLayout ? 246 : 210, ...sx}}
		{ ...props }
	>
		{ ["", "Yes", "No"].map((option) => {
			return <MenuItem key={'' + option} value={option} sx={{display: (option === '') ? 'none' : null}}>{ option }</MenuItem>
		})}									
	</TextField>
}