import axios from 'axios';
import { ENV } from 'Environment';
import { mockAPICalls } from 'MockAPICalls';
import moment from "moment";


axios.defaults.baseURL = ENV.apiBase;
axios.interceptors.response.use((response) => {
	if (ENV.logApiCalls) console.log(response.config.method.toUpperCase(), response.config.url.substr(response.config.baseURL.length), "response", response.data);

	const isFile = (response.responseType === "arraybuffer");
	if (!isFile && response.data && response.data.data) processDataTypes(response.data.data);

	return response;
});
axios.interceptors.request.use((request) => {
	if (request.method.toUpperCase() !== "GET") {
		if (ENV.logApiCalls) console.log(request.method.toUpperCase(), request.url.substr(request.baseURL.length), "request", request.data);
		if (request.data) procesOutgoingsDataTypes(request.data);
	}

	return request;
});


function basePath(user) {
	if (!user) return '';
	if (user.isAdmin) return '/admin';
	else if (user.isPrimary) return '/client/' + user.id_client + '/primary';
	else if (user.isSubsidiary) return '/client/' + user.id_client + '/subsidiary';
	else if (user.isDataroom) return '/client/' + (user.id_client ? user.id_client : 0) + '/dataroom';
	return '';
}

let routes = {
	getUserData: () => {
		return '/user';
	},
	
	getStaticData: (user) => {
		if (!user) return null;
		return basePath(user) + '/static';
	},
	
	getClients: (user) => {
		if (!user) return null;
		if (user.isAdmin || user.isDataroom) return basePath(user) + '/clients';
	},
	
	getClientsTotals: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/clients/totals';
	},

	getClient: (user, id_client) => {
		if (!user) return null;
		if (user.isAdmin && id_client) return basePath(user) + '/client/' + id_client;
		if (user.isDataroom && id_client) return basePath(user) + '/client';
		if (user.isPrimary || user.isSubsidiary) return basePath(user) + '/client';
	},

	getFinancials: (user, financial_years) => {
		if (!user || !financial_years) return null;
		return basePath(user) + '/financials?id_client=' + user.id_client + "&financial_years=" + financial_years.join(",");
	},

	getBonds: (user, id_client) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/bonds' + (id_client ? '?id_client=' + id_client : '');
		if (user.isPrimary || user.isSubsidiary) return basePath(user) + '/bonds';
	},
	getOrphanBonds: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/bonds/projects/orphans';
	},

	getBondReturns: (user, id_client) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + (id_client ? '/client/' + id_client : '') + '/bonds/returns';
		if (user.isPrimary || user.isSubsidiary) return basePath(user) + '/bonds/returns';
	},

	getBond: (user, id_bond) => {
		if (!user) return null;
		return basePath(user) + '/bond/' + id_bond;
	},
	getBondApplications: (user, id_bond) => {
		if (!user) return null;
		return basePath(user) + '/bond/' + id_bond + '/applications';
	},
	getBondApplication: (user, id_bond) => {
		if (!user) return null;
		return basePath(user) + '/bond/' + id_bond + '/application/latest';
	},

	getContacts: (user) => {
		if (!user) return null;
		return basePath(user) + '/contacts';
	},

	getContact: (user, id_contact) => {
		if (!user) return null;
		return basePath(user) + '/contact/' + id_contact;
	},

	getFacility: (user, id_facility) => {
		if (!user) return null;
		return basePath(user) + '/facility/' + id_facility;
	},

	getStatement: (user, id_client, startDate, endDate, issuedOnly) => {
		if (!user) return null;
		if (user.isAdmin || user.isDataroom) return basePath(user) + '/export/statement?id_client=' + id_client + '&date_start=' + moment.utc(startDate).format("YYYY-MM-DD") + '&date_end=' + moment.utc(endDate).format("YYYY-MM-DD") + (issuedOnly ? "&id_bond_status=3" : "");
		if (user.isPrimary || user.isSubsidiary) return basePath(user) + '/export/statement?date_start=' + moment.utc(startDate).format("YYYY-MM-DD") + '&date_end=' + moment.utc(endDate).format("YYYY-MM-DD") + (issuedOnly ? "&id_bond_status=3" : "");
	},

	getXeroContacts: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/xero/contacts';
	},

	getGraphProjects: (user, id_client) => {
		if (!user) return null;
		if (id_client) {
			if (user.isAdmin) return basePath(user) + '/client/' + id_client + '/graph/projects';
			if (user.isDataroom) return basePath(user) + '/graph/projects';
		} else {
			if (user.isAdmin || user.isPrimary) return basePath(user) + '/graph/projects';
		}
	},
	getProjectDistributions: (user, id_client) => {
		if (!user) return null;
		if (id_client) {
			if (user.isAdmin) return basePath(user) + '/client/' + id_client + '/projects/distributions';
			if (user.isDataroom) return basePath(user) + '/projects/distributions';
		} else {
			if (user.isAdmin || user.isPrimary) return basePath(user) + '/projects/distributions';
		}
	},

	getProject: (user, id_project) => {
		if (!user) return null;
		return basePath(user) + '/project/' + id_project;
	},


	postClient: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/clients';
	},
	postFinancials: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/financials';
	},
	postContact: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/contacts';
	},
	postUser: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/users';
	},
	postResetUser: (user, id_user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/user/' + id_user + '/reset';
	},
	postFacility: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/facilitys';
	},
	postBond: (user) => {
		if (!user) return null;
		if (user.isAdmin || user.isPrimary || user.isSubsidiary) return basePath(user) + '/bonds';
	},
	postBondReturn: (user, id_bond) => {
		if (!user) return null;
		if (user.isAdmin || user.isPrimary || user.isSubsidiary) return basePath(user) + '/bond/' + id_bond + '/return';
	},
	postBondExtensionRequest: (user, id_bond) => {
		if (!user) return null;
		if (user.isPrimary || user.isSubsidiary) return basePath(user) + '/bond/' + id_bond + '/extension/request';
	},
	postBondApplication: (user, id_bond) => {
		if (!user) return null;
		if (user.isAdmin || user.isPrimary || user.isSubsidiary || user.isDataroom) return basePath(user) + '/bond/' + id_bond + '/applications';
	},
	postProject: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/projects';
	},


	putIssuer: (user, id_issuer) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/issuer/' + id_issuer;
	},
	putClient: (user, id_client) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/client/' + id_client;
	},
	putContact: (user, id_contact) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/contact/' + id_contact;
	},
	putUser: (user, id_user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/user/' + id_user;
	},
	putFacility: (user, id_facility) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/facility/' + id_facility;
	},
	putBond: (user, id_bond) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/bond/' + id_bond;
	},
	putProject: (user, id_project) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/project/' + id_project;
	},


	uploadBondFile: (user, id_bond) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/bond/' + id_bond + '/files';
	},
	uploadFacilityFile: (user, id_facility) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/facility/' + id_facility + '/files';
	},
	uploadFinancialFile: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/financial/file';
	},


	downloadBondFile: (user, id_bond, id_file) => {
		if (!user) return null;
		if (user.isAdmin || user.isPrimary || user.isSubsidiary) return basePath(user) + '/bond/' + id_bond + '/file/' + id_file;
	},
	downloadFacilityFile: (user, id_facility, id_file) => {
		if (!user) return null;
		if (user.isAdmin || user.isPrimary) return basePath(user) + '/facility/' + id_facility + '/file/' + id_file;
	},
	downloadFinancialFile: (user, id_client, id_file) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/financial/file?id_client=' + id_client + '&id_file=' + id_file;
		if (user.isPrimary || user.isDataroom) return basePath(user) + '/financials/file/' + id_file;
	},


	deleteBond: (user, id_bond) => {
		if (!user) return null;
		if (user.isAdmin || user.isPrimary || user.isSubsidiary) return basePath(user) + '/bond/' + id_bond;
	},
	deleteBondFile: (user, id_bond, id_file) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/bond/' + id_bond + '/file/' + id_file;
	},
	deleteFacilityFile: (user, id_facility, id_file) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/facility/' + id_facility + '/file/' + id_file;
	},
	deleteFinancialFile: (user) => {
		if (!user) return null;
		if (user.isAdmin) return basePath(user) + '/financial/file';
	},




};
export default routes;


export function processDataTypes(data) {
	if (!data) return;

	if (data.file) return; // Don't process file submission responses

	// Issuers
	if (data.issuers) data.issuers.forEach((issuer) => {processIssuer(issuer)});

	// Clients
	if (data.client) processClient(data.client);
	if (data.clients) data.clients.forEach((client) => {processClient(client)});

	// Facilitys
	if (data.facility) processFacility(data.facility);
	if (data.facilitys) data.facilitys.forEach((facility) => {processFacility(facility)});

	// Indemnifiers
	if (data.indemnifiers) data.indemnifiers.forEach((indemnifier) => {processIndemnifier(indemnifier)});

	// Bonds
	if (data.bond) processBond(data.bond);
	if (data.bonds) data.bonds.forEach((bond) => {processBond(bond)});
	if (data.bond_history && Array.isArray(data.bond_history)) data.bond_history.forEach((bond) => {processBond(bond)});
	if (data.bond_related) data.bond_related.forEach((bond) => {processBond(bond)});
	if (data.bonds) data.bonds.sort((a, b) => { return new Date(b.timestamp_updated) - new Date(a.timestamp_updated); })

	// Projects
	if (data.project) processProject(data.project);
	if (data.project_extensions) data.project_extensions.forEach((extension) => {processProjectExtension(extension)});
	if (data.projects) data.projects.forEach((project) => {processProject(project)});

	// Contact
	if (data.contact) data.contact.notifications = sanitizeBoolean(data.contact.notifications);

	// User
	if (data.user) data.user.active = sanitizeBoolean(data.user.active);

	// StaticData
	if (data.bond_types) data.bond_types.forEach((bondType) => {
		bondType.fronted = sanitizeBoolean(bondType.fronted);
	});	
	if (data.facility_fee_types) data.facility_fee_types.forEach((feeType) => {
		feeType.fronted = sanitizeBoolean(feeType.fronted);
	});
	if (data.bond_statuses) {
		let preferredStatusOrder = ["Quote", "Applied", "Draft", "Issued", "Extended", "Expired", "Returned", "Ended"];
		let n = data.bond_statuses.length;
		data.bond_statuses.sort((a, b) => {
			let aIndex = (preferredStatusOrder.indexOf(a.name) + n * 2) % n;
			let bIndex = (preferredStatusOrder.indexOf(b.name) + n * 2) % n;
			return aIndex - bIndex;
		});
	}
}

function processIssuer(issuer) {
	issuer.can_issue_bonds = (issuer.can_issue_bonds === 1);
}

function processProject(project) {
	if (project.project) {
		processProject(project.project);
		if (project.project_extensions) project.project_extensions.forEach((extension) => {processProjectExtension(extension)});
	} else {
		project.date_start = project.date_start ? new Date(project.date_start) : null;
		project.date_performance = project.date_performance ? new Date(project.date_performance) : null;
		project.date_maintenance = project.date_maintenance ? new Date(project.date_maintenance) : null;
	}
}
function processProjectExtension(extension) {
	if (extension.date_performance) extension.date_performance = new Date(extension.date_performance);
	if (extension.date_maintenance) extension.date_maintenance = new Date(extension.date_maintenance);
}
function processBond(bond) {
	bond.face_value = sanitizeFloat(bond.face_value);
	bond.date_start = new Date(bond.date_start);
	bond.date_finish = new Date(bond.date_finish);
	bond.expires = sanitizeBoolean(bond.expires);
	// Convert server (UTC) timestamps to local Dates
	bond.timestamp = moment.utc(bond.timestamp).local().toDate();
	bond.timestamp_created = moment.utc(bond.timestamp_created).local().toDate();
	bond.timestamp_updated = moment.utc(bond.timestamp_updated).local().toDate();
}
function processClient(client) {
	client.active = sanitizeBoolean(client.active);
	if (client.totals) {
		client.totals.total_client_facility_limits = parseFloat(client.totals.total_client_facility_limits);
		client.totals.total_bond_fees.total_client_bond_fees = parseFloat(client.totals.total_bond_fees.total_client_bond_fees);
		for (let i = 0; i < client.totals.total_bond_face_values.length; i++) {
			client.totals.total_bond_face_values[i].total_bond_face_values = parseFloat(client.totals.total_bond_face_values[i].total_bond_face_values);
		}
	}
}
function processFacility(facility) {
	facility.active = sanitizeBoolean(facility.active);
	facility.limit = sanitizeFloat(facility.limit);
	facility.prime_rate = sanitizeFloat(facility.prime_rate);
	facility.renewal_date = new Date(facility.renewal_date);
	if (facility.bond_type_facility_rates) facility.bond_type_facility_rates.forEach((bond_type_facility_rate) => {
		bond_type_facility_rate.rate = sanitizeFloat(bond_type_facility_rate.rate);
	});
	if (facility.facility_fees) facility.facility_fees.forEach((facility_fee) => {
		facility_fee.fee = sanitizeFloat(facility_fee.fee);
	});
	if (facility.facility_banks) facility.facility_banks.forEach((facility_bank) => {
		facility_bank.active = sanitizeBoolean(facility_bank.active);
		if (facility_bank.facility_bank_duration_rates) facility_bank.facility_bank_duration_rates.forEach((facility_bank_duration_rate) => {
			facility_bank_duration_rate.rate = sanitizeFloat(facility_bank_duration_rate.rate);
		});
	});
	if (facility.bond_status_totals) facility.bond_status_totals.forEach((bond_status_total) => {
		bond_status_total.face_value_total = parseFloat(bond_status_total.face_value_total);
	});
}
function processIndemnifier(indemnifier) {
	indemnifier.active = sanitizeBoolean(indemnifier.active);
}

function sanitizeBoolean(b) {
	return ((b === 1) || (b === true));
}
function sanitizeFloat(n) {
	if (n === null || n === undefined) return null;
	return parseFloat(n);
}


export function procesOutgoingsDataTypes(data) {
	if (data.bond) processOutgoingBond(data.bond);
	if (data.bonds) data.bonds.forEach((bond) => {processOutgoingBond(bond)});

	if (data.facility) processOutgoingFacility(data.facility);
	if (data.facilitys) data.facilitys.forEach((facility) => {processOutgoingFacility(facility)});
}
function processOutgoingBond(bond) {
	bond.date_start = moment(bond.date_start).format("YYYY-MM-DD");
	bond.date_finish = moment(bond.date_finish).format("YYYY-MM-DD");
	// Convert server timestamps back to UTC
	bond.timestamp = moment.utc(bond.timestamp).format("YYYY-MM-DD hh:mm:ss");
	bond.timestamp_created = moment.utc(bond.timestamp_created).format("YYYY-MM-DD hh:mm:ss");
	bond.timestamp_updated = moment.utc(bond.timestamp_updated).format("YYYY-MM-DD hh:mm:ss");
}
function processOutgoingFacility(facility) {
	facility.renewal_date = moment(facility.renewal_date).format("YYYY-MM-DD");
}



if (ENV.mock) mockAPICalls(axios);