import { ENV } from "Environment";
import { differenceInCalendarDays } from "date-fns";
import { isBlank, searchByField } from "Utils";

export const clientSelfIndemnifierId = 'client';
export const missingNewChargeText = 'TBD';
export const missingHistoricChargeText = 'Refer to original invoice';



export function generateNewBond(staticData) {
	var bond = {
		
		id_bond: null,
		id_quote: null,
		id_bond_status: null,
		
		custodian_name: null,		
		custodian_email: null,		
		custodian_phone: null,		
		
		reference: null,
		project: null,
		principal: null,
		invoice: null,

		// Required inputs for calculations
		id_bond_type: null,
		id_indemnifier: null,
		id_facility: null,
		id_bank: null,
		fronted: false,
		expires: false,
		face_value: 0,
		date_start: new Date(),
		date_finish: new Date(),

		// Overrides
		bond_fee_override: null,
		fronting_fee_override: null,
		issuance_fee_override: null,

		//Outputs from calculations
		days: 0,
		
		rate: null,
		fronting_rate: null,

		calculated_bond_fee: null,
		calculated_fronting_fee: null,
		calculated_issuance_fee: null,

		issuanceFeeIncGST: null,
		total_charge: null,

	};

	if (ENV.quickStart) {
		bond.id_bond_type = 7;
		bond.id_client = 1;
		bond.id_facility = 3;
		bond.id_bank = 1;
		bond.face_value = 10000000;
		bond.date_finish.setFullYear(bond.date_finish.getFullYear() + 2);		
	}

	doBondCalculations(bond, null, staticData);
	return bond;
}

export function clearBond(bond, facility, staticData) {
	bond.reference = null;
	bond.project = null;
	bond.principal = null;
	bond.invoice = null;

	bond.id_bond_type = null;
	bond.id_indemnifier = null;
	bond.id_facility = null;
	bond.id_bank = null;
	bond.fronted = false;
	bond.expires = false;
	bond.face_value = 0;
	bond.date_start = new Date();
	bond.date_finish = new Date();

	bond.bond_fee_override = null;
	bond.fronting_fee_override = null;
	bond.issuance_fee_override = null;

	doBondCalculations(bond, facility, staticData);
}

export function calculateBondDays(bond) {
	bond.days = calculateDays(bond.date_start, bond.date_finish, bond.extension);
}
export function calculateDays(date_start, date_finish, extension) {
	return differenceInCalendarDays(date_finish, date_start) + (extension ? 0 : 1);
}

export function checkBondFronted(bond, staticData) {
	let bondType = staticData ? searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type) : null;
	bond.fronted = bondType ? (bondType.fronted) : false;	
}

export function doBondCalculations(bond, facility, staticData) {
	let bondType = staticData ? searchByField(staticData.bond_types, 'id_bond_type', bond.id_bond_type) : null;
	let frontingBank = facility ? searchByField(facility.facility_banks, 'id_bank', bond.id_bank) : null;

	calculateBondDays(bond);
	checkBondFronted(bond, staticData);

	if (!bond.fronted) bond.fronting_fee_override = null; // Clear this out in case it interferes with calculations

	var duration = null;
	if (staticData) {
		staticData.durations.forEach((d) => {
			if (bond.days >= d.min && (!d.max || bond.days <= d.max)) {
				duration = d;
			}
		});
	}

	var feeType = null;
	if (staticData) {
		staticData.facility_fee_types.forEach((f) => {
			if (f.fronted === bond.fronted) {
				feeType = f;
			}
		});
	}

	let bond_fee, issuance_fee, fronting_fee;

	bond.rate = null;
	bond.fronting_rate = null;

	bond.calculated_bond_fee = null;
	bond.calculated_issuance_fee = null;
	bond.calculated_fronting_fee = null;

	// Facility rate
	let bond_type_facility_rate = (facility && bondType && searchByField(facility.bond_type_facility_rates, 'id_bond_type', bondType.id_bond_type));
	if (bond_type_facility_rate && !isBlank(bond_type_facility_rate.rate)) {
		bond.rate = bond_type_facility_rate.rate;
		bond.calculated_bond_fee = (bond.days / 365) * bond.face_value * (bond_type_facility_rate.rate / 100);
		bond.calculated_bond_fee = Math.round(bond.calculated_bond_fee * 100) / 100;
	} else if (facility && !isBlank(facility.prime_rate)) {
		bond.rate = facility.prime_rate;
		bond.calculated_bond_fee = (bond.days / 365) * bond.face_value * (facility.prime_rate / 100);
		bond.calculated_bond_fee = Math.round(bond.calculated_bond_fee * 100) / 100;
	}
	bond_fee = !isBlank(bond.bond_fee_override) ? bond.bond_fee_override : bond.calculated_bond_fee;
	if (!isBlank(bond.bond_fee_override)) bond.rate = null;

	// Issuance fee
	let facility_fee = (facility && feeType && searchByField(facility.facility_fees, 'id_facility_fee_type', feeType.id_facility_fee_type));
	if (facility_fee && !isBlank(facility_fee.fee)) {
		bond.calculated_issuance_fee = facility_fee.fee;
		bond.calculated_issuance_fee = Math.round(bond.calculated_issuance_fee * 100) / 100;
	}
	issuance_fee = !isBlank(bond.issuance_fee_override) ? bond.issuance_fee_override : bond.calculated_issuance_fee;

	calculateBondGST(bond);

	// Fronting fee
	let facility_bank_duration_rate = (bond.fronted && frontingBank && duration && searchByField(frontingBank.facility_bank_duration_rates, 'id_duration', duration.id_duration));
	if (bond.fronted && facility_bank_duration_rate && !isBlank(facility_bank_duration_rate.rate)) {
		bond.fronting_rate = facility_bank_duration_rate.rate;
		bond.calculated_fronting_fee = (bond.days / 365) * bond.face_value * (facility_bank_duration_rate.rate / 100);
		bond.calculated_fronting_fee = Math.round(bond.calculated_fronting_fee * 100) / 100;
	}
	fronting_fee = !isBlank(bond.fronting_fee_override) ? bond.fronting_fee_override : bond.calculated_fronting_fee;
	if (!isBlank(bond.fronting_fee_override)) bond.fronting_rate = null;

	// Final total
	bond.total_charge = bond_fee + fronting_fee + bond.issuanceFeeIncGST;
	if (isBlank(bond_fee) || isBlank(issuance_fee) || (bond.fronted && isBlank(fronting_fee)) || isNaN(bond.total_charge)) bond.total_charge = null;

}
export function calculateBondGST(bond) {
	const issuance_fee = !isBlank(bond.issuance_fee_override) ? bond.issuance_fee_override : bond.calculated_issuance_fee;
	bond.issuanceFeeIncGST = !isBlank(issuance_fee) ? Math.round((issuance_fee * 1.1) * 100) / 100 : null;
}
export function calculateBondOverrideRate(bond, override, dp = 0) {
	return calculateOverrideRate(bond.face_value, bond.days, override, dp);
}
export function calculateOverrideRate(face_value, days, override, dp = 0) {
	if (override === null) return null;
	let rate = override / ((days / 365) * face_value) * 100;
	let mult = Math.pow(10, dp);
	return Math.round(rate * mult) / mult;
}
export function calculateBondOverrideFromRate(bond, rate) {
	let override = (bond.days / 365) * bond.face_value * (rate / 100);
	return Math.round(override * 100) / 100;
}


export const BOND_TYPE_PERFORMANCE = "Performance";
export const BOND_TYPE_MAINTENANCE = "Maintenance";
export const BOND_TYPE_FRONTED_PERFORMANCE = "Fronted Performance";
export const BOND_TYPE_FRONTED_MAINTENANCE = "Fronted Maintenance";

export function bondTypeId(name, staticData) {
	return (searchByField(staticData.bond_types, 'name', name) || {}).id_bond_type;
}
export function bondTypeName(id_bond_type, staticData) {
	return (searchByField(staticData.bond_types, 'id_bond_type', id_bond_type) || {}).name;
}
export function getBondType(id_bond_type, staticData) {
	return (searchByField(staticData.bond_types, 'id_bond_type', id_bond_type) || {});
}


export const BOND_STATUS_QUOTE = "Quote";
export const BOND_STATUS_APPLIED = "Applied";
export const BOND_STATUS_DRAFT = "Draft";
export const BOND_STATUS_ISSUED = "Issued";
export const BOND_STATUS_EXTENDED = "Extended";
export const BOND_STATUS_EXPIRED = "Expired";
export const BOND_STATUS_RETURNED = "Returned";

export function statusId(name, staticData) {
	return (searchByField(staticData.bond_statuses, 'name', name) || {}).id_bond_status;
}
export function statusName(id_bond_status, staticData) {
	return (searchByField(staticData.bond_statuses, 'id_bond_status', id_bond_status) || {}).name;
}
export function getBondStatus(id_bond_status, staticData) {
	return (searchByField(staticData.bond_statuses, 'id_bond_status', id_bond_status) || {});
}

export function calculateBondStatusUsage(totals, bond_statuses) {
	var pending = 0;
	var active = 0;
	var ended = 0;

	totals.forEach((status_total) => {
		const bond_status = searchByField(bond_statuses, 'id_bond_status', status_total.id_bond_status);
		let total = parseFloat(status_total.face_value_total || status_total.total_bond_face_values);
		switch(bond_status.name) {
			case BOND_STATUS_QUOTE:
			case BOND_STATUS_APPLIED:
			case BOND_STATUS_DRAFT:
				pending += total;
				break;
			case BOND_STATUS_ISSUED:
				active += total;
				break;
			case BOND_STATUS_EXTENDED:
			case BOND_STATUS_EXPIRED:
			case BOND_STATUS_RETURNED:
				ended += total;
				break;
			default: break;
		}
	})

	return { pending: pending, active: active, ended: ended };
}
