import { createTheme } from "@mui/material";
import { BOND_STATUS_APPLIED, BOND_STATUS_EXPIRED, BOND_STATUS_EXTENDED, BOND_STATUS_ISSUED, BOND_STATUS_QUOTE, BOND_STATUS_RETURNED, BOND_STATUS_DRAFT } from "BondFunctions";
import { INVOICE_STATUS_PAID } from "InvoiceFunctions";

const wideQuery = '@media (min-width:900px)';
const font = 'Roboto, Open Sans';
const identityFont = 'Roboto, Open Sans';

const inscapTheme = createTheme({
	fontFamily: [font],
	spacing: 6,
	typography: {
		fontSize: 14,
		body1: { fontSize: '0.875rem' },
		body2: { fontSize: '0.875rem' },
		identity: { fontFamily: identityFont },
		total: { fontFamily: font, fontSize: '1.3rem', lineHeight: 1.8, display: 'block' },
		grandtotal: { fontFamily: font, fontSize: '1.625rem', fontWeight: 500, lineHeight: 1.8, display: 'block' },
		label: { fontFamily: font, fontSize: '0.8rem', opacity: 0.6 },
        control: { fontFamily: font, fontSize: '0.875rem', fontWeight: 400, lineHeight: 1.43, letterSpacing: '0.01071em' },
		graph: { fontFamily: font, fontSize: '0.7rem', fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' },
		primary: { fontFamily: font, fontSize: '1rem', color: 'rgba(0, 0, 0, 0.87)' },
		secondary: { fontFamily: font, fontSize: '1rem', color: '#45556A' },
		tertiary: { fontFamily: font, fontSize: '0.9rem', color: '#808D9D' },
		formText: { fontFamily: font, fontSize: '0.9rem', color: 'rgba(0, 0, 0, 0.87)', lineHeight: 1.4 },
		cell: { fontFamily: font, fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)', lineHeight: 1.43 },
		cardHeader: { fontFamily: font, fontSize: '1.2rem' },
		pageHeader: { fontFamily: font, fontSize: '1.5rem', fontWeight: 400 },
		tableHeader: { fontFamily: font, fontSize: '0.875rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.87)', lineHeight: '1.5rem' },
		smallTextField: { fontSize: '0.875rem' }, // Matches a small-text class on a TextField
		h1: { fontSize: '1.5rem', fontWeight: 400, marginBottom: '1.75rem' },
		h2: { fontSize: '1.4rem', marginBottom: '1.5rem' },
		h3: { fontSize: '1.3rem', marginBottom: '1.25rem' },
		h4: { fontSize: '1.2rem', marginBottom: '1rem' },
		h5: { fontSize: '1.1rem', marginBottom: '0.5rem' },
		h6: { fontSize: '1rem' },
	},
	palette: {
		primary: {
			main: '#4355F7',
		},
	},
	bondColors: [
		'#F2994A',
		'#BB6BD9',
		'#56CCF2',
		'#F2C94C',
		'#BAE14A',
		'#F589D7',
		'#2F80ED',
		'#72E8CB',
		'#F47A5F',
		'#A27FEC',
	],
	bondStatusColors: {
		[BOND_STATUS_QUOTE]: '#f5c391',
		[BOND_STATUS_APPLIED]: '#AAC7FF',
		[BOND_STATUS_DRAFT]: '#86d5d5',
		[BOND_STATUS_ISSUED]: '#77F3BF',
		[BOND_STATUS_EXTENDED]: '#DEDEDE',
		[BOND_STATUS_EXPIRED]: '#DEDEDE',
		[BOND_STATUS_RETURNED]: '#DEDEDE',
	},
	invoiceStatusColors: {
		[INVOICE_STATUS_PAID]: '#EEEEEE',
	},
	distributionColors: {
		issuers: ['#C6E0B4', '#548235'],
		price_structures: ['#FFF2CC', '#FFE699', '#FFD966', '#BF8F00'],
		project_types: ['#F8CBAD', '#f1c19e', '#F4B084', '#cf8551', '#C65911', '#833C0C', '#CCCCCC'],
		principal_types: ['#D9E1F2', '#B4C6E7', '#8EA9DB', '#7484d7', '#5e699f', '#506691', '#3a558b', '#3d5d9f', '#22478f', '#203764', '#CCCCCC'],
	},
	sideNav: {
		transitionDuration: '0.2s',
		collapsedWidth: '3.5rem',
		expandedWidth: '15rem',
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					'.MuiToolbar-root': {
						padding: '0 8px',
					}
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '20px',
				},
			},
			variants: [
				{
					props: { variant: 'hero' },
					style: {
						padding: '20px',
						[wideQuery]: {
							paddingLeft: '40px',
							paddingRight: '40px',
						},
					},
				},
				{
					props: { variant: 'heroTable' },
					style: {
						padding: 0,
					},
				},
			],
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					paddingLeft: '20px',
					paddingRight: '20px',
				},
			},
			variants: [
				{
					props: { variant: 'hero' },
					style: {
						[wideQuery]: {
							paddingLeft: '40px',
							paddingRight: '40px',
						},
					},
				},
				{
					props: { variant: 'heroTable' },
					style: {
						[wideQuery]: {
							paddingLeft: '40px',
							paddingRight: '40px',
						},
					},
				},
			],
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					padding: '20px',
				},
			},
			variants: [
				{
					props: { variant: 'hero' },
					style: {
						padding: '40px 20px',
						[wideQuery]: {
							paddingLeft: '40px',
							paddingRight: '40px',
						},
					},
				},
				{
					props: { variant: 'heroTable' },
					style: {
						padding: 0,
					},
				},
			],
		},
		MuiChip: {
			variants: [
				{
					props: { variant: 'bondStatus' },
					style: {
						height: 'auto',
						padding: '5px 12px',
						span: {
							textTransform: 'uppercase',
							fontSize: '0.625rem',
							fontWeight: 500,
							lineHeight: '16px',
							padding: 0,
							letterSpacing: 1.5,
						},
					},
				},
			],
		},
		MuiTableRow: {
			variants: [
				{
					props: { className: 'clickable' },
					style: {
						transition: 'background-color 0.1s',
						background: 'rgba(0, 0, 0, 0)',
						cursor: 'pointer',
						"&:hover": {
							background: 'rgba(0, 0, 0, 0.03)',
						}
					}
				}
			]
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: '12px',
				}
			}
		},
		MuiTextField: {
			variants: [
				{
					props: { variant: 'outlined' },
					style: {
						input: {
							fontSize: '0.875rem',
							lineHeight: '1rem',
							padding: '12.5px 12px', // 16.5px 14px;
							height: 'auto',
						},
						label: {
							fontSize: '0.875rem',
							transform: 'translate(12px, 12px) scale(1)', // transform: translate(14px, 16px) scale(1);
						},
						'label.MuiInputLabel-shrink': {
							transform: 'translate(14px, -9px) scale(0.75)', // 'translate(14px, -9px) scale(0.75)',

						},
					}
				},
				{
					props: { className: 'small-text' },
					style: {
						input: {
							fontSize: '0.875rem',
						}
					}
				},
			]
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					fontSize: '0.875rem',
					lineHeight: '1rem',
					padding: '12.5px 12px',
					paddingRight: '32px',
					height: 'auto',
					minHeight: 0,
				}
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',
				}
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					marginTop: '-1px',
				}
			},
		},
	}
});
export default inscapTheme;