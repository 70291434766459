import { bondTypeId, BOND_TYPE_FRONTED_MAINTENANCE, BOND_TYPE_FRONTED_PERFORMANCE, BOND_TYPE_MAINTENANCE, BOND_TYPE_PERFORMANCE } from "BondFunctions";

export const EXTENSION_TYPE_PERFORMANCE = "Performance";
export const EXTENSION_TYPE_MAINTENANCE = "Maintenance";


export function prepareProject(project, extensions, margins, bonds, staticData) {
	if (!project || !staticData) return null;

	const hasBonds = bonds.length > 0;

	let originalPerformanceDate = null;
	let originalMaintenanceDate = null;

	let originalProjectValue = null;
	let originalProjectMargin = null;
	let currentProjectValue = null;
	let currentProjectMargin = null;
	let originalProjectMarginClientInitiated = false;

	let typedExtensions = [];
	let revisedMargins = [];



	let performanceType1 = bondTypeId(BOND_TYPE_PERFORMANCE, staticData);
	let performanceType2 = bondTypeId(BOND_TYPE_FRONTED_PERFORMANCE, staticData);
	let maintenanceType1 = bondTypeId(BOND_TYPE_MAINTENANCE, staticData);
	let maintenanceType2 = bondTypeId(BOND_TYPE_FRONTED_MAINTENANCE, staticData);
		
	if (hasBonds) {
		bonds.forEach((bond) => {
			switch (bond.id_bond_type) {
				case performanceType1:
				case performanceType2:
					if (!originalPerformanceDate) originalPerformanceDate = bond.date_finish;
					else typedExtensions.push({ type: BOND_TYPE_PERFORMANCE, date: bond.date_finish });
					break;
				case maintenanceType1:
				case maintenanceType2:
					if (!originalMaintenanceDate) originalMaintenanceDate = bond.date_finish;
					else typedExtensions.push({ type: BOND_TYPE_MAINTENANCE, date: bond.date_finish });
					break;
				default: break;
			}
		});
	} else {
		originalPerformanceDate = project.date_performance;
		originalMaintenanceDate = project.date_maintenance
		extensions.forEach((extension, index) => {
			if (extension.date_performance) typedExtensions.push({ index: index, type: BOND_TYPE_PERFORMANCE, date: extension.date_performance });
			else if (extension.date_maintenance) typedExtensions.push({ index: index, type: BOND_TYPE_MAINTENANCE, date: extension.date_maintenance });
		});
	}

	let currentPerformanceDate = originalPerformanceDate;
	let currentMaintenanceDate = originalMaintenanceDate;
	typedExtensions.forEach((extension) => {
		switch(extension.type) {
			case BOND_TYPE_PERFORMANCE:
				if (extension.date > currentPerformanceDate) currentPerformanceDate = extension.date;
				break;
			case BOND_TYPE_MAINTENANCE:
				if (extension.date > currentMaintenanceDate) currentMaintenanceDate = extension.date;
				break;
			default: break;
		}
	});

	if (originalPerformanceDate) originalPerformanceDate = new Date(originalPerformanceDate);
	if (originalMaintenanceDate) originalMaintenanceDate = new Date(originalMaintenanceDate);
	if (currentPerformanceDate) currentPerformanceDate = new Date(currentPerformanceDate);
	if (currentMaintenanceDate) currentMaintenanceDate = new Date(currentMaintenanceDate);

	let endDate = currentMaintenanceDate;
	if (!endDate || (currentPerformanceDate && currentPerformanceDate > endDate)) endDate = currentPerformanceDate;

	margins.forEach((margin, index) => {
		if (originalProjectValue === null) {
			originalProjectValue = margin.project_value;
			originalProjectMargin = margin.project_margin;
			originalProjectMarginClientInitiated = !!margin.client_initiated;
		} else {
			revisedMargins.push({
				index: index,
				...margin
			});
		}
		currentProjectValue = margin.project_value;
		currentProjectMargin = margin.project_margin;
	});

	
	return { project, bonds, revisedMargins, typedExtensions, currentPerformanceDate, currentMaintenanceDate, originalPerformanceDate, originalMaintenanceDate, endDate, originalProjectValue, originalProjectMargin, currentProjectValue, currentProjectMargin, originalProjectMarginClientInitiated };

}